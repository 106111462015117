var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.prefixClass },
    [
      _c("Input", {
        staticClass: "field-search",
        attrs: {
          suffix: "ios-search",
          placeholder: _vm.$t("fieldConfig.enterField"),
        },
        model: {
          value: _vm.query,
          callback: function ($$v) {
            _vm.query = $$v
          },
          expression: "query",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tree-wrap" },
        [
          _c("Tree", {
            attrs: {
              data: _vm.data,
              "show-checkbox": "",
              query: _vm.query,
              queryStyle: {
                color: "red",
              },
            },
            on: { "on-check-change": _vm.handleCheck },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "add-field-btn" },
        [
          !_vm.isDisabled
            ? _c(
                "Poptip",
                {
                  attrs: {
                    transfer: "",
                    placement: "right",
                    trigger: "click",
                    "popper-class": "ak-add-options",
                  },
                },
                [
                  _c("template", { slot: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ak-add-option",
                        on: {
                          click: function ($event) {
                            return _vm.add(1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("fieldConfig.addTable")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ak-add-option",
                        on: {
                          click: function ($event) {
                            return _vm.add(2)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("fieldConfig.addFilter")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("Icon", {
                    staticStyle: { cursor: "pointer" },
                    attrs: { type: "ios-add-circle-outline" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        cursor: "pointer",
                        "margin-top": "4px",
                        display: "inline-block",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("fieldConfig.add")))]
                  ),
                ],
                2
              )
            : [
                _c("Icon", {
                  staticClass: "disable-color",
                  staticStyle: { "margin-right": "4px" },
                  attrs: { type: "ios-add-circle-outline" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "disable-color" }, [
                  _vm._v(_vm._s(_vm.$t("fieldConfig.add"))),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }