var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "NaVertical" }, [
    _c("div", { staticClass: "NaVertical-icons" }, [_vm._t("icon-tag")], 2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "NaVertical-box" },
      [
        _vm._t("icon-outlink"),
        _vm._v(" "),
        _vm._t("nav-input"),
        _vm._v(" "),
        _vm._t("icon-home"),
        _vm._v(" "),
        _vm._t("icon-Collect"),
        _vm._v(" "),
        _vm._t("icon-Lately"),
        _vm._v(" "),
        _vm._t("icon-Setting"),
        _vm._v(" "),
        _vm._t("icon-message"),
        _vm._v(" "),
        _vm._t("icon-task"),
        _vm._v(" "),
        _vm._t("icon-person"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }