var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.outList.length > 0
    ? _c(
        "div",
        { class: _vm.classes },
        _vm._l(_vm.outList, function (item, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "tag r3-outlink",
              attrs: { "data-type": item.type },
              on: {
                click: function ($event) {
                  return _vm.outlink(item)
                },
              },
            },
            [
              _c("Tooltip", { attrs: { width: "50", trigger: "hover" } }, [
                _c(
                  "svg",
                  {
                    key: i,
                    staticClass: "r3-svg-icon",
                    attrs: { "aria-hidden": "true" },
                  },
                  [_c("use", { attrs: { "xlink:href": "#" + item.icon } })]
                ),
                _vm._v(" "),
                _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _vm._v(_vm._s(_vm.$t("buttons.jump")) + _vm._s(item.type)),
                ]),
              ]),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }