var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.title,
        width: _vm.width,
        loading: _vm.loading,
        "title-align": _vm.titleAlign,
        scrollable: _vm.scrollable,
        closable: _vm.closable,
        draggable: _vm.draggable,
        mask: _vm.mask,
        "mask-closable": _vm.maskClosable,
        transfer: _vm.transfer,
        "footer-hide": _vm.footerHide,
        "ok-text": _vm.okText,
        "cancel-text": _vm.cancelText,
      },
      on: { "on-cancel": _vm.onCancel, "on-ok": _vm.onOk },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [_c("div", { staticClass: "show-dialog" }, [_vm._t("Modalsolt")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }