var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "modify" },
    [
      _c(
        "ModalConfirm",
        {
          ref: "Modal",
          attrs: {
            title: _vm.poptitle,
            width: _vm.width,
            loading: _vm.loading,
            "title-align": _vm.titleAlign,
            "footer-hide": true,
          },
          on: { "on-cancle": _vm.oncancle },
        },
        [
          _c(
            "div",
            {
              class: _vm.classes,
              attrs: { slot: "Modalsolt" },
              slot: "Modalsolt",
            },
            [
              _vm.loading
                ? _c(
                    "Spin",
                    { attrs: { fix: "" } },
                    [
                      _c("Icon", {
                        staticClass: "demo-spin-icon-load",
                        attrs: { type: "ios-loading", size: "18" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("panelForm", {
                ref: "panelForm",
                tag: "component",
                staticClass: "v-formPanel",
                attrs: {
                  defaultData: _vm.newformList,
                  CollapseName: _vm.CollapseName,
                  tableName: "BatchModal",
                  type: "",
                },
                on: { "on-changeForm": _vm.formChange },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "button R3-button-group modifyButton" },
                [
                  _c(
                    "Button",
                    {
                      attrs: { type: "fcdefault" },
                      on: { click: _vm.oncancle },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("buttons.cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      staticStyle: { margin: "0 0 0 10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.confirm },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("buttons.confirm")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }