<template>
  <div :class="classes" v-if="outList.length > 0">
    <!-- 长度为1 -->
    <!-- :style="svgColor(item.icon)" -->
    <div
      class="tag r3-outlink"
      v-for="(item, i) in outList"
      :key="i"
      :data-type="item.type"
      @click="outlink(item)"
    >
      <Tooltip width="50" trigger="hover">
        <svg class="r3-svg-icon" :key="i" aria-hidden="true">
          <use :xlink:href="`#${item.icon}`"></use>
        </svg>
        <div slot="content">{{ $t("buttons.jump") }}{{ item.type }}</div>
      </Tooltip>
    </div>
    <!-- 长度大于1 -->
    <!-- <Dropdown v-if="outList.length >1">
        <svg class="r3-svg-icon" aria-hidden="true">
          <use xlink:href="#icon-tiaozhuan"></use>
        </svg>
        <DropdownMenu slot="list">
          <DropdownItem
            v-for="(item, i) in outList"
            :key="i"
            @click.native="outlink(item)"
          >
            <svg
              class="r3-svg-icon"
              aria-hidden="true"
              :style="svgColor(item.icon)"
            >
              <use :xlink:href="`#${item.icon}`"></use>
            </svg>
            <span>{{ item.type }}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown> -->
  </div>
</template>

<script>
import { classFix, Version } from "../../constants/global";
import network from "../../__utils__/network";
import "../../assets/svg/iconfont";
import {  mapActions } from 'vuex';
import {  customizeMixins } from '../../constants/global';

export default {
  name: "OutLink",
  mixins:[customizeMixins().oullink ? customizeMixins().oullink :false],
  data() {
    return {
      icon: {
        "icon-BOS_logo": {
          color: "#3699FE",
        },
        "icon-yunque": {
          color: "#1d74ff",
        },
        "icon-bojun": {
          color: "#438EB9",
        },
      },
      outList: [],
      windowList: {}, // 存储window 对象
    };
  },
  computed: {
    classes() {
      return [`${classFix}out-link`];
    },
    svgColor() {
      return function (val) {
        if (val) {
          return `color:${this.icon[val].color}`;
        }
      };
    },
  },
  methods: {
    ...mapActions('global', ['ssothirdsystem']),
    async outlink(item){
      // 跳转单点登录
     let url = await this.ssothirdsystem(item.type);
      let { outOpenSelf } = window.ProjectConfig;
            if (outOpenSelf) {
              location.href = url;
            } else {
              if (!this.windowList[item.type]) {
                this.windowList[item.type] = window.open(url);
              } else {
                if (this.windowList[item.type].opener) {
                  this.windowList[item.type].focus();
                } else {
                  this.windowList[item.type] = window.open(url);
                }
              }
      }
    },
    init() {
      // 初始化获取icon
      network
        .post("/p/cs/ssosystemlist")
        .then((res) => {
          if (res.data.code === 0) {
            this.outList = res.data.data;
            localStorage.setItem('R3_outList',JSON.stringify(this.outList));
          }
        })
        .catch(() => {});
    },
  },
  mounted() {
    let { SingleSignOnSwitch } = window.ProjectConfig;
    if (Version() === "1.4" && SingleSignOnSwitch) {
      this.init();
    }
  },
};
</script>
