var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "buttonGroup" },
        _vm._l(_vm.buttonGroup, function (item, index) {
          return _c("Button", {
            key: index,
            domProps: { textContent: _vm._s(item.name) },
            on: {
              click: function ($event) {
                return _vm.buttonClick(item)
              },
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "Collapse",
            {
              model: {
                value: _vm.openCollapse,
                callback: function ($$v) {
                  _vm.openCollapse = $$v
                },
                expression: "openCollapse",
              },
            },
            [
              _c("Panel", { attrs: { name: "1", "title-type": "center" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("messages.productColorMainImage")) +
                    "\n        "
                ),
                _c(
                  "div",
                  {
                    staticClass: "item-area",
                    attrs: { slot: "content" },
                    slot: "content",
                  },
                  [
                    _c("div", { staticClass: "pro_img" }, [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("messages.mainVideo")) + "}:"),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.video,
                              expression: "video",
                            },
                          ],
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("messages.videoId"),
                          },
                          domProps: { value: _vm.video },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.video = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "span-tip",
                            on: { click: _vm.showPicture },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("messages.videoUploadTutorial"))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "span-tip" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.uploadImage,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("messages.videoUploadEntrance"))
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("ul", { staticStyle: { display: "none" } }, [
                          _c("li", [
                            _c("div", {
                              style: {
                                backgroundImage: "url(" + _vm.videoCover + ")",
                                height: "64px",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pro_img" }, [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("messages.productMainImage")) + ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "imageContent" }, [
                        _c(
                          "ul",
                          { staticClass: "clearfix" },
                          [
                            _vm._l(_vm.proImg, function (item, index) {
                              return _c(
                                "li",
                                {
                                  directives: [
                                    {
                                      name: "dragging",
                                      rawName: "v-dragging",
                                      value: {
                                        item: item,
                                        list: _vm.proImg,
                                        group: "color",
                                        key: item,
                                      },
                                      expression:
                                        "{ item: item, list: proImg, group: 'color', key: item }",
                                    },
                                  ],
                                  key: index,
                                  on: {
                                    mouseout: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.proImgOut(item)
                                    },
                                    mouseover: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.proImgDrag(item)
                                    },
                                    mousedown: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.proImgOut(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.flag,
                                        expression: " item.flag",
                                      },
                                    ],
                                    staticClass:
                                      "iconfont iconios-close-circle",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.proImgDelect(item, index)
                                      },
                                      mousedown: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "Poptip",
                                    {
                                      attrs: {
                                        placement: "right",
                                        title: "",
                                        trigger: "hover",
                                        "popper-class": "el-popover-img",
                                        transfer: "",
                                      },
                                      model: {
                                        value: item.flag,
                                        callback: function ($$v) {
                                          _vm.$set(item, "flag", $$v)
                                        },
                                        expression: "item.flag",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        style: {
                                          "background-position": "center",
                                          "background-repeat": "no-repeat",
                                          "background-size": "auto 100%",
                                          backgroundImage:
                                            "url(" +
                                            item.URL +
                                            "?x-oss-process=image/quality,q_80)",
                                          height: "64px",
                                          width: "64px",
                                        },
                                        attrs: { title: item.NAME },
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        style: {
                                          "background-position": "center",
                                          "background-repeat": "no-repeat",
                                          "background-size": "auto 100%",
                                          backgroundImage:
                                            "url(" +
                                            item.URL +
                                            "?x-oss-process=image/quality,q_80)",
                                          height: "300px",
                                          width: "300px",
                                        },
                                        attrs: {
                                          slot: "content",
                                          title: item.NAME,
                                        },
                                        slot: "content",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _vm.proImg.length < 15
                              ? _c("li", { on: { click: _vm.proImgChange } }, [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        height: "64px",
                                        border: "1px solid #c7c7c7",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "upload-span" },
                                        [
                                          _vm._v(
                                            "+ " +
                                              _vm._s(_vm.$t("tips.addPicture"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "form",
                                    {
                                      staticStyle: { display: "none" },
                                      attrs: {
                                        role: "form",
                                        method: "POST",
                                        enctype: "multipart/form-data",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _c("input", {
                                          staticStyle: {
                                            filter: "alpha(opacity=0)",
                                            opacity: "0",
                                            width: "0",
                                            height: "0",
                                            display: "none",
                                          },
                                          attrs: {
                                            id: "proImg" + _vm.objId,
                                            type: "file",
                                            multiple: "multiple",
                                          },
                                          on: {
                                            change: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.uploadFileChange(
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "imageInstruction" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("messages.suggestImageNumber")) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pro_color_img" }, [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("messages.colorMainImage")) + "}:"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "imageContent" }, [
                        _c(
                          "ul",
                          { staticClass: "clearfix" },
                          _vm._l(_vm.colorList, function (item, index) {
                            return _c("li", { key: index }, [
                              _c("span", [
                                _vm._v(_vm._s(item.ECODE + item.ENAME)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  on: {
                                    mouseout: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.colImgOut(item)
                                    },
                                    mouseover: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.colImgDrag(item)
                                    },
                                    mousedown: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.colImgOut(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.flag && item.URL,
                                        expression: "item.flag&&item.URL",
                                      },
                                    ],
                                    staticClass:
                                      "iconfont iconios-close-circle",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.colImgDelete(item, index)
                                      },
                                      mousedown: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "Poptip",
                                    {
                                      attrs: {
                                        placement: "right",
                                        title: "",
                                        trigger: "hover",
                                        transfer: "",
                                        "popper-class": "el-popover-img",
                                      },
                                      model: {
                                        value: item.flag,
                                        callback: function ($$v) {
                                          _vm.$set(item, "flag", $$v)
                                        },
                                        expression: "item.flag",
                                      },
                                    },
                                    [
                                      !item.URL
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "upload-span",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.colImgChange(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "+ " +
                                                  _vm._s(
                                                    _vm.$t("tips.addPicture")
                                                  ) +
                                                  "\n                        "
                                              ),
                                              _c(
                                                "form",
                                                {
                                                  attrs: {
                                                    role: "form",
                                                    method: "POST",
                                                    enctype:
                                                      "multipart/form-data",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c("input", {
                                                      staticStyle: {
                                                        filter:
                                                          "alpha(opacity=0)",
                                                        opacity: "0",
                                                        width: "0",
                                                        height: "0",
                                                        display: "none",
                                                      },
                                                      attrs: {
                                                        id:
                                                          "colImg" +
                                                          _vm.objId +
                                                          index,
                                                        type: "file",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.uploadColorChange(
                                                            item,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", {
                                        style: {
                                          "background-position": "center",
                                          "background-repeat": "no-repeat",
                                          "background-size": "auto 100%",
                                          backgroundImage:
                                            "url(" +
                                            item.URL +
                                            "?x-oss-process=image/quality,q_80)",
                                          height: "62px",
                                          width: "64px",
                                        },
                                        attrs: { title: item.NAME },
                                      }),
                                      _vm._v(" "),
                                      item.URL
                                        ? _c("div", {
                                            style: {
                                              "background-position": "center",
                                              "background-repeat": "no-repeat",
                                              "background-size": "auto 100%",
                                              backgroundImage:
                                                "url(" +
                                                item.URL +
                                                "?x-oss-process=image/quality,q_80)",
                                              height: "300px",
                                              width: "300px",
                                            },
                                            attrs: {
                                              slot: "content",
                                              title: item.NAME,
                                            },
                                            slot: "content",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "imageInstruction" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("messages.suggestImageMaintain")) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: {
            title: _vm.$t("messages.videoUploadTutorial"),
            width: 1100,
            height: 560,
            "footer-hide": true,
            closable: true,
          },
          model: {
            value: _vm.dialogShow,
            callback: function ($$v) {
              _vm.dialogShow = $$v
            },
            expression: "dialogShow",
          },
        },
        [
          _c("div", { staticClass: "dialog-scroll" }, [
            _c("img", {
              attrs: { src: "/src/assets/image/course.jpg", width: "100%" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }