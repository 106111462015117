var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "div",
        {
          staticClass: "navigator-primary-menu",
          class: { active: _vm.index === _vm.primaryMenuIndex },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.togglePrimaryMenu(_vm.data.children)
            },
          },
        },
        [
          _vm.type === "Vertical"
            ? [
                _c(_vm.NavigatorPrimaryMenuBar, {
                  tag: "component",
                  attrs: { data: _vm.data },
                }),
                _vm._v(" "),
                _vm.NavigatorPrimaryMenuBar === ""
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "navigator-primary-menu-div" },
                          [
                            _vm._v(
                              "\n        " + _vm._s(_vm.data.label) + "\n      "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("Icon", { attrs: { type: "ios-arrow-forward" } }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : [_vm._v("\n    " + _vm._s(_vm.data.label) + "\n  ")],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }