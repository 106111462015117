var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "leaf-node" }, [
    _c("div", { staticClass: "leaf-node-l" }, [
      _c(
        "div",
        {
          staticClass: "leaf-node-icon",
          class: { "leaf-mr": _vm.data.showClose },
        },
        [
          !_vm.data.showClose
            ? _c("i", { staticClass: "iconfont icon-field" })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "leaf-node-value" }, [
        _vm._v(_vm._s(_vm.data.title)),
      ]),
    ]),
    _vm._v(" "),
    _vm.data.showClose
      ? _c("i", {
          staticClass: "iconfont icon-md-close",
          on: {
            click: function ($event) {
              return _vm.handleDelete($event)
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }