(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("vue"), require("vuex"));
	else if(typeof define === 'function' && define.amd)
		define("R3", ["axios", "vue", "vuex"], factory);
	else if(typeof exports === 'object')
		exports["R3"] = factory(require("axios"), require("vue"), require("vuex"));
	else
		root["R3"] = factory(root["axios"], root["Vue"], root["Vuex"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__1103__, __WEBPACK_EXTERNAL_MODULE__8976__, __WEBPACK_EXTERNAL_MODULE__1149__) => {
return 