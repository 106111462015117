var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("div", { staticClass: "table-c" }, [
      _c(
        "table",
        { attrs: { border: "0", cellspacing: "0", cellpadding: "0" } },
        [
          _c("tr", { staticClass: "th" }, [
            _c("td", [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("tips.default")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("tips.templateName")) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.printTemplateData, function (item, index) {
            return _c("tr", { key: index }, [
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.ISDEFAULT,
                      expression: "item.ISDEFAULT",
                    },
                  ],
                  attrs: { type: "radio", name: "radio", value: "Y" },
                  domProps: { checked: _vm._q(item.ISDEFAULT, "Y") },
                  on: {
                    click: function ($event) {
                      return _vm.CheckItem(item)
                    },
                    change: function ($event) {
                      return _vm.$set(item, "ISDEFAULT", "Y")
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.NAME))]),
            ])
          }),
          _vm._v(" "),
          _c("tr", [
            _vm.printTemplateData.length < 1
              ? _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("tips.noData")) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pop-btn" }, [
      _c("button", { staticClass: "sav-btn", on: { click: _vm.save } }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("buttons.confirm")))]),
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "cancel-btn", on: { click: _vm.cancel } }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }