var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("Table", {
        ref: "table",
        staticClass: "table",
        attrs: {
          columns: _vm.columns,
          height: true,
          multiple: _vm.multiple,
          multipleRow: _vm.multipleRow,
          data: _vm.tableData,
          "highlight-row": _vm.highlightRow,
          "row-class-name": _vm.rowClassName,
          "total-data": _vm.tableTotalData,
        },
        on: {
          "on-selection-change": _vm.tableSelectionChange,
          "on-row-dblclick": _vm.tableRowDbclick,
          "on-row-click": _vm.tableRowClick,
          "on-sort-change": _vm.tableSortChange,
          "on-select-all": _vm.tableSelectionChange,
        },
      }),
      _vm._v(" "),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.spinShow,
              expression: "spinShow",
            },
          ],
          staticClass: "table-spin-fix",
          attrs: { fix: "" },
        },
        [
          _c("Icon", {
            staticClass: "demo-spin-icon-load",
            attrs: { type: "ios-loading", size: "48" },
          }),
          _vm._v(" "),
          _c("div", [_vm._v("Loading")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("Dialog", {
        ref: "dialogRef",
        attrs: {
          title: _vm.dialogConfig.title,
          mask: _vm.dialogConfig.mask,
          "content-text": _vm.dialogConfig.contentText,
          "footer-hide": _vm.dialogConfig.footerHide,
          confirm: _vm.dialogConfig.confirm,
          "dialog-component-name": _vm.dialogComponentName,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }