var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _vm.showLabel
      ? _c(
          "span",
          { staticClass: "itemLabel", style: _vm.labelStyle },
          [
            _vm._items.props.comment
              ? _c(
                  "Poptip",
                  {
                    attrs: {
                      "word-wrap": "",
                      trigger: "hover",
                      transfer: "",
                      width: "200",
                      content: _vm._items.props.comment,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "api",
                        attrs: { slot: "content" },
                        slot: "content",
                      },
                      [
                        _c("span", [
                          _c("span", [
                            _vm._v(_vm._s(_vm._items.props.comment)),
                          ]),
                          _vm._v(" "),
                          _vm._items.props.webconf &&
                          _vm._items.props.webconf.Outside
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm._items.props.webconf.Outside,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("...")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("i", {
                      staticClass:
                        "iconfont iconios-information-circle-outline",
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._items.required
              ? _c("span", { staticClass: "label-tip" }, [_vm._v("*")])
              : _vm._e(),
            _vm._v(" "),
            _vm.getVersion() === "1.4" &&
            _vm._items.props.fkdisplay === "pop" &&
            _vm.type === "PanelForm"
              ? [
                  !!_vm._items.value &&
                  _vm._items.props.Selected &&
                  _vm._items.props.Selected[0] &&
                  !!_vm._items.props.Selected[0].ID &&
                  _vm._items.props.Selected[0].ID !== "-1" &&
                  _vm._items.props.Selected[0].ID !== 0 &&
                  _vm._items.props.Selected[0].ID !== "0"
                    ? [
                        _c("i", {
                          staticClass: "iconfont iconbj_link",
                          staticStyle: {
                            color: "#0f8ee9",
                            cursor: "pointer",
                            "font-size": "12px",
                          },
                          attrs: { "data-target-tag": "fkIcon" },
                          on: {
                            click: function ($event) {
                              return _vm.routerNext(_vm._items.props.Selected)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.getVersion() === "1.4" &&
            _vm._items.props.fkdisplay === "drp" &&
            _vm.type === "PanelForm"
              ? [
                  !!_vm._items.value &&
                  _vm._items.props.defaultSelected &&
                  _vm._items.props.defaultSelected[0] &&
                  !!_vm._items.props.defaultSelected[0].ID &&
                  _vm._items.props.defaultSelected[0].ID !== "-1" &&
                  _vm._items.props.defaultSelected[0].ID !== "0" &&
                  _vm._items.props.defaultSelected[0].ID !== 0
                    ? [
                        _c("i", {
                          staticClass: "iconfont iconbj_link",
                          staticStyle: {
                            color: "#0f8ee9",
                            cursor: "pointer",
                            "font-size": "12px",
                          },
                          attrs: { "data-target-tag": "fkIcon" },
                          on: {
                            click: function ($event) {
                              return _vm.routerNext(
                                _vm._items.props.defaultSelected
                              )
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("span", { attrs: { title: _vm._items.title } }, [
              _vm._v(_vm._s(_vm._items.title) + ":"),
            ]),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class:
          _vm._items.props.row > 1
            ? "itemComponent height100"
            : "itemComponent",
        style:
          _vm._items.props.type === "ImageUpload" ? "overflow:visible" : "",
      },
      [
        _vm._items.type === "input"
          ? _c("Input", {
              ref: _vm._items.field,
              class: {
                "encode-text": _vm._items.props.ispassword && _vm._items.value,
              },
              attrs: {
                type: _vm._items.props.type,
                clearable: _vm._items.props.clearable,
                disabled:
                  _vm._items.props.disabled || _vm._items.props.readonly,
                readonly:
                  _vm._items.props.readonly || _vm._items.props.disabled,
                rows: _vm._items.props.row,
                autosize: _vm._items.props.autosize,
                autofocus: _vm._items.props.autofocus,
                placeholder: !_vm._items.props.disabled
                  ? _vm._items.props.placeholder
                  : "",
                size: _vm._items.props.size,
                maxlength: _vm._items.props.maxlength,
                icon: _vm._items.props.icon,
                regx: _vm._items.props.regx,
                htmlExp: _vm._items.props.htmlExp,
                encrypt: _vm._items.props.ispassword,
                "on-click": "inputClick",
              },
              on: {
                "on-blur": _vm.inputBlur,
                "on-change": _vm.inputChange,
                "on-enert": _vm.inputEnter,
                "on-focus": _vm.inputFocus,
                "on-keyup": _vm.inputKeyUp,
                "on-keydown": _vm.inputKeyDown,
                "on-keypress": _vm.inputKeyPress,
                "on-regx-check": _vm.inputRegxCheck,
              },
              model: {
                value: _vm._items.value,
                callback: function ($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "checkbox"
          ? _c("Checkbox", {
              ref: _vm._items.field,
              attrs: {
                disabled:
                  _vm._items.props.disabled || _vm._items.props.readonly,
                "true-value": _vm._items.props.trueValue,
                "false-value": _vm._items.props.falseValue,
                size: _vm._items.props.size,
                circle: _vm._items.props.circle,
              },
              on: { "on-change": _vm.checkBoxChange },
              model: {
                value: _vm._items.value,
                callback: function ($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "select"
          ? _c(
              "Select",
              {
                ref: _vm._items.field,
                attrs: {
                  clearable: _vm._items.props.clearable,
                  multiple: _vm._items.props.multiple,
                  "multiple-type": _vm._items.props.multipleType,
                  disabled:
                    _vm._items.props.disabled || _vm._items.props.readonly,
                  placeholder: !_vm._items.props.disabled
                    ? _vm._items.props.placeholder
                    : "",
                  "not-found-text": _vm._items.props["not-found-text"],
                  "label-in-value": _vm._items.props["label-in-value"],
                  "choose-all": _vm.items.props.chooseAll,
                  placement: _vm._items.props.placement,
                  transfer: _vm._items.props.transfer,
                  "options-visible": _vm._items.props.optionsVisible,
                },
                on: {
                  "on-change": _vm.selectChange,
                  "on-clear": _vm.selectClear,
                  "on-open-change": _vm.selectOpenChange,
                },
                model: {
                  value: _vm._items.value,
                  callback: function ($$v) {
                    _vm.$set(_vm._items, "value", $$v)
                  },
                  expression: "_items.value",
                },
              },
              _vm._l(_vm._items.options, function (item) {
                return _c(
                  "Option",
                  {
                    key: item.value,
                    attrs: { value: item.value, disabled: item.disabled },
                  },
                  [_vm._v("\n         " + _vm._s(item.label) + "\n       ")]
                )
              }),
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "DatePicker"
          ? _c("DatePicker", {
              ref: _vm._items.field,
              attrs: {
                value: _vm._items.value,
                type: _vm._items.props.type,
                transfer: _vm._items.props.transfer,
                format: _vm._items.props.format,
                placement: _vm._items.props.placement,
                placeholder: !_vm._items.props.disabled
                  ? _vm._items.props.placeholder
                  : "",
                options: _vm._items.props.options,
                open: _vm._items.props.open,
                confirm: _vm._items.props.confirm,
                size: _vm._items.props.size,
                disabled:
                  _vm._items.props.disabled || _vm._items.props.readonly,
                clearable: _vm._items.props.clearable,
                readonly:
                  _vm._items.props.disabled || _vm._items.props.readonly,
                editable: _vm._items.props.editable,
              },
              on: {
                "on-change": function ($event, event, instance) {
                  return _vm.datePickerChange(
                    (_vm._items.value = $event),
                    event,
                    instance,
                    _vm._items.props.type
                  )
                },
                "on-clear": _vm.datePickerClear,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "TimePicker"
          ? _c("TimePicker", {
              ref: _vm._items.field,
              attrs: {
                type: _vm._items.props.type,
                transfer: _vm._items.props.transfer,
                steps: _vm._items.props.steps,
                format: _vm._items.props.format,
                placement: _vm._items.props.placement,
                placeholder: !_vm._items.props.disabled
                  ? _vm._items.props.placeholder
                  : "",
                open: _vm._items.props.open,
                confirm: _vm._items.props.confirm,
                size: _vm._items.props.size,
                disabled:
                  _vm._items.props.disabled || _vm._items.props.readonly,
                clearable: _vm._items.props.clearable,
                readonly:
                  _vm._items.props.disabled || _vm._items.props.readonly,
                editable: _vm._items.props.editable,
              },
              on: {
                "on-change": _vm.timePickerChange,
                "on-clear": _vm.timePickerClear,
              },
              model: {
                value: _vm._items.value,
                callback: function ($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "DropDownSelectFilter"
          ? [
              _vm._items.props.fk_type === "drp"
                ? _c("DropDownSelectFilter", {
                    ref: _vm._items.field,
                    attrs: {
                      "class-name": "R3_" + _vm._items.field,
                      data: _vm._items.props.data,
                      single: _vm._items.props.single,
                      singleTurn: true,
                      placeholder: !_vm._items.props.disabled
                        ? _vm._items.props.placeholder
                        : "",
                      "total-row-count": _vm._items.props.totalRowCount,
                      "page-size": _vm._items.props.pageSize,
                      "auto-data": _vm._items.props.AutoData,
                      disabled:
                        _vm._items.props.disabled || _vm._items.props.readonly,
                      "is-show-pop-tip": _vm._items.props.isShowPopTip,
                      "enter-type": _vm._items.props.enterType,
                      hidecolumns: _vm._items.props.hidecolumns,
                      "data-empty-message": _vm._items.props.dataEmptyMessage,
                      "default-selected": _vm._items.props.defaultSelected,
                      transfer: _vm._items.props.transfer,
                    },
                    on: {
                      "on-fkrp-selected": _vm.fkrpSelected,
                      "on-page-change": _vm.pageChange,
                      "on-input-value-change": _vm.inputValueChange,
                      "on-focus": _vm.fkrpSelectedInputFocus,
                      "on-blur": _vm.fkrpSelectedInputBlur,
                      "on-Outside": _vm.Outside,
                      "on-keyup": _vm.fkrpSelectedInputKeyup,
                      "on-keydown": _vm.fkrpSelectedInputKeydown,
                      "on-popper-show": _vm.fkrpSelectedPopperShow,
                      "on-popper-hide": _vm.fkrPopperHide,
                      "on-clear": _vm.fkrpSelectedClear,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._items.props.fk_type === "mrp"
                ? _c("DropMultiSelectFilter", {
                    ref: _vm._items.field,
                    attrs: {
                      "class-name": "R3_" + _vm._items.field,
                      data: _vm._items.props.data,
                      singleTurn: true,
                      single: _vm._items.props.single,
                      placeholder: !_vm._items.props.disabled
                        ? _vm._items.props.placeholder
                        : "",
                      "total-row-count": _vm._items.props.totalRowCount,
                      "page-size": _vm._items.props.pageSize,
                      "auto-data": _vm._items.props.AutoData,
                      disabled:
                        _vm._items.props.disabled || _vm._items.props.readonly,
                      "is-show-pop-tip": _vm._items.props.isShowPopTip,
                      "enter-type": _vm._items.props.enterType,
                      hidecolumns: _vm._items.props.hidecolumns,
                      "data-empty-message": _vm._items.props.dataEmptyMessage,
                      "default-selected": _vm._items.props.defaultSelected,
                      transfer: _vm._items.props.transfer,
                    },
                    on: {
                      "on-Outside": _vm.Outside,
                      "on-fkrp-selected": _vm.fkrpSelected,
                      "on-page-change": _vm.pageChange,
                      "on-input-value-change": _vm.inputValueChange,
                      "on-focus": _vm.fkrpSelectedInputFocus,
                      "on-blur": _vm.fkrpSelectedInputBlur,
                      "on-keyup": _vm.fkrpSelectedInputKeyup,
                      "on-popper-show": _vm.fkrpSelectedPopperShow,
                      "on-popper-hide": _vm.fkrPopperHide,
                      "on-clear": _vm.fkrpSelectedClear,
                    },
                  })
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "AttachFilter"
          ? _c("ComAttachFilter", {
              ref: _vm._items.field,
              attrs: {
                "default-value": _vm._items.value,
                "default-selected": _vm._items.props.Selected,
                propstype: _vm._items.props,
              },
              on: {
                keydown: _vm.attachFilterInputKeydown,
                valuechange: _vm.attachFilterInput,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "ImageUpload"
          ? _c(
              "div",
              { staticClass: "img-upload-wrap" },
              [
                _vm._items.type === "ImageUpload"
                  ? _c("ImageUpload", {
                      ref: _vm._items.field,
                      staticClass: "img-upload-component",
                      attrs: {
                        dataitem: Object.assign(_vm._items.props.itemdata, {
                          readonly: _vm._items.props.readonly,
                        }),
                      },
                      on: {
                        deleteImg: _vm.deleteImg,
                        uploadFileChangeOnloadstart:
                          _vm.uploadFileChangeOnloadstart,
                        uploadFileChangeSuccess: _vm.uploadFileChangeSuccess,
                        uploadFileChangeProgress: _vm.uploadFileChangeProgress,
                        uploadFileChangeOnerror: _vm.uploadFileChangeOnerror,
                        dblclick: _vm.uploadFileDblclick,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._items.type === "ImageUpload" &&
                _vm.showImgUploadProcess &&
                _vm.imgProgressController
                  ? _c(
                      "div",
                      { staticClass: "img-process" },
                      [
                        _c(
                          "i-circle",
                          {
                            staticClass: "img-process",
                            attrs: {
                              percent: _vm.uploadProgress,
                              "stroke-color":
                                _vm.uploadProgress === 100
                                  ? "#5cb85c"
                                  : "rgb(45, 140, 240)",
                            },
                          },
                          [
                            _vm.uploadProgress === 100
                              ? _c("Icon", {
                                  staticStyle: { color: "#5cb85c" },
                                  attrs: { type: "ios-checkmark", size: "60" },
                                })
                              : _c(
                                  "span",
                                  { staticStyle: { "font-size": "24px" } },
                                  [_vm._v(_vm._s(_vm.uploadProgress) + "%")]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "EnumerableInput"
          ? _c("EnumerableInput", {
              ref: _vm._items.field,
              attrs: {
                "default-value": _vm._items.value,
                disabled:
                  _vm._items.props.disabled || _vm._items.props.readonly,
              },
              on: {
                keydown: _vm.enumerKeydown,
                valueChange: _vm.enumerableValueChange,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "Wangeditor"
          ? [
              _vm._items.type === "Wangeditor"
                ? _c(_vm._items.componentType, {
                    key: _vm.index,
                    ref: _vm._items.field,
                    tag: "component",
                    attrs: {
                      "is-actives": _vm._items.props.readonly,
                      valuedata: _vm._items.value,
                      item: _vm._items.props,
                    },
                    on: { getChangeItem: _vm.getWangeditorChangeItem },
                  })
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "docfile"
          ? _c("Docfile", {
              ref: _vm._items.field,
              attrs: {
                "item-webconf": _vm._items.props.webconf,
                "web-conf-single": _vm.webConfSingle,
                dataitem: _vm._items.props.itemdata,
              },
              on: { filechange: _vm.filechange },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "radioGroup"
          ? _c("radio-group", {
              ref: _vm._items.field,
              attrs: { options: _vm._items.props },
              on: { change: _vm.radioValueChange },
              model: {
                value: _vm._items.value,
                callback: function ($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "checkboxgroup"
          ? _c("checkbox-group", {
              ref: _vm._items.field,
              attrs: { options: _vm._items.props },
              on: { change: _vm.checkboxGroupValueChange },
              model: {
                value: _vm._items.value,
                callback: function ($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "MonthDay"
          ? _c("MonthDay", {
              ref: _vm._items.field,
              attrs: {
                placeholder: _vm._items.props.placeholder,
                disabled: _vm._items.props.disabled,
                editable: false,
                transfer: true,
              },
              on: { "on-change": _vm.monthDayValueChange },
              model: {
                value: _vm._items.value,
                callback: function ($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "String"
          ? _c("string-render", {
              ref: _vm._items.field,
              attrs: {
                customizedDefaultValue: _vm._items.props.customizedDefaultValue,
                options: _vm._items.props,
              },
              model: {
                value: _vm._items.value,
                callback: function ($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "iconfontpicker"
          ? _c("arkIconfontPicker", {
              ref: _vm._items.field,
              attrs: {
                placeholder: _vm._items.props.placeholder,
                disabled: _vm._items.props.disabled,
                transferJson: "",
              },
              on: { change: _vm.iconValueChange },
              model: {
                value: _vm._items.value,
                callback: function ($$v) {
                  _vm.$set(_vm._items, "value", $$v)
                },
                expression: "_items.value",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "customization"
          ? _c(_vm._items.componentName, {
              ref: _vm._items.field,
              tag: "component",
              attrs: {
                options: Object.assign({}, _vm._items, {
                  webConfSingle: _vm.webConfSingle,
                  index: _vm.index,
                  formIndex: _vm.formIndex,
                }),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._items.type === "defined"
          ? [
              _c("Defined", {
                attrs: {
                  readonly: _vm._items.props.readonly,
                  itemdata: _vm._items.props,
                  items: _vm._items,
                },
                on: { "on-change": _vm.definedChange },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }