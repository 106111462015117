var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.slotTemple,
    {
      ref: "slotTemple",
      tag: "component",
      class: _vm.classes,
      attrs: { id: _vm.currentTableName },
    },
    [
      _c(_vm.currentSingleButtonComponentName, {
        tag: "component",
        attrs: {
          slot: "v-object-button",
          tabcmd: _vm.mainFormInfo.buttonsData.data.tabcmd,
          "object-type": "vertical",
          "item-table-check-func": _vm.itemTableCheckFunc,
          isreftabs: _vm.mainFormInfo.buttonsData.data.isreftabs,
          isactive: _vm.mainFormInfo.buttonsData.data.isactive,
          watermarkimg: _vm.resetWaterMark,
          "item-name-group": _vm.childTableNames,
          "item-info": _vm.mainFormInfo,
          tabwebact: _vm.mainFormInfo.buttonsData.data.tabwebact,
          "item-name": _vm.getItemName,
          "is-main-form": _vm.mainFormInfo,
        },
        slot: "v-object-button",
      }),
      _vm._v(" "),
      _c("panelForm", {
        attrs: {
          slot: "v-object-from",
          tableName: _vm.$route.params.tableName,
          readonly: _vm.objreadonly,
          defaultData:
            Object.keys(_vm.defaultDataForCopy).length > 0
              ? _vm.defaultDataForCopy.data
              : _vm.mainFormInfo.formData.data,
        },
        slot: "v-object-from",
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "verticalTabs",
          attrs: { slot: "v-object-TabPanels" },
          slot: "v-object-TabPanels",
        },
        [
          _c("TabPanels", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabPanels.length > 0,
                expression: "tabPanels.length > 0",
              },
            ],
            ref: "tabPanel",
            staticClass: "tabPanel tabPanel-sublist",
            attrs: {
              "tab-margin-left": 20,
              "is-keep-alive": "",
              beforeLeave: _vm.tabBeforeLeave,
              type: "singleCard",
              "tab-panels": _vm.tabPanels,
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "panelForm",
            attrs: { id: "verticalTabs_panelForm" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }