var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "buttonGroup" },
        [
          _c(
            "Button",
            {
              attrs: { type: "posdefault" },
              on: {
                click: function ($event) {
                  return _vm.saveCubeReport()
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("metricsDefinition.saveDefinition")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "Button",
            {
              attrs: { type: "fcdefault" },
              on: {
                click: function ($event) {
                  return _vm.saveCubeReport()
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("metricsDefinition.saveDefinitionSeparately")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Row", { staticClass: "report-intro" }, [
        _c("span", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("metricsDefinition.factTable")) +
              "：\n      "
          ),
          _c("em", [_vm._v(_vm._s(_vm.adTableInfo.NAME))]),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("metricsDefinition.reportTemplate")) +
              "：\n      "
          ),
          _c("em", [_vm._v(_vm._s(_vm.adTableInfo.DESCRIPTION))]),
        ]),
      ]),
      _vm._v(" "),
      _c("main", [
        _c("div", { staticClass: "parent-box" }, [
          _c("div", { staticClass: "list" }, [
            _c(
              "div",
              { staticClass: "list-header" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("metricsDefinition.availableField")) +
                    "\n          "
                ),
                _c(
                  "Input",
                  {
                    staticStyle: { width: "92px", height: "20px" },
                    attrs: {
                      placeholder: _vm.$t("metricsDefinition.enterFieldName"),
                      expand: false,
                    },
                    on: {
                      "on-enter": function ($event) {
                        return _vm.fieldQuery()
                      },
                    },
                    model: {
                      value: _vm.fieldName,
                      callback: function ($$v) {
                        _vm.fieldName = $$v
                      },
                      expression: "fieldName",
                    },
                  },
                  [
                    _c("Icon", {
                      attrs: { slot: "suffix", type: "ios-search" },
                      on: {
                        click: function ($event) {
                          return _vm.fieldQuery()
                        },
                      },
                      slot: "suffix",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "treeBox" },
              [
                _c("Tree", {
                  ref: "treeAllCheck",
                  attrs: {
                    id: "treeIcon",
                    data: _vm.list,
                    "load-data": _vm.loadListData,
                    "show-checkbox": "",
                  },
                  on: { "on-check-change": _vm.onCheckChange },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "child-box" },
          _vm._l(_vm.childBoxList, function (item, idx) {
            return _c("div", { key: idx, staticClass: "child-item" }, [
              _c(
                "div",
                { staticClass: "burgeon-transfer-operation" },
                [
                  _c(
                    "Button",
                    {
                      attrs: {
                        disabled: _vm.isParentBtnDisabled,
                        type: "posdefault",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getData(idx, item.dataName)
                        },
                      },
                    },
                    [_vm._v("\n            >\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      attrs: {
                        disabled: item.isChildBtnDisabled,
                        type: "posdefault",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeData(idx)
                        },
                      },
                    },
                    [_vm._v("\n            <\n          ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "child-list" },
                [
                  _c("div", { staticClass: "list" }, [
                    _c("div", { staticClass: "list-header" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.name) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "list-body" },
                      [
                        _c(
                          "CheckboxGroup",
                          {
                            on: {
                              "on-change": function ($event) {
                                return _vm.move("child", idx)
                              },
                            },
                            model: {
                              value: item.childCheckedArr,
                              callback: function ($$v) {
                                _vm.$set(item, "childCheckedArr", $$v)
                              },
                              expression: "item.childCheckedArr",
                            },
                          },
                          [
                            _c(
                              "ul",
                              [
                                _c(
                                  "draggable",
                                  _vm._b(
                                    {
                                      on: { update: _vm.datadragEnd },
                                      model: {
                                        value: item.childList,
                                        callback: function ($$v) {
                                          _vm.$set(item, "childList", $$v)
                                        },
                                        expression: "item.childList",
                                      },
                                    },
                                    "draggable",
                                    _vm.dragOptions,
                                    false
                                  ),
                                  [
                                    _c(
                                      "transition-group",
                                      _vm._l(
                                        item.childList,
                                        function (item1, i) {
                                          return _c(
                                            "li",
                                            {
                                              key: "" + (i + 1),
                                              on: {
                                                dblclick: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.dbClick(
                                                    item,
                                                    item1,
                                                    idx,
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "Checkbox",
                                                { attrs: { label: item1.ID } },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        item1.DESCRIPTION
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      staticClass: "set-data-btn",
                      attrs: {
                        type: "fcdefault",
                        size: "small",
                        disabled: item.isSetDataDisabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showSetModal(idx)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(item.setBtnTitle) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "Checkbox",
                    {
                      attrs: {
                        indeterminate: item.indeterminate,
                        value: item.checkBox,
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.checkBatchChange(idx)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("metricsDefinition.ifSelectAll")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "tips" },
        [
          _c("Icon", { attrs: { type: "ios-alert-outline" } }),
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("metricsDefinition.lessGroupingField")) +
              "\n  "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: {
            draggable: "",
            scrollable: "",
            title:
              _vm.currentIdx != 3
                ? _vm.$t("metricsDefinition.editGroupField")
                : _vm.$t("metricsDefinition.editSummaryField"),
          },
          on: {
            "on-ok": function ($event) {
              return _vm.saveSetModal()
            },
          },
          model: {
            value: _vm.isSetDialogShow,
            callback: function ($$v) {
              _vm.isSetDialogShow = $$v
            },
            expression: "isSetDialogShow",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentIdx != 3,
                  expression: "currentIdx != 3",
                },
              ],
            },
            [
              _c(
                "Form",
                {
                  ref: "groupForm",
                  attrs: {
                    model: _vm.groupForm,
                    rules: _vm.groupRule,
                    "label-width": 120,
                  },
                },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label:
                          _vm.$t("metricsDefinition.fieldReference") + "：",
                        prop: "COLUMN_LINK",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.groupForm.COLUMN_LINK,
                          callback: function ($$v) {
                            _vm.$set(_vm.groupForm, "COLUMN_LINK", $$v)
                          },
                          expression: "groupForm.COLUMN_LINK",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: _vm.$t("tips.description") + "：",
                        prop: "DESCRIPTION",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.groupForm.DESCRIPTION,
                          callback: function ($$v) {
                            _vm.$set(_vm.groupForm, "DESCRIPTION", $$v)
                          },
                          expression: "groupForm.DESCRIPTION",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: _vm.$t("metricsDefinition.hideInReport") + "：",
                      },
                    },
                    [
                      _c("Checkbox", {
                        model: {
                          value: _vm.groupForm.HIDE_HTML,
                          callback: function ($$v) {
                            _vm.$set(_vm.groupForm, "HIDE_HTML", $$v)
                          },
                          expression: "groupForm.HIDE_HTML",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentIdx == 3,
                  expression: "currentIdx == 3",
                },
              ],
            },
            [
              _c(
                "Form",
                {
                  ref: "factListForm",
                  attrs: {
                    model: _vm.factListForm,
                    rules: _vm.factListRule,
                    "label-width": 100,
                  },
                },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: _vm.$t("metricsDefinition.summaryField") + "：",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.factListForm.COLUMN_LINK,
                          callback: function ($$v) {
                            _vm.$set(_vm.factListForm, "COLUMN_LINK", $$v)
                          },
                          expression: "factListForm.COLUMN_LINK",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: _vm.$t("metricsDefinition.customColumn") + "：",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.factListForm.USER_FACT,
                          callback: function ($$v) {
                            _vm.$set(_vm.factListForm, "USER_FACT", $$v)
                          },
                          expression: "factListForm.USER_FACT",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: _vm.$t("metricsDefinition.variableName") + "：",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.factListForm.VALUE_NAME,
                          callback: function ($$v) {
                            _vm.$set(_vm.factListForm, "VALUE_NAME", $$v)
                          },
                          expression: "factListForm.VALUE_NAME",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label:
                          _vm.$t("metricsDefinition.statisticalFunction") +
                          "：",
                        prop: "FUNCTION_NAME",
                      },
                    },
                    [
                      _c(
                        "Select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.factListForm.FUNCTION_NAME,
                            callback: function ($$v) {
                              _vm.$set(_vm.factListForm, "FUNCTION_NAME", $$v)
                            },
                            expression: "factListForm.FUNCTION_NAME",
                          },
                        },
                        _vm._l(_vm.functionNameList, function (item) {
                          return _c(
                            "Option",
                            { key: item.value, attrs: { value: item.value } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.label) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: _vm.$t("tips.description") + "：",
                        prop: "DESCRIPTION",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.factListForm.DESCRIPTION,
                          callback: function ($$v) {
                            _vm.$set(_vm.factListForm, "DESCRIPTION", $$v)
                          },
                          expression: "factListForm.DESCRIPTION",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: _vm.$t("metricsDefinition.displayFormat") + "：",
                        prop: "VALUE_FORMAT",
                      },
                    },
                    [
                      _c(
                        "Select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.factListForm.VALUE_FORMAT,
                            callback: function ($$v) {
                              _vm.$set(_vm.factListForm, "VALUE_FORMAT", $$v)
                            },
                            expression: "factListForm.VALUE_FORMAT",
                          },
                        },
                        _vm._l(_vm.valueFormatList, function (item) {
                          return _c(
                            "Option",
                            { key: item.value, attrs: { value: item.value } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.label) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: _vm.$t("metricsDefinition.securityLevel") + "：",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.factListForm.SECURITY_GRADE,
                          callback: function ($$v) {
                            _vm.$set(_vm.factListForm, "SECURITY_GRADE", $$v)
                          },
                          expression: "factListForm.SECURITY_GRADE",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }