var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes, attrs: { id: _vm.id } },
    _vm._l(Object.keys(_vm.formItemLists), function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "formItem" },
        [
          _c(
            _vm.CollapseComponent,
            {
              tag: "component",
              attrs: {
                id: "Collapse_" + item,
                value: _vm.collapseValue,
                tableName: _vm.tableName,
                isMainTable: _vm.isMainTable,
                isTitleShow: _vm.formItemLists[item].parentdesc ? true : false,
                parentdesc: _vm.formItemLists[item].parentdesc,
                keyName: "" + _vm.formItemLists[item].parentdesc,
                name: _vm.formItemLists[item].parentname,
              },
            },
            [
              _c(
                "div",
                { staticClass: "content R3masterForm", style: _vm.setWidth },
                _vm._l(
                  Object.keys(_vm.formItemLists[item].childs),
                  function (temp, index) {
                    return _c(
                      "div",
                      {
                        key: _vm.formItemLists[item].childs[temp]._index,
                        staticClass: "R3masterForm_item",
                        style: _vm.formItemLists[item].childs[temp].styles,
                        attrs: { index: index },
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _c(_vm.formItemLists[item].childs[temp].component, {
                              ref: "formItem",
                              refInFor: true,
                              tag: "component",
                              attrs: {
                                id: _vm.formItemLists[item].childs[temp]
                                  .colname,
                                items: _vm.formItemLists[item].childs[temp],
                                "label-width": _vm.labelWidth,
                              },
                              on: { "on-keydown": _vm.enterForm },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }