var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dispalyArea",
      attrs: { "data-keep-alive": _vm.keepAliveContainer },
    },
    [
      _c("TabLists", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.enableTabLists &&
              _vm.openedMenuLists.length > 0 &&
              !_vm.flag &&
              !_vm.displayAreas,
            expression:
              "enableTabLists&&openedMenuLists.length>0&&!flag && !displayAreas",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                (_vm.openedMenuLists.length > 0 && !_vm.flag) ||
                !_vm.getDashboardConfig,
              expression:
                "(openedMenuLists.length>0&&!flag)||!getDashboardConfig",
            },
          ],
          staticStyle: {
            height: "100%",
            padding: "0 15px 0",
            overflow: "auto",
            position: "relative",
          },
          attrs: { id: "content" },
        },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.keepAliveContainer } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.getDashboardConfig && _vm.flag,
              expression: "getDashboardConfig&&flag",
            },
          ],
          staticStyle: {
            height: "100%",
            padding: "0 15px 0",
            overflow: "auto",
            position: "relative",
          },
          attrs: { id: "content" },
        },
        [
          _vm.getDashboardConfig &&
          _vm.getDashboardConfig.compontent &&
          _vm.display
            ? _c(_vm.getDashboardConfig.compontent, { tag: "component" })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }