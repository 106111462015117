var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.verticalCls },
    [
      _c("div", { class: _vm.classes }, [
        _c(
          "div",
          {},
          _vm._l(_vm.menuLists, function (menu, index) {
            return _c("NavigatorPrimaryMenu", {
              key: "primary-menu-" + index,
              attrs: { data: menu, type: "Vertical", index: index },
              on: { togglePrimaryMenu: _vm.togglePrimaryMenu },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c("NavigatorSubMenu", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.primaryMenuIndex !== -1,
                expression: "primaryMenuIndex!==-1",
              },
            ],
            ref: "NavigatorSubMenu",
            attrs: { data: _vm.togglePrimaryMenuData || [] },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }