<template>
  <div>
    <Select
      multiple
      :value="value"
      ref="Select"
      :chooseAll="chooseAll"
      clearable
      class="select-exclude-div"
      :disabled="disabled"
      :placeholder="placeholder"
      @on-clear="clear"
      @on-selectAll="selectAll"
      @on-change="change"
    >
      <li slot="transition-head" class="exclusive-li">
         <span>{{$t('choice')}}</span><span>{{$t('actions.exclude')}}</span>
      </li>
      <Option
        v-for="(item, index) in optionData"
        :ref="`Option_${index}`"
        :label="item.label"
        :value="item.value"
        @click.stop ="remove"
        class="exclusive-li-bar"
        :key="item.value"
      >
        <div
          @click.stop ="remove"
          :class="
            `exclusive-bar ${item.disabled ? 'exclusive-icon-active' : ''}`
          "
        >
        
     
          <Icon
            type="ios-close"
            class="exclusive-icon"
            :disabled="item.type === 1"
            @click.stop="setState(item, index,$event)"
          /><span @click.stop ="remove(item, index)">{{ item.label }}</span>
        </div>
      </Option>
    </Select>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  model: {
    props: 'value',
    event: 'change'
  },
  props: {
    // 双向绑定值
    value: {
      type: [String, Array],
    },
    keydata:{
      type: [Object],

    },
    // 外部传入的所有参数
    options: {
      type: Array,
      default: () => []
    },
    placeholder:{
      type: [String]
    },
    disabled:{
      type: Boolean,
      default: false
    },
    chooseAll:{
      type: [Boolean]

    }
  },
  data() {
    return {
        optionData:[],
        fieldData:{},
        selectValue:[]
    }
  },
  mounted(){
    this.optionData = this.initdata(JSON.parse(JSON.stringify(this.options)));
  },
  watch:{
      options(val){
         this.optionData = this.initdata(JSON.parse(JSON.stringify(val)));
      }
  },

  methods: {
    remove(){
      return false;
    },
    selectAll($this){
      let values = $this.values;
       this.clear();
       $this.values = values;
    },
    change(val){
        this.selectValue = val;
    },
    getValueData(){
        let exclusivedata = this.optionData.reduce((obj,item)=>{
          // 获取排斥的数据
          if(item.disabled){
              let data = JSON.parse(JSON.stringify(this.keydata[item.colname]));
              if( obj[item.colname]){
                  data = obj[item.colname]
              }
              let index = data.indexOf(`=${item.columnsValue}`);

              if(index!==-1){
                  data.splice(index,1);
              }
              obj[item.colname] = data;
          }
          return obj;
        },{});
       let selectData = {};
       if(Array.isArray(this.selectValue)){
        //  循环选中的数据且排除排除的
         this.selectValue.forEach((option)=> {
            let item = option.split(':');
            if(!selectData[item[0]] && !exclusivedata[item[0]]){
                selectData[item[0]] = [`=${item[1]}`]
            }else if(!exclusivedata[item[0]]){
              if( selectData[item[0]].indexOf(`=${item[1]}`)=== -1){
                  selectData[item[0]].push(`=${item[1]}`)
              }
            }
        });
       }
       return Object.assign(exclusivedata,selectData);
    },
    clear(){
       this.optionData = this.initdata(this.optionData).concat([]);
       this.$refs.Select.reset();
    },
    initdata(data){
      // 初始化下拉列表数据及挂载选中方法
      this.fieldData = {};
      return data.map((item,index)=>{
        item.disabled = false;
        setTimeout(()=>{
          if(this.$refs[`Option_${index}`]){
              let select = this.$refs[`Option_${index}`][0].select;
              this.$refs[`Option_${index}`][0].select = function(event){
                if(event){
                    if(event.target && event.target.tagName.toUpperCase() ==='INPUT'){
                      // 选中事件
                      if(this.setDisabled){
                          this.setLabel = this.label;
                              this.setDisabled = false;
                              item.disabled = false;
                              select();
                          setTimeout(()=>{
                               select();
                          },10)
                      }else{
                          select();
                      }
                    }else if(event.target.tagName.toUpperCase() ==='I'){
                      // 排斥事件
                      select(event);
                    }
                }else if(!event){
                      select();
                }
              }
          }
        },200)
        if( this.$refs[`Option_${index}`]){
           this.$refs[`Option_${index}`][0].setDisabled = false;
           this.$refs[`Option_${index}`][0].setLabel = item.label;
        }
        return item;
      });

    },
    setState(item,index,$event){
         if(item.type === 1){
          //  来源字段不是字段选项组
            return false;
         }
         if(!this.$refs[`Option_${index}`]){
            return false;
         }
          this.$refs[`Option_${index}`][0].setDisabled = !this.$refs[`Option_${index}`][0].setDisabled;
           item.disabled =  this.$refs[`Option_${index}`][0].setDisabled;
          if (this.$refs[`Option_${index}`][0].setDisabled) {
            this.$refs[`Option_${index}`][0].setLabel = `[${this.$t('actions.exclude')}]`+item.label;
             if(this.$refs[`Option_${index}`][0].selected){
                this.$refs[`Option_${index}`][0].select($event);
            }
          }else{
              this.$refs[`Option_${index}`][0].setLabel = item.label;
          }
          this.$refs[`Option_${index}`][0].select($event);

    }

  }
}
</script>
