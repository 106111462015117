var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "titleTOP" }, [
        _vm._v(_vm._s(_vm.$t("tips.accountLogin"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "divAccount" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("../../assets/image/account.png") },
        }),
        _vm._v(" "),
        _c("input", {
          ref: "username",
          staticClass: "username",
          attrs: {
            type: "text",
            value: "",
            placeholder: _vm.$t("messages.enterUserName"),
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "divMima" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("../../assets/image/password.png") },
        }),
        _vm._v(" "),
        _c("input", {
          ref: "password",
          staticClass: "pwd",
          attrs: {
            type: "password",
            value: "",
            placeholder: _vm.$t("messages.enterPassword"),
          },
        }),
      ]),
      _vm._v(" "),
      _vm.loginType
        ? _c("div", { staticClass: "divCode" }, [
            _c("img", {
              staticClass: "icon",
              attrs: { src: require("../../assets/image/code.png") },
            }),
            _vm._v(" "),
            _c("input", {
              ref: "code",
              staticClass: "pwd code",
              attrs: { value: "", placeholder: _vm.$t("messages.enterCode") },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("ErCode", {
        ref: "ercode",
        attrs: { visible: _vm.loginType },
        on: { refresh: _vm.getNewCode },
      }),
      _vm._v(" "),
      _vm.moveBarshow
        ? _c("MoveBar", { ref: "movebar", on: { "on-change": _vm.moveChange } })
        : _vm._e(),
      _vm._v(" "),
      _vm.loginType
        ? _c("div", { staticClass: "divToggle" }, [
            _c("span", { staticClass: "sanjiao" }, [
              _c("img", {
                staticClass: "toggle phone",
                attrs: { src: require("../../assets/image/phone.png") },
                on: { click: _vm.toggles },
              }),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }