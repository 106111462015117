var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Select",
        {
          ref: "Select",
          staticClass: "select-exclude-div",
          attrs: {
            multiple: "",
            value: _vm.value,
            chooseAll: _vm.chooseAll,
            clearable: "",
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
          },
          on: {
            "on-clear": _vm.clear,
            "on-selectAll": _vm.selectAll,
            "on-change": _vm.change,
          },
        },
        [
          _c(
            "li",
            {
              staticClass: "exclusive-li",
              attrs: { slot: "transition-head" },
              slot: "transition-head",
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("choice")))]),
              _c("span", [_vm._v(_vm._s(_vm.$t("actions.exclude")))]),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.optionData, function (item, index) {
            return _c(
              "Option",
              {
                key: item.value,
                ref: "Option_" + index,
                refInFor: true,
                staticClass: "exclusive-li-bar",
                attrs: { label: item.label, value: item.value },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.remove($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    class:
                      "exclusive-bar " +
                      (item.disabled ? "exclusive-icon-active" : ""),
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.remove($event)
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      staticClass: "exclusive-icon",
                      attrs: { type: "ios-close", disabled: item.type === 1 },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.setState(item, index, $event)
                        },
                      },
                    }),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.remove(item, index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.label))]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }