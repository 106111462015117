var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("contentdiv"),
      _vm._v(" "),
      _c("SkqTasksDialog", {
        attrs: { type: "list" },
        on: { "on-close": _vm.handleClose },
        model: {
          value: _vm.showTasks,
          callback: function ($$v) {
            _vm.showTasks = $$v
          },
          expression: "showTasks",
        },
      }),
      _vm._v(" "),
      _c("SkqTasksDialog", {
        attrs: { type: "notice" },
        on: { "on-close": _vm.handleClose },
        model: {
          value: _vm.showTasksNotice,
          callback: function ($$v) {
            _vm.showTasksNotice = $$v
          },
          expression: "showTasksNotice",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }