/**
 * 解析url参数
 * @example  ?id=1&table=pro
 * @return Object {id:1,table:pro}
 * */

import urlParse from './urlParse';

export const getUrlParse = (key)=> {
  // 返回路由上的参数
  try {
    if(urlParse()[key]){
      return JSON.parse(urlParse()[key]) || '';
    }
    return ''
  } catch (error) {
    console.log(error)
    return ''
  }
}

// url添加来源originSource
export function setPathOriginSource(path,originSource) {
   // 来源数据
   if(originSource){
    if(path.includes('?') && ! path.includes('originSource')){
      path = `${path}&originSource=${encodeURI(JSON.stringify(originSource))}`
    }else{
      path = `${path}?originSource=${encodeURI(JSON.stringify(originSource))}`
    }
  }
  return path
}