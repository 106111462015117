var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Collapse",
    { attrs: { value: _vm.value, "data-name": _vm.name } },
    [
      _c(
        "Panel",
        {
          attrs: {
            "title-type": "center",
            isTitleShow: _vm.isTitleShow,
            name: _vm.name,
          },
        },
        [
          _vm._v("\n          " + _vm._s(_vm.parentdesc) + "\n          "),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }