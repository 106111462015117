var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "R3-accout-item",
      class: [_vm.currentPermissionsIndex === _vm.index ? "hight-light" : ""],
      on: {
        mouseenter: _vm.mouseenter,
        mouseleave: _vm.mouseleave,
        click: _vm.manageAuthority,
      },
    },
    [
      _c("div", { staticClass: "accout-item-l" }, [
        _c("div", { staticClass: "accout-row" }, [
          _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$t("messages.accountName")) + "："),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "value one-line-flow" }, [
            _vm._v(_vm._s(_vm.itemInfo.name)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "app-wrap" }, [
          _c("div", { staticClass: "app-info key" }, [
            _c("span", { staticClass: "label" }, [_vm._v("appKey：")]),
            _c("span", { staticClass: "value " }, [
              _vm._v(_vm._s(_vm.itemInfo.credentialKey)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "app-info" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("appSecret：")]),
              _c("span", { staticClass: "value serect" }, [
                _vm._v(_vm._s(_vm.itemInfo.credentialSecret)),
              ]),
              _vm._v(" "),
              _c(
                "Tooltip",
                {
                  attrs: {
                    content: _vm.$t("messages.refreshKey"),
                    placement: "top",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "refresh",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.refresh($event)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "refresh-icon",
                        attrs: {
                          src: require("../../assets/image/refresh.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.showButtons || _vm.currentPermissionsIndex === _vm.index,
              expression: "showButtons || currentPermissionsIndex === index",
            },
          ],
          staticClass: "accout-item-r",
        },
        [
          _c(
            "div",
            {
              staticClass: "delete-icon",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.deleteAccount($event)
                },
              },
            },
            [_c("i", { staticClass: "iconfont iconbj_delete" })]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }