var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DragList", {
    class: _vm.prefixClass,
    attrs: {
      axis: "y",
      "lock-to-container-edges": true,
      "press-delay": 0,
      shouldCancelStart: _vm.shouldCancelStart,
      "helper-class": "field-drag",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "field-item" }, [
              _c(
                "div",
                { staticClass: "field-item-l" },
                [
                  _vm.enableSort && item.value !== "ID"
                    ? _c(
                        "Tooltip",
                        {
                          staticClass: "ak-drag-pop",
                          attrs: {
                            transfer: "",
                            placement: "top",
                            content: item.IS_ORDER
                              ? _vm.$t("fieldConfig.disableSort")
                              : _vm.$t("fieldConfig.enableSort"),
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-sort1",
                            class: {
                              "ark-btn-ghost ark-btn-primary": item.IS_ORDER,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleDrag(item)
                              },
                            },
                          }),
                        ]
                      )
                    : _c("span", {
                        staticStyle: { width: "16px", display: "inline-block" },
                      }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "field-name",
                      attrs: {
                        "data-enable-drag": !(
                          item.value === "ID" && _vm.enableSort
                        ),
                        title: item.value,
                      },
                    },
                    [_vm._v(_vm._s(item.value))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !(item.value === "ID" && _vm.enableSort)
                ? _c("i", {
                    staticClass: "iconfont icon-md-close",
                    on: {
                      click: function ($event) {
                        return _vm.handleDelete($event, item.value)
                      },
                    },
                  })
                : _vm._e(),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.list,
      callback: function ($$v) {
        _vm.list = $$v
      },
      expression: "list",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }