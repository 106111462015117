var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("div", { staticClass: "pop-title" }, [
      _c("div", { staticClass: "pop-input" }, [
        _c("ul", [
          _c("li", [
            _c("span", [
              _vm._v(_vm._s(_vm.chineseName.SOURCETABLENAME) + "："),
            ]),
            _vm._v("\n          " + _vm._s(_vm.o_table_name) + "\n        "),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "resTop" }, [
            _c("span", [
              _c("sub", [_vm._v(" *")]),
              _vm._v(_vm._s(_vm.$t("tips.versionNumber")) + "："),
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "version" },
              [
                _c("DropDownSelectFilter", {
                  attrs: {
                    single: true,
                    data: _vm.version.data,
                    "total-row-count": _vm.version.totalRowCount,
                    "data-empty-message": _vm.version.dataEmptyMessage,
                    "auto-data": _vm.version.AutoData,
                  },
                  on: {
                    "on-popper-show": _vm.getVersion,
                    "on-fkrp-selected": _vm.getVersionID,
                    "on-input-value-change": _vm.fuzzyquerybyak,
                    "on-clear": _vm.clear,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("span", [
              _c("sub", [_vm._v(" *")]),
              _vm._v(_vm._s(_vm.chineseName.TARGETTABLENVARCHAR) + "："),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.t_table_name,
                  expression: "t_table_name",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.t_table_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.t_table_name = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("span", [
              _c("sub", [_vm._v(" *")]),
              _vm._v(_vm._s(_vm.chineseName.TARGETDESCRIPTION) + "："),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.s_table_name,
                  expression: "s_table_name",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.s_table_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.s_table_name = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pop-btn" }, [
        _c("button", { staticClass: "sav-btn", on: { click: _vm.save } }, [
          _c("span", [_vm._v(_vm._s(_vm.chineseName.CONFIRM))]),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "cancel-btn", on: { click: _vm.cancel } }, [
          _c("span", [_vm._v(_vm._s(_vm.chineseName.CANCEL))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }