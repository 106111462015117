<template>
  <div style="height: 100%; width: 100%">
    <RouterView />
      <DrawerIframe>
    </DrawerIframe>
  </div>
</template>

<script>
  import { hideMenu, launchNetworkMonitor } from './__config__/event.config';
  import { emptyRecord, emptySearch } from './__utils__/indexedDB';
  import network from './__utils__/network';
  import { Version,enableInitializationRequest, cbs, dateStorageTime } from './constants/global';
  import DispatchEvent from './__utils__/dispatchEvent';
  import DrawerIframe from './__component__/Dialog/DrawerIframe';
  import Vue from 'vue';
  // 侧边弹窗注册成全局
  Vue.component('DrawerIframe',DrawerIframe);

  export default {
    name: 'App',
    mounted() {
      const loginTime = window.sessionStorage.getItem('loginTime');
      if (loginTime && ((Date.now() - window.parseInt16(loginTime)) < 3000)) {
        if (cbs() && typeof (cbs().loginCb) === 'function') {
          cbs().loginCb();
        }
      }
      // 执行$BCL 的ag 加载时机
      setTimeout(()=>{
           if($Bcl && $Bcl.arkCommonTableByAgGrid){
             if($Bcl.arkCommonTableByAgGrid.components && typeof $Bcl.arkCommonTableByAgGrid.components.AgGridTable === 'function'){
                $Bcl.arkCommonTableByAgGrid.components.AgGridTable();
             }
          }
      },100)
      hideMenu();
      launchNetworkMonitor();
      emptyRecord(Date.now() - Number(dateStorageTime() ? dateStorageTime() : 1) * 24 * 1000 * 60 * 60);
    },
    async created() {
          // 如果开启系统升级，且需要更新
      if (/iframe=true/.test(window.location.search)) {
         // 路径含有iframe 则不显示导航区
           window.ProjectConfig.displayAreas = 'content';
           // 显示返回上一层
          window.ProjectConfig.ResetrouterBackLogic = true;

          let { showColorSetting } = window.ProjectConfig;
            if (Version() === '1.4' && showColorSetting) {
              // 调用换肤功能
              this.getsystemcolor();
            }

      }
      const { enableSystemUpdate } = window.ProjectConfig
      if(enableSystemUpdate && await this.checkUpdate()) {
        window.ProjectConfig.loginCallback = this.loginSucCbk
        this.$router.push({ path:'/R3UpdateSystem'})
        this.showContainer = true
        return
      }

      this.getUserInfo();
    },

    methods: {
      getsystemcolor() {
      // 获取设置的颜色和展现形式
      network.post('/p/c/getsystemcolor').then((res) => {
        let data = res.data.data;
        if(window['_R3_CHANGETHEME'] && data.color){
            window['_R3_CHANGETHEME'](data.color, this).then((res) => {
                  document.querySelector('body').style.opacity = '1';
            });
        }
      });
    },
      // 检查系统升级
      async checkUpdate() {
        return new Promise((resolve) => {
          network.post(`/p/c/retail/queryLiquibaseExeStatus?hash=${new Date().getTime()}`).then(result => {
            const res = result.data
            if(res.code === 0) {
              resolve(res.data.needUpdate)
              // resolve(true)
            } else {
              resolve(false)
            }
          }).catch(() => {
            resolve(false);
          })
        })
      },

      getUserInfo() {
        if (enableInitializationRequest()) {
          network.get('/p/cs/hello').then((res) => {
            // 此方法用于向外界（JFlow）提供用户信息。供外部处理自己的需要逻辑。
            
            DispatchEvent('userReady', {
              detail: {
                userInfo: JSON.parse(JSON.stringify(res.data))
              }
            });
            if (res.status === 200 && res.data.code === 0) {
              
              this.$store.commit('global/updataUserInfoMessage', {
                userInfo: res.data
              });
              window.localStorage.setItem('userInfo', JSON.stringify(res.data));
              window.localStorage.setItem('sessionCookie',res.data.sessionCookie);
            }
          });
        }
      },
    },
  };
</script>
