var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "reportContainer",
      staticClass: "reportContainer",
      staticStyle: { width: "100%" },
    },
    [
      _c(
        "div",
        { staticClass: "iframe-container", style: { marginTop: "5px" } },
        [
          _c("iframe", {
            ref: "reportIFrame",
            attrs: {
              id: _vm.iframeId,
              width: "100%",
              height: _vm.iframeHeight,
              frameborder: "0",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }