var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("div", { staticClass: "pop-title" }, [
      _c("div", { staticClass: "pop-input" }, [
        _c("ul", [
          _c(
            "li",
            { staticClass: "resTop" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("tips.environment")) + ":")]),
              _vm._v(" "),
              _c(
                "Select",
                {
                  staticStyle: { width: "228px" },
                  model: {
                    value: _vm.envValue,
                    callback: function ($$v) {
                      _vm.envValue = $$v
                    },
                    expression: "envValue",
                  },
                },
                _vm._l(_vm.env, function (item, index) {
                  return _c("Option", { key: index, attrs: { value: item } }, [
                    _vm._v(
                      "\n              " + _vm._s(item) + "\n            "
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pop-btn" }, [
        _c("button", { staticClass: "sav-btn", on: { click: _vm.save } }, [
          _c("span", [_vm._v(_vm._s(_vm.chineseName.CONFIRM))]),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "cancel-btn", on: { click: _vm.cancel } }, [
          _c("span", [_vm._v(_vm._s(_vm.chineseName.CANCEL))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }