import { render, staticRenderFns } from "./AccoutItem.vue?vue&type=template&id=5f683f19&scoped=true&"
import script from "./AccoutItem.vue?vue&type=script&lang=js&"
export * from "./AccoutItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f683f19",
  null
  
)

export default component.exports