var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.openedMenuLists.length > 0 && _vm.showModule.TabLists
    ? _c(
        "div",
        { ref: "openedMenuLists", class: _vm.classes },
        [
          _c(
            "Tabs",
            {
              ref: "tabs",
              staticClass: "openedMenuListsItem",
              attrs: {
                type: "card",
                closable: "",
                title: true,
                closedClick: _vm.handleClose,
                draggable: "",
                value: _vm.tagIndex,
              },
              on: {
                "on-click": _vm.switchTab,
                "on-drag-drop": _vm.handleDragDrop,
              },
            },
            [
              _vm._l(_vm.openedMenuLists, function (tag, index) {
                return _c("TabPane", {
                  key: index,
                  ref: "tabBox",
                  refInFor: true,
                  staticClass: "tabBox",
                  attrs: {
                    id: tag.tableName + "_TAB",
                    label: tag.label,
                    name: index.toString(),
                  },
                })
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "emptying",
                  attrs: { slot: "extra" },
                  on: { click: _vm.emptyClick },
                  slot: "extra",
                },
                [
                  _c("i", {
                    staticClass: "iconfont iconbj_delete ",
                    attrs: { title: _vm.$t("messages.closeBills") },
                  }),
                ]
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }