var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ValidateCom",
    {
      attrs: {
        rules: _vm.rules,
        labelWidth: _vm.labelWidth,
        items: _vm.items,
        colname: _vm.items.colname,
        value: _vm.value,
      },
    },
    [
      _c(
        "div",
        {
          class:
            _vm._items.props.fkdisplay === "pop"
              ? "ItemComponentRoot AttachFilter-pop"
              : "ItemComponentRoot",
        },
        [
          _vm._t("default", [
            _vm.showLabel
              ? _c(
                  "span",
                  { staticClass: "itemLabel", style: _vm.labelStyle },
                  [
                    _vm.items.comment
                      ? _c(
                          "Poptip",
                          {
                            attrs: {
                              "word-wrap": "",
                              trigger: "hover",
                              transfer: "",
                              width: "200",
                              content: _vm.items.comment,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "api",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("span", [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.items.comment)),
                                  ]),
                                  _vm._v(" "),
                                  _vm.items.webconf && _vm.items.webconf.Outside
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.items.webconf.Outside,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v("...")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass:
                                "iconfont iconios-information-circle-outline",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.items.isnotnull
                      ? _c("span", { staticClass: "label-tip" }, [_vm._v("*")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getVersion() === "1.4" &&
                    _vm.items.fkobj &&
                    _vm.items.fkobj.fkdisplay === "pop" &&
                    _vm.items.detailType
                      ? [
                          _vm.value &&
                          _vm.value[0] &&
                          Array.isArray(_vm.value) &&
                          !_vm.items.onjump
                            ? [
                                _c("i", {
                                  staticClass: "iconfont iconbj_link",
                                  staticStyle: {
                                    color: "#0f8ee9",
                                    cursor: "pointer",
                                    "font-size": "12px",
                                  },
                                  attrs: { "data-target-tag": "fkIcon" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.routerNext(_vm.value)
                                    },
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getVersion() === "1.4" &&
                    _vm.items.fkobj &&
                    _vm.items.fkobj.fkdisplay === "drp" &&
                    _vm.items.detailType
                      ? [
                          _vm.value &&
                          _vm.value[0] &&
                          Array.isArray(_vm.value) &&
                          !_vm.items.onjump
                            ? [
                                _c("i", {
                                  staticClass: "iconfont iconbj_link",
                                  staticStyle: {
                                    color: "#0f8ee9",
                                    cursor: "pointer",
                                    "font-size": "12px",
                                  },
                                  attrs: { "data-target-tag": "fkIcon" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.routerNext(_vm.value)
                                    },
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        style: _vm.setlabelStyle,
                        attrs: { title: _vm.items.coldesc },
                      },
                      [_vm._v(_vm._s(_vm.items.coldesc) + ":")]
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  _vm._items.props.row > 1
                    ? "itemComponent height100"
                    : "itemComponent",
                  _vm.items.isuppercase ? "isuppercase" : "",
                ],
                style: _vm._items.display === "image" ? "overflow:visible" : "",
              },
              [
                _c(
                  _vm.componentsName,
                  _vm._b(
                    {
                      ref: _vm.items.colname,
                      tag: "component",
                      on: { "on-keydown": _vm.enterForm },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "value",
                      },
                    },
                    "component",
                    _vm.propsMessage,
                    false
                  ),
                  [
                    _vm.items.display === "OBJ_SELECT"
                      ? _vm._t(
                          "default",
                          _vm._l(_vm.items.props.options, function (item) {
                            return _c(
                              "Option",
                              {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  disabled: item.disabled,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.label) +
                                    "\n          "
                                ),
                              ]
                            )
                          })
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm._items.type === "customization"
                  ? _c(
                      _vm._items.componentName,
                      _vm._b(
                        {
                          ref: _vm.items.colname,
                          tag: "component",
                          attrs: {
                            options: Object.assign({}, _vm._items, {
                              webConfSingle: _vm.webConfSingle,
                              index: _vm.index,
                              formIndex: _vm.formIndex,
                            }),
                          },
                          model: {
                            value: _vm.value,
                            callback: function ($$v) {
                              _vm.value =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "value",
                          },
                        },
                        "component",
                        _vm.propsMessage,
                        false
                      )
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "tip",
            attrs: { title: _vm.items.tip },
            domProps: { innerHTML: _vm._s(_vm.items.tip) },
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }