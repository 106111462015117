var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "DownComponent",
        {
          attrs: {
            "search-foldnum": _vm.searchFoldnum,
            "default-spread": _vm.defaultSpread,
            "set-height": _vm.setHeight,
            "row-all": _vm.rowAll,
          },
          on: { "on-toggle": _vm.toggle },
        },
        [
          _c("FormLayout", {
            ref: "FormLayout",
            staticClass: "formLayout",
            attrs: {
              slot: "dwonContent",
              "default-column": _vm.defaultColumn,
              defaultconfig: _vm.defaultconfig,
            },
            slot: "dwonContent",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }