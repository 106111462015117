<template>
  <Drawer class="r3-drawer-iframe" id="r3-drawer-iframe" v-bind="props" v-model="value">
    <component :is="props.components"></component>
    <iframe ref="DrawerIframe" id="DrawerIframe" data-config="btnBack=false"
     v-if="url" :src="url" style="height:100%;width:100%;border:none"></iframe>
  </Drawer>
</template>
<script>
export default {
  data() {
    return {
      props: {
        title: "",
        width: "800",
      },
      url: "",
      component:'',
      closable: false,
      value: false,
    };
  },
  destroyed(){
    this.url = '';
  },
  watch:{
    value(){
      if(!this.value){
        this.url = '';//测试哈哈

      }
    }

  },
  methods:{
   
  },
  mounted() {
    // 如果开启系统升级，且需要更新
    if (/iframe=true/.test(window.location.search)) {
      // 路径含有iframe 则不显示导航区
      window.ProjectConfig.displayAreas = "content";
      this.value = false;
    }
    let self = this;
  
    window.addEventListener("R3setIFrame", function(data) {
      self.value = data.detail.show;
      self.props = Object.assign(self.props, data.detail.props);
      console.log(self.props);
      self.url = data.detail.url;
      if (self.url.indexOf("?") === -1) {
        self.url = self.url + "?iframe=true&btnBack=false";
      } else {
        self.url = self.url + "&iframe=true&btnBack=false";
      }
      if(self.url){
        self.$el.__vue__ = self; 
      }
      if (data.detail && typeof data.detail.cb === "function") {
        data.detail.cb(self);
      }
    });
  },
};
</script>
<style lang="less">
.r3-drawer-iframe {
  .ark-drawer-header {
    border-radius: 0px;
    background-color: #fff;
    .ark-drawer-header-inner{
      color: #575757!important;
      text-align: left;
      padding-left: 16px;
      font-size: 14px;
      font-weight: normal!important;;

    }
    
  }
  .iconios-close{
      font-size: 22px!important;
      color: #000;
      margin-top: 10px;
    }
}
</style>
