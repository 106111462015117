<template>
  <!-- 报表模版，report -->
  <div :class="classes">
    <div class="pop-title">
      <div class="pop-input">
        <ul>
          <li class="resTop">
            三方元数据版本号为{{env}}，是否下载？    
          </li>
        </ul>
      </div>
      <div class="pop-btn">
       
        <button
          class="cancel-btn"
          @click="cancel"
        >
          <span>{{ chineseName.CANCEL }}</span>
        </button>
        <button
        class="sav-btn"
        @click="save"
      >
        <span>{{ chineseName.CONFIRM }}</span>
      </button>
      </div>
    </div>
  </div>
</template>

<script>
  import ChineseDictionary from '../../assets/js/ChineseDictionary';
  import network, { urlSearchParams } from '../../__utils__/network';
  // import window.vm.$router.from '../../__config__/router.config';
  import { classFix } from '../../constants/global';

  export default {
    name: 'Report',
    props: {
      objList: {
        type: Array,
        default: () => []
      },
      idArray: {// 获取ID用于多选
        type: [Array, Object],
        default: () => {}
      },
    },
    data() {
      return {
        env: '',
        envValue: ''
      };
    },
    components: {},
    computed: {
      classes() {
        return [
          `${classFix}publishContent`,
        ];
      },
    },
    methods: {
      getEnvs() {
        network
          .post('/p/cs/sync/version')
          .then((res) => {
            if (res.data.code === 0) {
              this.env = res.data.data;
            }
          });
      },
      save() {
        const { itemId } = window.vm.$router.currentRoute.params;
        this.publish(itemId)
        
      }, // 确定
      publish(itemId) {
        let self = this;

        this.$R3loading.show(this.loadingName);
        network.post('/p/cs/sync/sync', {"AD_VERSION":{"ID":itemId}})
          .then((res) => {
            self.$R3loading.hide(this.loadingName);

            if (res.data.code !== 0) {
              return;
            }
            this.cancel();
            self.downloadUrlFile(res.data.data)
          }).catch(() => {
            self.$R3loading.hide(this.loadingName);
          });
      },
      downloadUrlFile(url) {
        const self = this;
        const domFrame = window.parent.document.getElementById('downLoadListFrame');
        if (domFrame != null) {
          window.parent.document.body.removeChild(domFrame);
        }
        const downloadFile = {};
        if (typeof downloadFile.iframe === 'undefined') {
          const iframe = document.createElement('iframe');
          iframe.setAttribute('id', 'downLoadListFrame');
          iframe.style.display = 'none';
          self.addEvent('load', iframe, () => { self.iframeLoad(iframe); });
          iframe.src = url;
          downloadFile.iframe = iframe;
          document.body.appendChild(downloadFile.iframe);
         
        }
      },
      // 判断iframe的src
      iframeLoad(iframe) {
        const src = (iframe.src) ? iframe.src : iframe.contentWindow.locatiion.href;
         setTimeout(() => {
            iframe.src = '';
          }, 1000);
 
      },
      cancel() {
        this.$emit('closeActionDialog', false); // 关闭弹框
      }, // 取消
    },
    created() {
      this.loadingName = this.$route.meta.moduleName.replace(/\./g, '-');
      this.chineseName = ChineseDictionary;
    },
    mounted() {
      // this.$emit('setTitle', '环境'); // 关闭弹框

      this.getEnvs();
    },

  };
</script>
