var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "buttonGroup" },
        _vm._l(_vm.buttonsData, function (item, index) {
          return _c(
            "Button",
            {
              key: index,
              staticClass: "Button",
              attrs: {
                type: "fcdefault",
                loading:
                  item.webname === "CmenuPermissionSaveCmd" && _vm.saveLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.btnClick(item)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(item.webdesc) + "\n    ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "contentLeft" },
          [
            false
              ? _c(
                  "Input",
                  {
                    attrs: {
                      placeholder: _vm.$t("messages.enterUserName"),
                      clearable: "",
                      icon: "ios-search",
                    },
                    on: { "on-change": _vm.searchInputChange },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "prepend" }, slot: "prepend" },
                      [_vm._v(_vm._s(_vm.$t("buttons.find")))]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "menuContainer" },
              [
                _c("tree", {
                  ref: "ztree",
                  attrs: { "tree-datas": _vm.treeConfigData },
                  on: {
                    menuTreeChange: _vm.menuTreeChange,
                    treeSearch: _vm.treeSearch,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "contentRight" }, [
          _c(
            "div",
            { staticClass: "left-tree" },
            [
              _c("Tree", {
                ref: "tree",
                attrs: { data: _vm.treeData },
                on: { "on-select-change": _vm.treeChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "right-list" }, [
            _c("div", { staticClass: "upper-part" }, [
              _c("div", { ref: "upperTable", staticClass: "upper-table" }, [
                _c(
                  "div",
                  {
                    staticClass: "upper-table-tabth",
                    style: { left: _vm.upperTableTabthLeft },
                  },
                  [
                    _c("table", [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _c(
                              "th",
                              {
                                ref: "functionColumnTh",
                                staticClass: "functionColumnClass",
                                style: {
                                  "min-width": _vm.functionColumnWidth + "px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.$t("tips.function")) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.columns, function (item, index) {
                              return _c(
                                "th",
                                {
                                  key: index,
                                  ref: "tableTabth" + index,
                                  refInFor: true,
                                  style: { width: _vm.theadThMinWidth },
                                },
                                [
                                  _c("Checkbox", {
                                    on: {
                                      "on-change": function (currentValue) {
                                        return _vm.tabthCheckboxChange(
                                          currentValue,
                                          { index: index, column: item }
                                        )
                                      },
                                    },
                                    model: {
                                      value: item[item.key + "Value"],
                                      callback: function ($$v) {
                                        _vm.$set(item, item.key + "Value", $$v)
                                      },
                                      expression: "item[`${item.key}Value`]",
                                    },
                                  }),
                                  _vm._v(
                                    _vm._s(item.title) +
                                      "\n                    "
                                  ),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tableData.length === 0,
                        expression: "tableData.length === 0",
                      },
                    ],
                    staticClass: "upper-table-tabtd-empty",
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tips.noData")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tableData.length > 0,
                        expression: "tableData.length > 0",
                      },
                    ],
                    ref: "upperTableTabtd",
                    staticClass: "upper-table-tabtd",
                    on: { scroll: _vm.upperTableTbodyScroll },
                  },
                  [
                    _c("table", [
                      _c(
                        "tbody",
                        _vm._l(_vm.tableData, function (item, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              class:
                                _vm.upperTableTbodyHighlightIndex === index
                                  ? "upper-table-tabtd-highlight"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.upperTableTbodyClick(item, index)
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  ref: "functionColumnTd",
                                  refInFor: true,
                                  style: {
                                    "min-width": _vm.functionColumnTdWidth,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(item.description) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.columns, function (tem, temIdx) {
                                return _c(
                                  "td",
                                  {
                                    key: temIdx,
                                    style: { "min-width": tem.tbodyWidth },
                                  },
                                  [
                                    _c("Checkbox", {
                                      attrs: {
                                        disabled: item[tem.key + "Disabled"],
                                      },
                                      on: {
                                        "on-change": function (currentValue) {
                                          return _vm.rowCheckboxChange(
                                            currentValue,
                                            {
                                              row: item,
                                              index: index,
                                              column: tem,
                                            }
                                          )
                                        },
                                      },
                                      model: {
                                        value: item[tem.key + "Value"],
                                        callback: function ($$v) {
                                          _vm.$set(item, tem.key + "Value", $$v)
                                        },
                                        expression: "item[`${tem.key}Value`]",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bottom-part" }, [
              _c("div", { staticClass: "bottom-table" }, [
                _c("div", { staticClass: "bottom-table-tabth" }, [
                  _c("table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("messages.extensions"))),
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(_vm.$t("tips.function")))]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.extendTableData.length === 0,
                        expression: "extendTableData.length === 0",
                      },
                    ],
                    staticClass: "bottom-table-tbody-empty",
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tips.noData")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.extendTableData.length > 0,
                        expression: "extendTableData.length > 0",
                      },
                    ],
                    staticClass: "bottom-table-tbody",
                  },
                  [
                    _c("table", [
                      _c(
                        "tbody",
                        _vm._l(_vm.extendTableData, function (item, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              class:
                                _vm.bottomTableTbodyHighlightIndex === index
                                  ? "bottom-table-tbody-highlight"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.bottomTableTbodyClick(index)
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                { staticStyle: { width: "200px" } },
                                [
                                  _c("Checkbox", {
                                    attrs: {
                                      value:
                                        item.permission === 128 ? true : false,
                                    },
                                    on: {
                                      "on-change": function (currentValue) {
                                        return _vm.extendFunctionCheckboxChange(
                                          currentValue,
                                          { row: item, index: index }
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    _vm._s(item.description) +
                                      "\n                    "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _vm._l(
                                    item.children,
                                    function (checkItem, j) {
                                      return [
                                        _c("Checkbox", {
                                          key: j,
                                          attrs: {
                                            value: checkItem.permission === 128,
                                          },
                                          on: {
                                            "on-change": function (
                                              currentValue
                                            ) {
                                              return _vm.functionCheckboxChange(
                                                currentValue,
                                                {
                                                  row: item,
                                                  index: index,
                                                  itemIndex: j,
                                                }
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            checkItem.description
                                              ? checkItem.description
                                              : ""
                                          ) + "\n                        "
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: {
            closable: "",
            width: 420,
            mask: "",
            "footer-hide": "",
            title: _vm.$t("tips.copyPermission"),
          },
          model: {
            value: _vm.copyPermission,
            callback: function ($$v) {
              _vm.copyPermission = $$v
            },
            expression: "copyPermission",
          },
        },
        [
          _c("div", { staticClass: "modalContent" }, [
            _c(
              "div",
              { staticClass: "itemContent" },
              [
                _c("div", { staticClass: "labelContent" }, [
                  _c("div", { staticClass: "labelTip" }, [
                    _vm._v("\n            *\n          "),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("messages.originalRole")) + ":"),
                  ]),
                ]),
                _vm._v(" "),
                _c("DropDownSelectFilter", {
                  staticClass: "itemCom",
                  attrs: {
                    "total-row-count": _vm.totalRowCount,
                    "page-size": _vm.dropPageSize,
                    "auto-data": _vm.singleAutoData,
                    "columns-key": ["NAME"],
                    hidecolumns: ["ID"],
                    "default-selected": _vm.singleDefaultSelected,
                    data: _vm.singleDropDownSelectFilterData,
                  },
                  on: {
                    "on-fkrp-selected": _vm.singleDropSelected,
                    "on-page-change": _vm.singleDropPageChange,
                    "on-popper-hide": _vm.singlePopperHide,
                    "on-clear": _vm.singleDropClear,
                    "on-input-value-change": _vm.singleInputChange,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "itemContent" },
              [
                _c("div", { staticClass: "labelContent" }, [
                  _c("div", { staticClass: "labelTip" }, [
                    _vm._v("\n            *\n          "),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("messages.targetRole")) + ":"),
                  ]),
                ]),
                _vm._v(" "),
                _c("DropDownSelectFilter", {
                  staticClass: "itemCom",
                  attrs: {
                    single: false,
                    "total-row-count": _vm.totalRowCount,
                    "page-size": _vm.dropPageSize,
                    "columns-key": ["NAME"],
                    hidecolumns: ["ID"],
                    "default-selected": _vm.multipleDefaultSelected,
                    "auto-data": _vm.multipleAutoData,
                    data: _vm.multipleDropDownSelectFilterData,
                  },
                  on: {
                    "on-fkrp-selected": _vm.multipleDropSelected,
                    "on-page-change": _vm.multipleDropPageChange,
                    "on-popper-hide": _vm.multiplePopperHide,
                    "on-clear": _vm.multipleDropClear,
                    "on-input-value-change": _vm.multipleInputChange,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "itemContent" },
              [
                _c("div", { staticClass: "labelContent" }, [
                  _c("div", { staticClass: "labelTip" }, [
                    _vm._v("\n            *\n          "),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("messages.copyMethod")) + ":"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "Select",
                  {
                    staticClass: "itemCom",
                    attrs: { placeholder: _vm.$t("messages.selectCopyMethod") },
                    model: {
                      value: _vm.copyType,
                      callback: function ($$v) {
                        _vm.copyType = $$v
                      },
                      expression: "copyType",
                    },
                  },
                  [
                    _c("Option", { attrs: { value: "cover" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("messages.overwritePermission")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("Option", { attrs: { value: "copy" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("messages.keepPermission")) +
                          "\n          "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modalButton" },
              [
                _c(
                  "Button",
                  {
                    staticClass: "Button",
                    attrs: { type: "fcdefault" },
                    on: { click: _vm.modalConfirm },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("buttons.confirm")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "Button",
                  {
                    staticClass: "Button",
                    attrs: { type: "fcdefault" },
                    on: { click: _vm.modalCancel },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("buttons.cancel")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.spinShow,
              expression: "spinShow",
            },
          ],
          attrs: { fix: "" },
        },
        [
          _c("Icon", {
            staticClass: "demo-spin-icon-load",
            attrs: { type: "ios-loading", size: "48" },
          }),
          _vm._v(" "),
          _c("div", [_vm._v("Loading")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }