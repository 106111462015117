var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "btn-area" },
        [
          _c(
            "Button",
            {
              attrs: { type: "posdefault" },
              on: {
                click: function ($event) {
                  return _vm.btnclick("search")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.search")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "Button",
            {
              attrs: { type: "fcdefault", p: "" },
              on: {
                click: function ($event) {
                  return _vm.btnclick("reset")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.reset")) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("FormItemComponent", {
        ref: "FormItemComponent",
        attrs: {
          "form-items-data": _vm.formItems,
          "form-item-lists": _vm.formItemsLists,
          "default-column": 4,
          "default-spread": false,
          "search-foldnum": 7,
        },
        on: { formDataChange: _vm.formDataChange },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-area" },
        [
          _c("Page", {
            ref: "page",
            attrs: {
              size: "small",
              "show-total": "",
              "show-sizer": "",
              "show-elevator": "",
              transfer: "",
              current: _vm.page.current,
              "page-size": _vm.page.pageSize,
              "page-size-opts": _vm.page.pageSizeOpts,
              total: _vm.page.total,
            },
            on: {
              "on-change": _vm.pageChange,
              "on-page-size-change": _vm.pageSizeChange,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-wrap" },
            [
              _c("commonTable", {
                ref: "commonTable",
                attrs: {
                  datas: _vm.datas,
                  "css-status": _vm.cssStatus,
                  "error-arr": _vm.errorArr,
                  "on-row-double-click": _vm.onRowDoubleClick,
                  "on-sort-changed": _vm.onSortChanged,
                  "on-selection-changed": _vm.onSelectionChanged,
                  "on-single-cell-click": _vm.onSingleCellClick,
                  "on-row-single-click": _vm.onRowSingleClick,
                },
                on: { CustomizedDialog: _vm.customizedDialog },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }