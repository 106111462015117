var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("viewer", {
    ref: "viewer",
    class: _vm.viewerCls,
    attrs: { options: _vm.options, images: _vm.images },
    on: { inited: _vm.inited },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (scope) {
          return [
            _c(
              "figure",
              { staticClass: "images" },
              _vm._l(scope.images, function (ref, index) {
                var NAME = ref.NAME
                var URL = ref.URL
                return _c("div", { key: index, staticClass: "image-wrapper" }, [
                  _c("img", {
                    staticClass: "image",
                    attrs: { src: URL, "data-source": URL },
                  }),
                ])
              }),
              0
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }