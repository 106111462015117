var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("div", { staticClass: "pop-title" }, [
      _c("div", { staticClass: "pop-input" }, [
        _c("ul", [
          _c("li", { staticClass: "resTop" }, [
            _vm._v(
              "\n          三方元数据版本号为" +
                _vm._s(_vm.env) +
                "，是否下载？    \n        "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pop-btn" }, [
        _c("button", { staticClass: "cancel-btn", on: { click: _vm.cancel } }, [
          _c("span", [_vm._v(_vm._s(_vm.chineseName.CANCEL))]),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "sav-btn", on: { click: _vm.save } }, [
          _c("span", [_vm._v(_vm._s(_vm.chineseName.CONFIRM))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }