var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm.type === "vertical" &&
      _vm.itemInfo.tabrelation === "1:1" &&
      _vm.watermarkimg
        ? _c(
            "div",
            { ref: "watermark", staticClass: "submit-img" },
            [
              _c("WaterMark", {
                attrs: {
                  text: _vm.waterMarkText,
                  color: _vm.waterMarkColor,
                  top: _vm.waterMarkTop,
                  left: _vm.waterMarkLeft,
                  width: _vm.waterMarkWidth,
                },
                on: { "hook:mounted": _vm.getTransferDom },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.buttonsData.isShow && _vm.componentType !== "ALL"
        ? _c(_vm.objectButtonComponent, {
            tag: "component",
            staticClass: "objectButtons",
            attrs: {
              "object-type": _vm.type,
              "item-info": _vm.itemInfo,
              "item-name-group": _vm.childTableNames,
              "item-name": _vm.tableName,
              tabcmd: _vm.buttonsData.data.tabcmd,
              "disable-export": _vm.buttonsData.data.DisableEXPORT,
              "item-table-check-func": _vm.itemTableCheckFunc,
              tabwebact: _vm.buttonsData.data.tabwebact,
              isactive: _vm.isactive,
              watermarkimg: _vm.resetWaterMark,
              "clear-item-table-search-value": _vm.clearItemTableSearchValue,
              isreftabs: _vm.isreftabs,
              "is-main-form": _vm.tabPanelsAll,
              "is-item-table-vertical":
                _vm.buttonsData.data.isItemTableVertical,
              "back-button": _vm.buttonsData.data.backButton,
              "is-item-table": _vm.isItemTable,
              "web-conf-single": _vm.webConfSingle,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.formData.isShow && _vm.itemInfo.tabrelation !== "1:1"
        ? _c("childrenForm", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.status === 1 && !_vm.objreadonly,
                expression: "status === 1 && !objreadonly",
              },
            ],
            attrs: {
              tableName: _vm.tableName,
              objreadonly: _vm.objreadonly,
              "default-data": _vm.formData.data,
              readonly: _vm.formReadonly,
              "master-name": _vm.currentPageRoute.tableName,
              "master-id": _vm.currentPageRoute.itemId,
              "module-form-type": _vm.type,
              "is-main-table": _vm.isMainTable,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.componentName
        ? _c(
            "div",
            { staticStyle: { "overflow-y": "auto", flex: "1" } },
            [
              _vm.componentName
                ? _c(_vm.customizeComponent, {
                    tag: "component",
                    attrs: { "item-info": _vm.itemInfo },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.panelData.isShow
                ? _c("compositeForm", {
                    class:
                      _vm.type === "vertical"
                        ? "verticalFormPanel"
                        : "formPanel",
                    attrs: {
                      "is-main-table": _vm.isMainTable,
                      "object-type": _vm.type,
                      objreadonly: _vm.objreadonly,
                      readonly: _vm.formReadonly,
                      "default-set-value": _vm.changeData,
                      "master-name": _vm.currentPageRoute.tableName,
                      "master-id": _vm.currentPageRoute.itemId,
                      "module-form-type": _vm.type,
                      type: "PanelForm",
                      "default-data": _vm.panelData.data,
                      paths: _vm.formPaths,
                      isreftabs: _vm.isreftabs,
                      "child-table-name": _vm.tableName,
                    },
                    on: {
                      formChange: _vm.formPanelChange,
                      InitializationForm: _vm.initFormPanel,
                      VerifyMessage: _vm.verifyFormPanel,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.panelData.isShow && !_vm.componentName
        ? _c("panelForm", {
            attrs: {
              tableName: _vm.tableName,
              readonly: _vm.objreadonly,
              "is-main-table": _vm.isMainTable,
              defaultData: _vm.panelData.data,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tableData.isShow
        ? _c(_vm.objectTableComponent, {
            ref: "objectTableRef",
            tag: "component",
            staticClass: "objectTable",
            attrs: {
              "table-height": _vm.type === "vertical" ? 356 : 0,
              "table-name": _vm.tableName,
              "data-source": _vm.tableData.data,
              type: _vm.type,
              "item-info": _vm.itemInfo,
              readonly: _vm.buttonsData.data.objreadonly,
              "table-readonly": !_vm.getActionModify,
              objreadonly: _vm.objreadonly,
              "web-conf-single": _vm.webConfSingle,
              status: _vm.status,
              tabwebact: _vm.buttonsData.data.tabwebact,
              "tooltip-for-item-table": _vm.tooltipForItemTable,
              isCommonTable: _vm.isCommonTable,
            },
            on: {
              tableBeforeData: _vm.tableBeforeData,
              tableBeforeLabelData: _vm.tableBeforeLabelData,
              tableDataChangeLabel: _vm.tableDataChangeLabel,
              tableDataChangeLabelBefore: _vm.tableDataChangeLabelBefore,
              tableDataChange: _vm.tableDataChange,
              tableSelectedRow: _vm.tableSelectedRow,
              tableVerifyMessage: _vm.tableVerifyMessage,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "detail-buttons-a",
                  fn: function (slotProps) {
                    return _vm.slotArray.detailbuttonsa
                      ? _c(
                          "div",
                          {},
                          [
                            _c(_vm.slotArray.detailbuttonsa, {
                              tag: "component",
                              attrs: { slotProps: slotProps },
                            }),
                          ],
                          1
                        )
                      : _vm._e()
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }