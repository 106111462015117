var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "PanelForm"
        ? _vm._l(_vm.computdefaultData, function (item, index) {
            return _c(
              "Collapse",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkPanelShow(item),
                    expression: "checkPanelShow(item)",
                  },
                ],
                key: index,
                attrs: { "active-key": "index" },
                on: {
                  "on-change": function ($event) {
                    return _vm.CollapseClose(index, item.hrdisplay)
                  },
                },
                model: {
                  value: item.showHrdisplay,
                  callback: function ($$v) {
                    _vm.$set(item, "showHrdisplay", $$v)
                  },
                  expression: "item.showHrdisplay ",
                },
              },
              [
                _c(
                  "Panel",
                  {
                    key: index,
                    staticClass: "Rark-collapse-content-box",
                    attrs: {
                      "is-title-show": item.isTitleShow,
                      "title-type": "center",
                      name: item.hrdisplay === "expand" ? "expand" : "false",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(item.parentdesc) + "\n        "
                    ),
                    _c(
                      "div",
                      { attrs: { slot: "content" }, slot: "content" },
                      [
                        _vm.FormItemComponent !== ""
                          ? [
                              _c(_vm.FormItemComponent, {
                                key: index,
                                ref: "FormComponent_" + index,
                                refInFor: true,
                                tag: "component",
                                class:
                                  _vm.tableGetName === ""
                                    ? "R3masterForm"
                                    : _vm.tableGetName,
                                attrs: {
                                  path: _vm.path,
                                  "is-copy": _vm.isCopy,
                                  "form-index": index,
                                  "form-item-lists": item.childs,
                                  isreftabs: _vm.isreftabsForm,
                                  "set-objreadonly": _vm.setObjreadonly,
                                  "child-table-name": _vm.childTableName,
                                  "is-child-table": _vm.isChildTable,
                                  "refcolval-data": _vm.refcolvaData,
                                  "mapp-status": _vm.setMapping,
                                  "web-conf-single": _vm.webConfSingle,
                                  "is-main-table": _vm.isMainTableForm,
                                  "partent-vue": _vm.partentVue,
                                  condition: _vm.conditiontype,
                                  "module-form-type": _vm.moduleFormType,
                                  "get-state-data": _vm.getStateData,
                                  "getset-attset-props": _vm.getsetAttsetProps,
                                  verifymessageform: _vm.VerifyMessageForm,
                                  "mountdata-form": _vm.mountdataForm,
                                  "mounted-type": _vm.mountNumber,
                                  type: _vm.type,
                                  "default-column": _vm.defaultColumnCol,
                                },
                                on: { formDataChange: _vm.formDataChange },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ],
              1
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type !== "PanelForm"
        ? [
            _vm.FormItemComponent !== ""
              ? [
                  _c(_vm.FormItemComponent, {
                    ref: "FormComponent_0",
                    tag: "component",
                    class: _vm.tableGetName,
                    attrs: {
                      path: _vm.path,
                      isreftabs: _vm.isreftabsForm,
                      "form-index": 0,
                      "is-copy": _vm.isCopy,
                      "refcolval-data": _vm.refcolvaData,
                      "child-table-name": _vm.childTableNameForm,
                      "is-child-table": _vm.isChildTable,
                      verifymessageform: _vm.VerifyMessageForm,
                      "set-objreadonly": _vm.setObjreadonly,
                      "web-conf-single": _vm.webConfSingle,
                      "mapp-status": _vm.setMapping,
                      "partent-vue": _vm.partentVue,
                      "module-form-type": _vm.moduleFormType,
                      "default-column": _vm.defaultColumnCol,
                      condition: _vm.conditiontype,
                      "mounted-type": _vm.mountNumber,
                      "getset-attset-props": _vm.getsetAttsetProps,
                      "is-main-table": _vm.isMainTableForm,
                      "get-state-data": _vm.getStateData,
                      "mountdata-form": _vm.mountdataForm,
                      "form-item-lists": _vm.computdefaultData,
                    },
                    on: { formDataChange: _vm.formDataChange },
                  }),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }