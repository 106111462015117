var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "r3-skq-notice-queue" } }, [
    _c("div", { staticClass: "r3-skq-notice-queue-head" }, [
      _c(
        "div",
        { staticClass: "r3-skq-notice-head-l" },
        [
          _vm.dialogType === "list"
            ? _c(
                "Poptip",
                {
                  attrs: {
                    trigger: "hover",
                    transfer: "",
                    placement: "bottom",
                    "popper-class": "r3-skq-notice-pop",
                  },
                  on: { "on-popper-show": _vm.handleShowPop },
                  model: {
                    value: _vm.showFilter,
                    callback: function ($$v) {
                      _vm.showFilter = $$v
                    },
                    expression: "showFilter",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "r3-skq-task-state" },
                    [
                      _c("span", { staticClass: "r3-skq-filter-panel" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _vm._v(" "),
                      _c("Icon", {
                        attrs: { type: "ios-arrow-down", size: "18" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "skq-pop",
                      attrs: { slot: "content" },
                      slot: "content",
                    },
                    _vm._l(_vm.filterItem, function (state, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "state-item",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.filterTask(i)
                            },
                          },
                        },
                        [_vm._v(_vm._s(state))]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _c("div", [
                _c("span", { staticClass: "r3-skq-filter-panel" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "r3-skq-notice-head-r" },
        [
          _c(
            "span",
            {
              staticClass: "r3-skq-view-all",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.viewAllTasks($event)
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("buttons.view")) + _vm._s(_vm.$t("tips.all"))
              ),
            ]
          ),
          _vm._v(" "),
          _c("Icon", {
            attrs: { type: "ios-close", size: "22" },
            on: { click: _vm.close },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "r3-skq-notice-queue-body" },
      [
        _c("Spin", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          attrs: { fix: "", size: "large" },
        }),
        _vm._v(" "),
        _c(
          "transition-group",
          { attrs: { name: "r3-slide-fade", duration: _vm.removeDuration } },
          _vm._l(_vm.tasks, function (item) {
            return _c("div", { key: item.id, staticClass: "r3-task-item" }, [
              _c(
                "div",
                { staticClass: "r3-skq-notice-body-l" },
                [
                  item.statusCode === 1
                    ? _c(
                        "svg",
                        {
                          staticClass: "notice-ongoing",
                          attrs: { viewBox: "25 25 50 50" },
                        },
                        [
                          _c("circle", {
                            staticClass: "path",
                            attrs: {
                              cx: "50",
                              cy: "50",
                              r: "20",
                              fill: "none",
                              "stroke-width": "5",
                              "stroke-miterlimit": "10",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.statusCode === 2
                    ? _c("Icon", {
                        staticClass: "notice-success",
                        attrs: {
                          type: "ios-checkmark-circle-outline",
                          size: "16",
                        },
                      })
                    : item.statusCode === 3
                    ? _c("Icon", {
                        staticClass: "notice-error",
                        attrs: { type: "ios-close-circle-outline", size: "16" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "r3-skq-notice-body-m" }, [
                _c("div", { staticClass: "r3-task-content" }, [
                  _c(
                    "span",
                    {
                      staticClass: "one-line-flow task-title",
                      attrs: { title: item.content },
                    },
                    [_vm._v(_vm._s(item.content))]
                  ),
                  _vm._v(" "),
                  item.url && item.statusCode === 2
                    ? _c(
                        "a",
                        {
                          style: item.isRead
                            ? "color:#7f1bab"
                            : "color:#0F8EE9",
                          attrs: { href: item.url, download: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.downloadTask(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "【" +
                              _vm._s(_vm.$t("messages.downloadFile")) +
                              "】"
                          ),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          style: item.isRead
                            ? "color:#7f1bab"
                            : "color:#0F8EE9",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.viewTask(item)
                            },
                          },
                        },
                        [_vm._v("【" + _vm._s(_vm.$t("tips.details")) + "】")]
                      ),
                ]),
                _vm._v(" "),
                _vm.dialogType === "list"
                  ? _c("div", { staticClass: "r3-task-subtitle" }, [
                      _c("span", [_vm._v(_vm._s(item.beginTime))]),
                    ])
                  : _vm._e(),
              ]),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.dialogType === "list" && _vm.tasks.length < _vm.totalTasks
          ? _c(
              "div",
              {
                staticClass: "bottom-tip",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.viewAllTasks($event)
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("buttons.view")) +
                    _vm._s(_vm.$t("tips.all")) +
                    _vm._s(_vm.$t("tips.task"))
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }