var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.searchFoldnum === 0
      ? _c(
          "div",
          { class: _vm.classes, style: _vm.setWidth },
          _vm._l(_vm.dataColRol, function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.show !== false,
                    expression: "item.show !== false",
                  },
                ],
                key: index,
                staticClass: "FormItemComponent-item",
                style: _vm.setDiv(item),
                attrs: { id: item.item.field },
              },
              [
                _c(item.component, {
                  ref: "component_" + index,
                  refInFor: true,
                  tag: "component",
                  class: item.item.field,
                  attrs: {
                    "is-child-table": _vm.isChildTable,
                    index: index,
                    type: _vm.type,
                    "web-conf-single": _vm.webConfSingle,
                    items: item.item,
                  },
                  on: { inputChange: _vm.inputChange },
                }),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }