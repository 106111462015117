var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "R3-add-acount" }, [
    _c("div", { staticClass: "add-account-wrap" }, [
      _c(
        "div",
        { staticClass: "add-account-l" },
        [
          _c("Input", {
            staticClass: "input-name",
            attrs: {
              maxlength: 20,
              placeholder: _vm.$t("messages.enterAccountName"),
            },
            on: { "on-focus": _vm.handlerFocus },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "add-account-r" },
        [
          _c(
            "Button",
            { attrs: { type: "fcdefault" }, on: { click: _vm.cancel } },
            [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "Button",
            { attrs: { type: "posdefault" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("buttons.save")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }