<template>
  <div class="notfonud">
    <h1>没有找到当前组件，请检查配置</h1>
  </div>
</template>

<script>


  export default {
    data() {
      return {
      };
    },
    mounted() {
    }
  };
</script>
