var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.classes,
      on: {
        click: function ($event) {
          return _vm.toggleSubMenu()
        },
      },
    },
    _vm._l(_vm.data, function (subMenu, index) {
      return subMenu.children.length > 0 &&
        subMenu.children.filter(function (c) {
          return !c.isHidden
        }).length > 0
        ? _c(
            "ul",
            { key: "subMenu-" + index, staticClass: "menu-group" },
            [
              subMenu.children.length > 0 &&
              subMenu.children.filter(function (c) {
                return !c.isHidden
              }).length > 0
                ? _c("li", {
                    staticClass: "title",
                    domProps: {
                      innerHTML: _vm._s(
                        subMenu.label.replace(/&nbsp\;/g, "\xa0")
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(subMenu.children, function (endMenu, endIndex) {
                return !endMenu.isHidden
                  ? _c("li", {
                      key: "endMenu-" + endIndex,
                      staticClass: "item",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.renderLabel(
                            endMenu.label,
                            _vm.navConfig[endMenu.value]
                          )
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.routeTo(endMenu)
                        },
                      },
                    })
                  : _vm._e()
              }),
            ],
            2
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }