<template>
  <div
    ref="downComponent"
    :class="DCTCls"
  >
    <div v-if="rowAll > searchFoldnum" :class="tagCloseCls">
      <Icon
        :class="className"
        @click="toggle"
      />
    </div>
    <div
      class="downComponent"
      :style="downComponent"
    >
      <div
        v-if="title"
        class="downComponent-h5"
        @click="toggle"
      >
        {{ title }}<Icon :class="icon" />
      </div>
      <div :class="downContent">
        <slot name="dwonContent" />
      </div>
    </div>
  </div>
</template>

<script>
  import { classFix } from '../constants/global';

  export default {
    name: 'DownComponent',
    props: {
      index: {// 暂无用到
        type: Number,
        default() {
          return 0;
        }
      },
      setHeight: {// 每行表单组件高度
        type: Number,
        default() {
          return 0;
        }
      },
      rowAll: {// 所有行数
        type: [Number, String],
        default() {
          return 0;
        }
      },
      searchFoldnum: {// 默认显示几行
        type: [Number, String],
        default() {
          return 0;
        }
      },
      defaultSpread: { // 是否全部展开
        type: Boolean,
        default() {
          return true;
        }
      },
      title: {
        type: String,
        default() {
          return '';
        }
      }
    },
    data() {
      return {
        dowClass: false,
        spread: true
      };
    },
    watch: {
      defaultSpread(val) {
        // 查看是否展开
        this.spread = val;
        if (this.spread === false) {
          this.dowClass = true;
        } else {
          this.dowClass = false;
        }
      }
    },
    computed: {
      className() {
        return `${this.dowClass === false ? ' iconfont  iconios-arrow-down' : 'iconfont  iconios-arrow-down icon-xiadown'}`;
      },
      downComponent() {
        if (this.spread) {
          return `${this.dowClass === false ? `max-height: ${this.searchFoldnum * this.setHeight + 8}px; transition:max-height 0.3s;` : 'max-height:30000px;transition:max-height 0.3s;'}`;
        }

        if (this.dowClass === true) {
          return 'max-height:30000px;transition:max-height 0.3s;';
        }
        return `max-height: ${this.searchFoldnum * this.setHeight + 8}px; `;
      },
      icon() {
        return `${this.dowClass === false ? 'iconfont icon-triangle-copy-copy-copy1 ' : 'iconfont icon-triangle-copy-copy-copy1 icon-xiadown'}`;
      },
      downContent() {
        return `${this.title ? 'down-contain ' : 'down-contain down-right'}`;
      },
      tagCloseCls: () => `${classFix}TagClose`,
      DCTCls: () => `${classFix}downComponent-context`,
    },
    methods: {
      toggle() {
        this.dowClass = !this.dowClass;
        // 调整ag表格高度
        this.$emit('on-toggle', this.dowClass);

        setTimeout(() => {
          const detailTable = document.querySelector('.detailTable');
          if (detailTable && detailTable.agTable) {
            detailTable.agTable.fixContainerHeight();
          }
        }, 300);
      }
    },
    mounted() {
      if (this.defaultSpread === false) {
        this.spread = this.defaultSpread;
      }
    }

  };
</script>
