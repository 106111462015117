var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.classes,
      style: { "margin-left": _vm.isItemTableVertical ? "17px" : "0px" },
    },
    [
      _vm.watermarkImg
        ? _c(
            "div",
            { ref: "watermark", staticClass: "submit-img" },
            [
              _c("WaterMark", {
                attrs: {
                  text: _vm.waterMarkText,
                  color: _vm.waterMarkColor,
                  top: _vm.waterMarkTop,
                  left: _vm.waterMarkLeft,
                  width: _vm.waterMarkWidth,
                },
                on: { "hook:mounted": _vm.getTransferDom },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ButtonGroup", {
        staticClass: "buttonGroup",
        attrs: {
          "data-array": _vm.dataArray,
          "item-id": _vm.itemId,
          "id-array":
            _vm.itemName && _vm.updateData ? _vm.updateData[_vm.itemName] : {},
        },
        on: { buttonClick: _vm.buttonClick },
      }),
      _vm._v(" "),
      _c("Dialog", {
        ref: "dialogRef",
        attrs: {
          title: _vm.dialogConfig.title,
          mask: _vm.dialogConfig.mask,
          "content-text": _vm.dialogConfig.contentText,
          "footer-hide": _vm.dialogConfig.footerHide,
          confirm: _vm.dialogConfig.confirm,
          "action-id": _vm.actionId,
          "obj-tab-action-dialog-config": _vm.objTabActionDialogConfig,
          "dialog-component-name": _vm.dialogComponentName,
          "obj-list": _vm.dialogComponentName ? _vm.objList : [],
        },
        on: {
          dialogComponentSaveSuccess: _vm.dialogComponentSaveSuccess,
          clearDialogComponentName: _vm.clearDialogComponentName,
        },
      }),
      _vm._v(" "),
      _vm.importData.importDialog
        ? _c("ImportDialog", {
            attrs: {
              name: _vm.importData.importDialog,
              visible: _vm.importData.importDialog,
              "show-close": true,
              title: _vm.importData.importDialogTitle,
              tablename: _vm.itemName,
              "main-table": _vm.tableName,
              "main-id": _vm.itemId,
              "action-id": _vm.actionId,
            },
            on: {
              confirmImport: _vm.importsuccess,
              closeDialog: _vm.closeActionDialog,
              imporSuccess: _vm.imporSuccess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }