var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModule.Navigator
    ? _c(
        "div",
        { class: _vm.classes },
        [
          _c(
            _vm.slotName,
            { tag: "component" },
            [
              _c(
                "div",
                {
                  staticClass: "tag",
                  attrs: { slot: "icon-tag", title: _vm.$t("buttons.fold") },
                  on: { click: _vm.toggle },
                  slot: "icon-tag",
                },
                [
                  !_vm.iconShow
                    ? _c("i", { staticClass: "iconfont iconbj-fold" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.iconShow
                    ? _c("i", { staticClass: "iconfont iconbj-unfold" })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.getDashboardConfig
                ? _c(
                    "div",
                    {
                      staticClass: "tag right",
                      attrs: { slot: "icon-home" },
                      on: { click: _vm.dashboardClick },
                      slot: "icon-home",
                    },
                    [
                      _c("i", {
                        class: _vm.getDashboardConfig,
                        attrs: { title: _vm.$t("tips.backHome") },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.enableHistoryAndFavoriteUI
                ? _c(
                    "div",
                    {
                      staticClass: "HistoryAndFavorite-time",
                      attrs: { slot: "icon-time" },
                      slot: "icon-time",
                    },
                    [
                      _c(
                        "Dropdown",
                        {
                          ref: "Dropdown",
                          staticClass: "HistoryAndFavorite-Dropdown",
                          attrs: {
                            trigger: "click",
                            placement: "bottom-start",
                          },
                        },
                        [
                          _c("Icon", { attrs: { type: "iconmd-time" } }),
                          _vm._v(" "),
                          _c(
                            "DropdownMenu",
                            { attrs: { slot: "list" }, slot: "list" },
                            [_vm._t("collect", [_c("HistoryAndFavorite")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "icon-setting right",
                  attrs: { slot: "icon-Setting" },
                  slot: "icon-Setting",
                },
                [
                  _vm._l(_vm.navigatorSetting, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "tag right" },
                      [
                        _c(
                          "Badge",
                          {
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: {
                              offset: ["6px", "-8px"],
                              count: item.count,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return item.callback($event)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont",
                              class: item.icon,
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("ComAutoComplete", {
                    attrs: { slot: "nav-input" },
                    slot: "nav-input",
                  }),
                  _vm._v(" "),
                  _vm.enableHistoryAndFavoriteUI
                    ? _c("Lately", {
                        attrs: { slot: "icon-Lately" },
                        slot: "icon-Lately",
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.enableHistoryAndFavoriteUI
                    ? _c("Collect", {
                        attrs: { slot: "icon-Collect" },
                        slot: "icon-Collect",
                      })
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.versionValue && !_vm.showTaskIcon
                ? _c(
                    "div",
                    {
                      staticClass: "tag right",
                      attrs: { slot: "icon-message" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.messageSlide($event)
                        },
                      },
                      slot: "icon-message",
                    },
                    [
                      _c(
                        "Tooltip",
                        { attrs: { width: "50", trigger: "hover" } },
                        [
                          _c(
                            "Badge",
                            { attrs: { count: _vm.taskMessageCount } },
                            [
                              _c("i", {
                                staticClass: "iconfont iconbj_message badge",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("tips.message")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("MessageList", {
                        model: {
                          value: _vm.showMessages,
                          callback: function ($$v) {
                            _vm.showMessages = $$v
                          },
                          expression: "showMessages",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.versionValue && _vm.showTaskIcon
                ? _c(
                    "div",
                    {
                      staticClass: "tag right async-task-icon",
                      attrs: { slot: "icon-task" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handlerOpenTasks($event)
                        },
                      },
                      slot: "icon-task",
                    },
                    [
                      _c(
                        "Tooltip",
                        { attrs: { width: "50", trigger: "hover" } },
                        [
                          _c(
                            "Badge",
                            { attrs: { count: _vm.taskMessageCount } },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  color: "#1F272C",
                                  "font-size": "20px",
                                },
                                attrs: { "icon-class": "task" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("tips.task")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("OutLink", {
                attrs: { slot: "icon-outlink" },
                slot: "icon-outlink",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tag right",
                  attrs: { slot: "icon-person" },
                  on: {
                    click: function ($event) {
                      _vm.show = true
                    },
                  },
                  slot: "icon-person",
                },
                [
                  _c("Tooltip", { attrs: { width: "50", trigger: "hover" } }, [
                    _c("i", {
                      staticClass: "iconfont iconmd-person",
                      attrs: { title: _vm.$t("buttons.setting") },
                    }),
                    _vm._v(" "),
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("buttons.setting")) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("Setting", {
                    model: {
                      value: _vm.show,
                      callback: function ($$v) {
                        _vm.show = $$v
                      },
                      expression: "show",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }