var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "Form",
        { attrs: { model: _vm.formItem, "label-width": 200 } },
        [
          _c(
            "FormItem",
            { attrs: { label: "请选择要生成的路由类型" } },
            [
              _c(
                "Select",
                {
                  attrs: { "label-in-value": "" },
                  on: { "on-change": _vm.pathTypeModelChange },
                  model: {
                    value: _vm.formItem.pathTypeModel,
                    callback: function ($$v) {
                      _vm.$set(_vm.formItem, "pathTypeModel", $$v)
                    },
                    expression: "formItem.pathTypeModel",
                  },
                },
                _vm._l(_vm.formItem.pathType, function (item) {
                  return _c(
                    "Option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v("\n          " + _vm._s(item.label) + "\n        ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formItem.pathTypeModel && _vm.formItem.Name
            ? _c(
                "FormItem",
                { attrs: { label: _vm.formItem.Name } },
                [
                  _c("Input", {
                    attrs: { placeholder: _vm.formItem.nameValuePlaceholder },
                    model: {
                      value: _vm.formItem.nameValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.formItem, "nameValue", $$v)
                      },
                      expression: "formItem.nameValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.formItem.pathTypeModel && _vm.formItem.Id
            ? _c(
                "FormItem",
                { attrs: { label: _vm.formItem.Id } },
                [
                  _c("Input", {
                    model: {
                      value: _vm.formItem.idValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.formItem, "idValue", $$v)
                      },
                      expression: "formItem.idValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentInfo.type && _vm.currentInfo.type === "TABLE_DETAIL"
            ? _c(
                "FormItem",
                { attrs: { label: "请选择需要跳转的单对象界面类型:" } },
                [
                  _c(
                    "Select",
                    {
                      attrs: { "label-in-value": "" },
                      on: { "on-change": _vm.singleObjectChange },
                      model: {
                        value: _vm.formItem.singleObjectPageValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.formItem, "singleObjectPageValue", $$v)
                        },
                        expression: "formItem.singleObjectPageValue",
                      },
                    },
                    _vm._l(_vm.formItem.singleObjectPageData, function (item) {
                      return _c(
                        "Option",
                        { key: item.value, attrs: { value: item.value } },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.label) + "\n        "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.formItem.singleObjectPageValue === "0"
            ? _c(
                "FormItem",
                { attrs: { label: "明细ID" } },
                [
                  _c("Input", {
                    model: {
                      value: _vm.formItem.singleObjectPageItemId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formItem, "singleObjectPageItemId", $$v)
                      },
                      expression: "formItem.singleObjectPageItemId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "FormItem",
            [
              _c("Input", {
                attrs: { placeholder: "path", readonly: "", disabled: "" },
                model: {
                  value: _vm.formItem.path,
                  callback: function ($$v) {
                    _vm.$set(_vm.formItem, "path", $$v)
                  },
                  expression: "formItem.path",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FormItem",
            [
              _c(
                "Button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.AutomaticPathGeneration },
                },
                [_vm._v("\n        生成path\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }