<template>
  <div class="sensitivity">
    <div class="noth1" v-if="Group">
      <div style="padding: 10px 0">
        <button class="btn" v-if="!jurisdiction" @click="saveUser()">
          {{ $t("buttons.save") }}
        </button>
        <button class="btn" @click="F5(true)">
          {{ $t("buttons.refresh") }}
        </button>
      </div>
      <div class="centent">
        <!--用户组-->
        <div class="cen_left border">
          <!-- <div class="searching">
            <span><Icon type="ios-search" /></span>
            <input
              type="text"
              name
              id
              @keyup.enter="axiosGroup()"
              v-model="value1"
             :placeholder="$t('messages.enterRole')"
            />
            <i class="iconfont icon-sousuo" @click="axiosGroup()"></i>
          </div> -->
          <!-- :search-query="value1"
              :search-flag="userSearchFlag"
              :treeSetting="treeSetting"
              :refresh="treeRefresh"
              v-on:selectTreeItem="selectTreeItem"
              v-on:searchFinish="searchFinish" -->
          <div class="R3tree" id="sensitivityR3tree">
            <group-tree
              :search-query="value1"
              ref="ztree"
              :search-flag="userSearchFlag"
              :treeSetting="treeSetting"
              :z-nodes="treeData"
              :refresh="treeRefresh"
            ></group-tree>
          </div>
        </div>
        <div class="cen_right border">
          <div class="searching">
            <span><Icon type="ios-search" /></span>
            <input
              type="text"
              name
              @keyup.enter="enterUp()"
              id
              v-model="value2"
              :placeholder="$t('inputSensitiveList')"
            />
            <i class="iconfont icon-sousuo" @click="enterUp()"></i>
          </div>
          <div class="list_head">
            <span style="padding-left: 18px">{{ $t("tips.index") }}</span>
            <span>{{ $t("sensitiveList") }}</span>
            <span>
              <input
                type="checkbox"
                name
                :disabled="jurisdiction"
                v-model="LOOK1"
                id="maomao1"
              />
              <label for="maomao1"></label>{{ $t("buttons.view") }}
            </span>
            <span>
              <input
                type="checkbox"
                name
                :disabled="jurisdiction"
                v-model="RADECT1"
                id="nice1"
              />
              <label for="nice1"></label>{{ $t("buttons.edit") }}
            </span>
            <span style="flex: 1"></span>
          </div>
          <div class="coto" style>
            <ul class="list_body">
              <li
                :key="index"
                v-for="(item, index) of list_body.filter(
                  (item) =>
                    !item.hidden &&
                    !(
                      item.PARENT_ISREAD &&
                      item.PARENT_ISREAD == 'N' &&
                      item.PARENT_ISMODIFY &&
                      item.PARENT_ISMODIFY == 'N'
                    )
                )"
              >
                <span style="padding-left: 18px">{{ index + 1 }}</span>
                <span
                  v-html="matchWords(item.CP_C_COLUMN_ENAME, 'value2')"
                ></span>
                <span class="checkboxFive">
                  <input
                    type="checkbox"
                    name
                    :disabled="jurisdiction || item.PARENT_ISREAD == 'N'"
                    @change="
                      GroupSee($event, item.CP_C_COLUMN_ID, item, 1, index)
                    "
                    v-model="checkboxA"
                    :id="item.CP_C_COLUMN_ID"
                    :value="item.CP_C_COLUMN_ID"
                  />
                  <label :for="item.ID"></label>
                </span>
                <span class="checkboxFive">
                  <input
                    type="checkbox"
                    name
                    :disabled="jurisdiction || item.PARENT_ISMODIFY == 'N'"
                    @change="
                      GroupSee($event, item.CP_C_COLUMN_ID, item, 2, index)
                    "
                    v-model="checkboxB"
                    :id="item.CP_C_COLUMN_ID"
                    :value="item.CP_C_COLUMN_ID"
                  />
                  <label :for="item.CP_C_COLUMN_ID"></label>
                </span>
                <span style="flex: 0.88"></span>
              </li>
            </ul>
          </div>
          <Spin v-show="spinShow" fix>
            <Icon type="ios-loading" size="48" class="demo-spin-icon-load" />
            <div>Loading</div>
          </Spin>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GroupTree from "../../__component__/Ztree.vue";
import network, { urlSearchParams } from "../../__utils__/network";
export default {
  name: "sensitivity",
  data() {
    return {
      objid: window.vm.$route.params.customizedModuleId,
      cid: "",
      UserGroup: [], //加载用户组
      BRAND: [], //加载敏感列
      Group: true, //阀标记
      list_body: [], //	查询用户组敏感接口  *修改页面的时候也会修改这里，sensitivity2存在原始数据
      list_body2: [], //	查询敏感列用户组接口  *修改页面的时候也会修改这里，checkboxBrandS存放原始数据
      checkboxA: [], //存放用户组敏感列开始就已勾选的可查看
      checkboxB: [], //存放用户组敏感列开始就已勾选的可编辑
      checkboxbrand1: [], //存放敏感列用户组开始就已勾选的可查看
      checkboxbrand2: [], //存放开始就已勾选的敏感列用户组可编辑
      GroupsID: "", //当前用户组ID
      BRANDID: "", //当前敏感列ID
      value1: "", //用户组检索
      value2: "", //用户组敏感列检索
      value3: "", //敏感列检索
      value4: "", //敏感列用户组检索
      Groups: {}, //把用户组发生change的id当做对象的属性   主要*
      sensitivity1: {}, //把敏感列发生change的id当做对象的属性   主要*
      jurisdiction: true, //是否有权限编辑
      groupId: "",
      userSearchFlag: "",
      treeRefresh: false,
      curryTree: {}, // 当前点击的树
      treeSetting: {
        data: {
          key: {
            children: "children",
            name: "NAME",
          },
          simpleData: {
            enable: true,
            idKey: "ID", // 树节点ID名称
            pIdKey: "PARENT_ID", // 父节点ID名称
          },
        },
        callback: {
          onClick: this.sefcWarning,
        },
        view: {
          selectedMulti: false,
          showIcon: false,
          nameIsHTML: true,
          dblClickExpand: true,
        },
      },
      treeData: [], // 左侧的树
      timertable:null,
      spinShow: false,
    };
  },
  components: { GroupTree },
  mounted() {
    // this.$request({
    //   //查询权限，有字段的表示有此权限
    //   method: "get",
    //   url: "/p/cs/fetchActionsInCustomizePage",
    //   params: {
    //     param: {
    //       AD_ACTION_NAME: "sensitivity"
    //     }
    //   }
    // }).then(res => {
    //   if (res.data.code == "0") {
    //     let Things = res.data.data;
    //     // for (var i = Things.length - 1; i >= 0; i--) {
    //     // 	if(Things[i].webname==="CgroupColumnUpdateCmd"){//如果有这个字段，则显示保存按钮
    //     this.jurisdiction = false;
    //     // 	}
    //     // }
    //   }
    // });
    this.postGroupData();
    this.jurisdiction = false;
  },
  methods: {
    selectFirstOnce() {
      var treeObj = $.fn.zTree.getZTreeObj("SENSITIVITYANALYSIStreeDemo");

      if (!treeObj) {
        return;
      }
      var nodes = treeObj.getNodes();
      if (nodes.length > 0) {
        treeObj.selectNode(nodes[0]);
        this.groupId = nodes[0].ID;
        treeObj.setting.callback.onClick("", "treeDemo", nodes[0]); //手动触发onClick事件
        this.pageInit = true;
      }
    },
    sefcWarning(e, treeId, object, type) {
      let YN = this.checkData();
      let self = this;
      if (YN.length > 0) {
        this.$Modal.fcWarning({
          title: this.$t("feedback.alert"),
          mask: true,
          showCancel: true,
          content: this.$t("messages.ifSave"),
          onOk: () => {
            self.saveUser(e, treeId, object);
          },
          onCancel: () => {
            this.curryTree = object;
            if (!type) {
              self.selectTreeItem(e, treeId, object);
            } else {
              self.list_body = [];
              self.Groups = {};
              self.postGroupData();
            }
          },
        });
      } else {
        this.curryTree = object;
        this.selectTreeItem(e, treeId, object);
      }
    },
    postGroupData() {
      const _self = this;
      network.post("/p/cs/groupTreeload").then((res) => {
        if (res.data.code === 0) {
          const result = res.data.data;
          this.treeData = result;
          if (this.treeData.length > 0) {
            setTimeout(() => {
              this.selectFirstOnce();
            }, 200);
          }
        }
      });
    },
    F5(val) {
      Array.from(document.querySelectorAll(".coto")).map((obj) => {
        obj.scrollTop = 0;
      });
      this.objid = window.vm.$route.params.customizedModuleId;
      //let from = this.$route.query.from ? true :false; //如果form有，则是用户组进来的，如果没有，则是直接进来的
      this.value1 = "";
      this.treeRefresh = true;
      if (this.$refs.ztree) {
        let YN = this.checkData();
        if (YN.length > 0) {
          // true 页面刷新
          this.sefcWarning("", "", this.curryTree, true);
        } else {
          this.postGroupData();
        }
        this.$refs.ztree.inputValue = "";
      }
    },
    exist(CID) {
      var cida = CID;
      //			cida.push(CID)
      network
        .post({
          //根据左边的CP_C_HRUSERS_ID查询右边的信息
          url: "/p/cs/groupQueryName",
          data: {
            param: JSON.stringify({ GROUPS_ID: cida }),
          },
        })
        .then((res) => {
          this.GroupsID = this.cid;
          this.value1 = res.data.data.name;
          this.axiosGroup();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    enterUp() {
      //模糊查询用户组,按回车键
      let query = this.value2;
      for (let obj of this.list_body) {
        if (
          (obj.CP_C_COLUMN_ENAME &&
            obj.CP_C_COLUMN_ENAME.toLowerCase().indexOf(query.toLowerCase()) >=
              0) ||
          (obj.CP_C_COLUMN_ECODE &&
            obj.CP_C_COLUMN_ECODE.toLowerCase().indexOf(query.toLowerCase()) >=
              0)
        ) {
          this.$set(obj, "hidden", false);
        } else {
          this.$set(obj, "hidden", true);
        }
      }
    },
    matchWords(value, query) {
      //检索模糊匹配高亮
      if (null == value || value == "") return "";
      if (this[query] === "") return value;
      let searchQuery = this[query].replace(
        /[\@\#\$\%\^\&\*\{\}\:\"\L\<\>\?\+\.\(\)\[\]\\]/g,
        (q) => "\\" + q
      );
      return value.replace(
        new RegExp(searchQuery, "gi"),
        (word) => '<b class="color-red">' + word + "</b>"
      );
    },
    UserGroupTable(item, refresh) {
          this.value2 = "";
          this.Groups = {};
           this.spinShow = true;

        network
          .post(
            "/p/cs/cgroupcolumnquery",
            urlSearchParams({
              GROUPS_ID: item.ID,
            })
          )
          .then((res) => {
          
            if (res.data.code == 0) {
              this.checkboxA = [];
              this.checkboxB = [];
              this.list_body = res.data.data;
              this.GroupsID = item.ID;
              for (let i = 0; i < this.list_body.length; i++) {
                this.$set(
                  this.list_body[i],
                  "isread",
                  this.list_body[i].ISREAD
                );
                this.$set(
                  this.list_body[i],
                  "ismodify",
                  this.list_body[i].ISMODIFY
                );
                if (this.list_body[i].ISREAD == "Y") {
                  this.checkboxA.push(this.list_body[i].CP_C_COLUMN_ID);
                }
                if (this.list_body[i].ISMODIFY == "Y") {
                  this.checkboxB.push(this.list_body[i].CP_C_COLUMN_ID);
                }
              }
              if (refresh) {
                this.$Message.success(this.$t("feedback.refreshSuccess"));
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setTimeout(() => {
              this.spinShow = false;
            }, 100);
          });
    },
    GroupSee(event, id, item, number, index) {
      //Groups为tab1的是否可查看
      //ISMODIFY可编辑，ISREAD可查看
      if (number == "1") {
        //1为可查看checked
        item.ISREAD = event.target.checked == true ? "Y" : "N";
        item.ISMODIFY = event.target.checked == false ? "N" : item.ISMODIFY;
        if (!event.target.checked) {
          //如果不可查看，则不可编辑
          for (var i = 0; i < this.checkboxB.length; i++) {
            if (this.checkboxB[i] == item.CP_C_COLUMN_ID) {
              //清除上次选择可查看添加进去的可查看id，不然每次都会push一次
              this.checkboxB.splice(i, 1);
            }
          }
        }
      } else {
        //2为可编辑checked
        item.ISMODIFY = event.target.checked == true ? "Y" : "N";
        item.ISREAD = event.target.checked == true ? "Y" : item.ISREAD;
        if (event.target.checked) {
          //如果为true，则把可查看也选中
          for (var i = 0; i < this.checkboxA.length; i++) {
            if (this.checkboxA[i] == item.CP_C_COLUMN_ID) {
              //清除上次选择可编辑添加进去的可查看id，不然每次都会push一次
              this.checkboxA.splice(i, 1);
            }
          }
          this.checkboxA.push(id);
        }
      }
    },
    checkData() {
      //保存用户组
      this.list_body.map((obj, index) => {
        if (obj.ISREAD != obj.isread || obj.ISMODIFY != obj.ismodify) {
          this.Groups[obj.ID] = {
            ISREAD: obj.ISREAD || "N",
            ID: obj.ID || -1,
            CP_C_COLUMN_ID: obj.CP_C_COLUMN_ID,
            CP_C_GROUPS_ID: this.GroupsID,
            ISMODIFY: obj.ISMODIFY || "N",
          };
        }
      });
      let YN = [];
      for (let [item, index] of Object.entries(this.Groups)) {
        YN.push(index);
      }
      return YN;
    },
    saveUser(e, treeId, object) {
      //保存用户组
      // this.list_body.map((obj, index) => {
      //   if (obj.ISREAD != obj.isread || obj.ISMODIFY != obj.ismodify) {
      //     this.Groups[obj.ID] = {
      //       ISREAD: obj.ISREAD || "N",
      //       ID: obj.ID || -1,
      //       CP_C_COLUMN_ID: obj.CP_C_COLUMN_ID,
      //       CP_C_GROUPS_ID: this.GroupsID,
      //       ISMODIFY: obj.ISMODIFY || "N"
      //     };
      //   }
      // });
      // let YN = [];
      // for (let [item, index] of Object.entries(this.Groups)) {
      //   YN.push(index);
      // }
      let YN = this.checkData();

      if (YN.length > 0) {
        // this.$request({
        //   method: "get",
        //   url: "/p/cs/objectselectTreeItem",
        //   params: {
        //     table: "CP_C_GROUPCOLUMN",
        //     objid: this.objid,
        //     data: JSON.stringify({"CP_C_GROUPS_ID": this.GroupsID, "CP_C_GROUPCOLUMN": YN})//YN是一个数组
        //   }
        // })
        // let postParam = new URLSearchParams();
        // postParam.append("table", "CP_C_GROUPCOLUMN");
        // postParam.append("objid", this.objid);
        // postParam.append(
        //   "data",
        //   JSON.stringify({
        //     CP_C_GROUPS_ID: this.GroupsID,
        //     CP_C_GROUPCOLUMN: YN
        //   })
        // );
        let data = {
          table: "CP_C_GROUPCOLUMN",
          objid: this.objid,
          data: { CP_C_GROUPS_ID: this.GroupsID, CP_C_GROUPCOLUMN: YN }, //YN是一个数组
        };
        network
          .post("/p/cs/cgroupcolumnobjsave", {
            CP_C_GROUPS_ID: this.GroupsID,
            CP_C_GROUPCOLUMN: YN,
          })
          .then((res) => {
            this.spinShow = false;
            if (res.data.code == 0) {
              this.Groups = {};
              this.$Message.success(this.$t("feedback.saveSuccess"));
              // this.UserGroupTable({ ID: this.GroupsID },'spinShow');
            }
            setTimeout(() => {
              if(!object){
                  object = { ID: this.GroupsID }
              }
              this.selectTreeItem(e, treeId, object);
            }, 200);
          })
          .catch((error) => {
            setTimeout(() => {
              this.selectTreeItem(e, treeId, object);
            }, 200);
          });
      } else {
        this.$Message.warning(this.$t("messages.noChange"));
      }
    },
    axiosGroup() {
      this.userSearchFlag = true;
      $(".sensitivity .cen_left").find(".myTree").scrollTop(0);
    },
    //树节点搜索完成
    searchFinish(value) {
      this.userSearchFlag = value;
    },
    //切换用户组tree
    selectTreeItem(e, treeId, object) {
   
      //      if(_self.userId !== object.ID) {
      //        _self.userId = object.ID;
      const _self = this;
      clearTimeout(this.timertable);
      this.timertable = setTimeout(()=>{
           _self.groupId = object.ID;
         _self.UserGroupTable(object, true);
      },300);
      //   if (_self.treeRefresh) _self.UserGroupTable(object, true);
      //   else _self.UserGroupTable(object);
      //   _self.treeRefresh = false;
      //   //      }
    },
  },
  computed: {
    LOOK1: {
      get() {
        return this.list_body.filter(
          (item) => !(item.PARENT_ISREAD && item.PARENT_ISREAD == "N")
        ).length > 0
          ? this.checkboxA.length ===
              this.list_body.filter(
                (item) => !(item.PARENT_ISREAD && item.PARENT_ISREAD == "N")
              ).length
          : false;
      },
      set(v) {
        this.checkboxA = [];
        for (let i of this.list_body.filter(
          (item) => !(item.PARENT_ISREAD && item.PARENT_ISREAD == "N")
        )) {
          if (v) {
            //可查看不一定可编辑
            i.ISREAD = "Y";
            this.checkboxA.push(i.CP_C_COLUMN_ID);
          } else {
            //不可查看一定不可编辑
            i.ISMODIFY = "N";
            i.ISREAD = "N";
            this.checkboxB = [];
          }
        }
      },
    },
    RADECT1: {
      get() {
        return this.list_body.filter(
          (item) => !(item.PARENT_ISMODIFY && item.PARENT_ISMODIFY == "N")
        ).length > 0
          ? this.checkboxB.length ===
              this.list_body.filter(
                (item) => !(item.PARENT_ISMODIFY && item.PARENT_ISMODIFY == "N")
              ).length
          : false;
      },
      set(v) {
        //v为checkbox的true还是false
        this.checkboxB = [];
        for (let i of this.list_body.filter(
          (item) => !(item.PARENT_ISMODIFY && item.PARENT_ISMODIFY == "N")
        )) {
          if (v) {
            //可编辑一定可查看
            i.ISREAD = "Y";
            i.ISMODIFY = "Y";
            this.checkboxB.push(i.CP_C_COLUMN_ID);
          } else {
            //不可编辑与可查看无关
            i.ISMODIFY = "N";
          }
          if (this.checkboxA.indexOf(i.CP_C_COLUMN_ID) < 0) {
            this.checkboxA.push(i.CP_C_COLUMN_ID);
          }
        }
      },
    },
  },
};
</script>
