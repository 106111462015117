var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModule.Navigator
    ? _c(
        _vm.wrapperComponent,
        { tag: "component", class: _vm.classes },
        [
          _c("template", { slot: "left" }, [
            _c(
              "div",
              {
                staticClass: "left",
                style: {
                  width: _vm.collapseHistoryAndFavorite ? "50px" : "180px",
                },
              },
              [
                _c("span", [
                  !_vm.collapseHistoryAndFavorite &&
                  _vm.enableHistoryAndFavoriteUI
                    ? _c("img", {
                        staticClass: "trigger",
                        attrs: {
                          title: _vm.$t("messages.collapseFavorites"),
                          alt: "",
                          src: _vm.imgSrc.closedImg,
                        },
                        on: { click: _vm.doCollapseHistoryAndFavorite },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.collapseHistoryAndFavorite &&
                  _vm.enableHistoryAndFavoriteUI
                    ? _c("img", {
                        staticClass: "trigger",
                        attrs: {
                          alt: "",
                          title: _vm.$t("messages.expandFavorites"),
                          src: _vm.imgSrc.openedImg,
                        },
                        on: { click: _vm.doCollapseHistoryAndFavorite },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: !_vm.collapseHistoryAndFavorite
                      ? "navBrandImg-banner"
                      : "navBrandImg-logo",
                    attrs: { id: "navBrandImg" },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.collapseHistoryAndFavorite,
                            expression: "!collapseHistoryAndFavorite",
                          },
                        ],
                        staticClass: "banner",
                      },
                      [
                        !_vm.banner
                          ? _c("img", {
                              attrs: { alt: "", src: _vm.imgAssets.banner },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.banner
                          ? _c("div", {
                              domProps: { innerHTML: _vm._s(_vm.banner) },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.collapseHistoryAndFavorite,
                          expression: "collapseHistoryAndFavorite",
                        },
                      ],
                      staticClass: "logo",
                      attrs: { alt: "", src: _vm.imgSrc.logoImg },
                    }),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "middle" },
            [
              _c("div", { staticClass: "middle" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  _vm._l(_vm.menuLists, function (menu, index) {
                    return _c("NavigatorPrimaryMenu", {
                      key: "primary-menu-" + index,
                      attrs: { data: menu, index: index },
                      on: { togglePrimaryMenu: _vm.togglePrimaryMenu },
                    })
                  }),
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("NavigatorSubMenu", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.primaryMenuIndex !== -1,
                        expression: "primaryMenuIndex!==-1",
                      },
                    ],
                    attrs: { data: _vm.togglePrimaryMenuData || [] },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "icon-home" },
            [
              _vm._l(_vm.navigatorSetting, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "tag right",
                    staticStyle: { width: "auto", display: "flex" },
                  },
                  [
                    _c(
                      "Badge",
                      {
                        staticStyle: { width: "50px", height: "50px" },
                        attrs: { offset: ["6px", "-8px"], count: item.count },
                        nativeOn: {
                          click: function ($event) {
                            return item.callback($event)
                          },
                        },
                      },
                      [_c("i", { staticClass: "iconfont", class: item.icon })]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.getDashboardConfig
                ? _c(
                    "div",
                    {
                      staticClass: "tag right",
                      on: { click: _vm.dashboardClick },
                    },
                    [
                      _c("i", {
                        class: _vm.getDashboardConfig,
                        attrs: { title: _vm.$t("tips.backHome") },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("template", { slot: "nav-input" }, [_c("ComAutoComplete")], 1),
          _vm._v(" "),
          _c(
            "template",
            { slot: "icon-task" },
            [
              _vm.versionValue && !_vm.showTaskIcon
                ? _c(
                    "div",
                    {
                      staticClass: "tag right",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.messageSlide($event)
                        },
                      },
                    },
                    [
                      _c(
                        "Tooltip",
                        { attrs: { width: "50", trigger: "hover" } },
                        [
                          _c(
                            "Badge",
                            { attrs: { count: _vm.taskMessageCount } },
                            [
                              _c("i", {
                                staticClass: "iconfont iconbj_message badge",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("tips.message")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.versionValue && _vm.showTaskIcon
                ? _c(
                    "div",
                    {
                      staticClass: "tag right async-task-icon",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handlerOpenTasks($event)
                        },
                      },
                    },
                    [
                      _c(
                        "Tooltip",
                        { attrs: { width: "50", trigger: "hover" } },
                        [
                          _c(
                            "Badge",
                            { attrs: { count: _vm.taskMessageCount } },
                            [
                              _c("svg-icon", {
                                staticStyle: { width: "21px" },
                                attrs: { "icon-class": "task" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("tips.task")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("MessageList", {
                model: {
                  value: _vm.showMessages,
                  callback: function ($$v) {
                    _vm.showMessages = $$v
                  },
                  expression: "showMessages",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("template", { slot: "icon-outside" }, [_c("OutLink")], 1),
          _vm._v(" "),
          _c(
            "template",
            { slot: "icon-person" },
            [
              _c(
                "div",
                {
                  staticClass: "tag right",
                  on: {
                    click: function ($event) {
                      _vm.show = true
                    },
                  },
                },
                [
                  _c("Tooltip", { attrs: { width: "50", trigger: "hover" } }, [
                    _c("i", {
                      staticClass: "iconfont iconmd-person",
                      attrs: { title: _vm.$t("buttons.setting") },
                    }),
                    _vm._v(" "),
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("buttons.setting")) +
                          "\n        "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("Setting", {
                model: {
                  value: _vm.show,
                  callback: function ($$v) {
                    _vm.show = $$v
                  },
                  expression: "show",
                },
              }),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }