var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "keep-alive",
    { attrs: { include: _vm.keepAliveLists } },
    [
      _vm.show
        ? _c(_vm.currentModule, {
            tag: "component",
            attrs: { "url-name": _vm.urlName },
            on: { "hook:activated": _vm.handleActivated },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }