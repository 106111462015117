var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("div", { staticClass: "clonePopUp", staticStyle: { height: "140px" } }, [
      _c("div", { staticClass: "pop-title" }, [
        _c("div", { staticClass: "pop-input" }, [
          _c("ul", { staticStyle: { "list-style": "none" } }, [
            _c("li", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("messages.fillPassword")) + "："),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newpaswd,
                    expression: "newpaswd",
                  },
                ],
                class: { borderactive: _vm.inconformity2 },
                attrs: { id: "newPwd", type: "password" },
                domProps: { value: _vm.newpaswd },
                on: {
                  blur: function ($event) {
                    return _vm.blur()
                  },
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.newpaswd = $event.target.value
                    },
                    function ($event) {
                      return _vm.changwd()
                    },
                  ],
                  focus: function ($event) {
                    return _vm.focus($event, 2)
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                staticStyle: { display: "none" },
                attrs: { type: "password", name: "password1" },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pawdgrade1,
                      expression: "pawdgrade1",
                    },
                  ],
                  style: { color: _vm.errorpawdgrade1 },
                },
                [_vm._v(_vm._s(this.newHint))]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("messages.confirmPassword")) + "："),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.againpaswd,
                    expression: "againpaswd",
                  },
                ],
                class: { borderactive: _vm.inconformity3 },
                attrs: { type: "password" },
                domProps: { value: _vm.againpaswd },
                on: {
                  focus: function ($event) {
                    return _vm.focus($event, 3)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.againpaswd = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                staticStyle: { display: "none" },
                attrs: { type: "password", name: "password1" },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inconformity3,
                      expression: "inconformity3",
                    },
                  ],
                  style: { color: _vm.error },
                },
                [_vm._v(_vm._s(_vm.$t("messages.passwordInconsistent")))]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pop-btn" }, [
          _c(
            "span",
            {
              staticClass: "sav-btn",
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.confirm")))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "cancel-btn",
              on: {
                click: function () {
                  this$1.$emit("closeActionDialog", false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }