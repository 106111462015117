var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c(
      "div",
      { staticClass: "tabs_container" },
      [
        _c("div", { staticClass: "navButton" }, [
          _vm.actionFlag.produceFlag
            ? _c("button", { on: { click: _vm.produceCode } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("messages.generateBarcode")) +
                    "\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("button", { on: { click: _vm.backMainTab } }, [
            _vm._v("\n        " + _vm._s(_vm.$t("buttons.back")) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("TabPanels", {
          ref: "tabPanels",
          attrs: {
            type: "singleCard",
            "is-keep-alive": "",
            "tab-margin-left": 20,
            "tab-height": 26,
            "tab-panels": _vm.tabPanels,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }