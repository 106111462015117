var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "radio-container" },
    [
      _c(
        "CheckboxGroup",
        {
          on: { "on-change": _vm.handleChange },
          model: {
            value: _vm.selectedValues,
            callback: function ($$v) {
              _vm.selectedValues = $$v
            },
            expression: "selectedValues",
          },
        },
        _vm._l(_vm.combobox, function (item) {
          return _c("Checkbox", {
            key: item.limitdesc,
            attrs: {
              label: item.limitdesc,
              disabled: _vm.options.disabled,
              size: _vm.options.size,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }