var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "r3-input-with-select" },
    [
      _vm.componentName
        ? _c(
            _vm.componentName,
            _vm._g(
              _vm._b(
                {
                  tag: "component",
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: _vm.placeholder,
                  },
                  on: { "on-change": _vm.handleInput },
                  model: {
                    value: _vm.inputValue,
                    callback: function ($$v) {
                      _vm.inputValue = $$v
                    },
                    expression: "inputValue",
                  },
                },
                "component",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            [
              _c(
                "Select",
                {
                  staticStyle: { width: "80px" },
                  attrs: { slot: "prepend", transfer: "" },
                  on: { "on-change": _vm.handleSelect },
                  slot: "prepend",
                  model: {
                    value: _vm.selectValue,
                    callback: function ($$v) {
                      _vm.selectValue = $$v
                    },
                    expression: "selectValue",
                  },
                },
                [
                  _c("Option", { attrs: { value: "all" } }, [
                    _vm._v(_vm._s(_vm.$t("tips.all"))),
                  ]),
                  _vm._v(" "),
                  _c("Option", { attrs: { value: "equal" } }, [
                    _vm._v(_vm._s(_vm.$t("tips.equal"))),
                  ]),
                  _vm._v(" "),
                  _c("Option", { attrs: { value: "isNull" } }, [
                    _vm._v(_vm._s(_vm.$t("tips.isNull"))),
                  ]),
                  _vm._v(" "),
                  _c("Option", { attrs: { value: "isNotNull" } }, [
                    _vm._v(_vm._s(_vm.$t("tips.notNull"))),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }