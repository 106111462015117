<template>
  <div :class="classes">
    <div class="clonePopUp">
      <div class="pop-title">
        <div class="pop-input">
          <ul style="list-style:none;">
            <li>
              <span>{{$t('tips.oldPassword')}}：</span><input
                id="ativeFocus"
                v-model="oldpaswd"
                type="password"
                :class="{'borderactive':inconformity1}"
                @focus="focus($event,1)"
              ><input
                type="password"
                name="password1"
                style="display:none"
              >
              <p
                v-show="oldp"
                :style="{ color: errorpawdgrade}"
              >
                {{$t('messages.enterOldPassword')}}
              </p>
            </li>
            <li>
              <span>{{$t('tips.newPassword')}}：</span><input
                v-model="newpaswd"
                :class="{'borderactive':inconformity2}"
                type="password"
                @blur="blur()"
                @input="changwd()"
                @focus="focus($event,2)"
              ><input
                type="password"
                name="password1"
                style="display:none"
              >
              <p
                v-show="pawdgrade1"
                :style="{ color: errorpawdgrade1}"
              >
                {{ this.newHint }}
              </p>
              <p v-show="pawdgrade">
                {{$t('messages.passwordSecurity')}}：<b :style="{ color: activeColor}">{{ grade }}</b>
              </p>
            </li>
            <li>
              <span>{{$t('messages.confirmPassword')}}：</span><input
                v-model="againpaswd"
                :class="{'borderactive':inconformity3}"
                type="password"
                @focus="focus($event,3)"
              ><input
                type="password"
                name="password1"
                style="display:none"
              >
              <p
                v-show="inconformity3"
                :style="{ color: error}"
              >
                {{$t('messages.passwordInconsistent')}}
              </p>
            </li>
          </ul>
        </div>
        <div class="pop-btn">
          <span
            class="sav-btn"
            @click="save()"
          >{{$t('buttons.confirm')}}</span>
          <span
            class="cancel-btn"
            @click="()=>{this.$emit('closeActionDialog',false)}"
          >{{$t('buttons.cancel')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import network from '../../__utils__/network';
  import { classFix } from '../../constants/global';

  export default {
    name: 'ChangePassword',
    data() {
      return {
        title: this.$t('tips.changePassword'), // 标题
        oldpaswd: '',
        newpaswd: '',
        pawdgrade2: false,
        errorpawdgrade: 'red',
        errorpawdgrade1: '#818181',
        newHint: this.$t('messages.passwordNewRule'),
        againpaswd: '',
        pop_dialog: false,
        activeColor: 'red', // 控制密码强度的时候，颜色
        error: 'red',
        grade: this.$t('tips.low'), // 密码等级
        oldp: false, // 旧密码是否错误
        pawdgrade: false, // 显示密码等级
        pawdgrade1: true, // 密码长度
        inconformity1: false, // input错误border颜色class
        inconformity2: false, // input错误border颜色class
        inconformity3: false, // input错误border颜色class
        reg: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,18}$/)
    };
    },
    computed: {
      classes() {
        return [
          `${classFix}ChangePassword`,
        ];
      },
    },
    mounted() {
      document.getElementById('ativeFocus').focus();
    },
    methods: {
      changwd() { // 修改密码，显示密码强度
        this.pawdgrade = false;
        this.pawdgrade1 = true;
      },
      blur() { // 失焦时判断密码
        if (this.newpaswd === '') {
          this.pawdgrade1 = true;
          this.errorpawdgrade1 = 'red';
          this.inconformity2 = true;
          this.newHint = this.$t('messages.enterNewPassword');
        } else if (this.newpaswd.length >= 6 && this.newpaswd.length <= 18) {
          if (!this.reg.test(this.newpaswd)) {
            this.newHint = this.$t('messages.passwordNewRule');
            this.errorpawdgrade1 = 'red';
            this.inconformity2 = true;
            this.pawdgrade1 = true;
          }
          this.errorpawdgrade1 = '#818181';
          this.newHint = this.$t('messages.passwordNewRule');
        } else {
          this.newHint = this.$t('messages.passwordNewRule');
          this.errorpawdgrade1 = 'red';
          this.inconformity2 = true;
          this.pawdgrade1 = true;
        }
      },
      focus(e, num) { // 聚焦清除提示
        switch (num) {
        case 1: this.oldp = false; this.inconformity1 = false;
                break;
        case 2: this.pawdgrade = false; this.pawdgrade1 = true; this.inconformity2 = false; this.errorpawdgrade1 = '#818181'; this.newHint = this.$t('messages.passwordNewRule');
                break;
        default: this.inconformity3 = false;
        }
      },
      save() { // 保存
        if (!this.oldpaswd) { // 如果旧密码为空
          this.oldp = true;
          this.inconformity1 = true;
          return false;
        }
        if (this.newpaswd === '') { // 新密码为空
          this.pawdgrade1 = true;
          this.errorpawdgrade1 = 'red';
          return false;
        }
        if (this.newpaswd.length < 6) { // 密码长度小于6
          this.pawdgrade = false;
          this.errorpawdgrade1 = 'red';
          this.pawdgrade1 = true;
          return false;
        }
        if (this.newpaswd.length > 18) { // 密码长度大于18
          this.pawdgrade = false;
          this.errorpawdgrade1 = 'red';
          this.pawdgrade1 = true;
          return false;
        }
        if (this.againpaswd === '') { // 再次输入密码为空
          this.inconformity3 = true;
          return false;
        }
        if (!this.reg.test(this.newpaswd)) { // 只要有一位数字、一位字母，其他四位不管是啥
          this.pawdgrade = false;
          this.errorpawdgrade1 = 'red';
          this.pawdgrade1 = true;
          return false;
        }
        if (this.newpaswd !== this.againpaswd) { // 两次密码不一样
          this.inconformity3 = true;
          return false;
        }
        network.post('/p/cs/changechruserpwd', {
          oldpassword: this.oldpaswd,
          newpassword: this.newpaswd,
          confirmpassword: this.againpaswd
        }).then((res) => {
          if (res.data.code === 0) {
            const message = {
              title: this.$t('feedback.success'),
              content: res.data.message
            };
            this.$Modal.fcSuccess(message);
            this.newpaswd = '';
            this.againpaswd = '';
            this.$emit('closeActionDialog', true);
          }
        });
        return true;
      }
    }
  };
</script>
