var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pageNotFound", staticStyle: { height: "100%" } },
    [
      _c("h1", [_vm._v("没有找到与如下路由：")]),
      _vm._v(" "),
      _c("h1", [_vm._v(_vm._s(_vm.info))]),
      _vm._v(" "),
      _c("h1", [_vm._v("相匹配的页面")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }