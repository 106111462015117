var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModule.Navigator
    ? _c(
        "div",
        { class: _vm.classes },
        [
          _c("div", { staticClass: "left" }, [
            _c(
              "div",
              {
                staticClass: "navBrandImg-banner",
                attrs: { id: "navBrandImg" },
              },
              [
                _c("div", { staticClass: "banner" }, [
                  !_vm.banner
                    ? _c("img", {
                        staticClass: "banner",
                        attrs: { alt: "", src: _vm.imgAssets.banner },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.banner
                    ? _c("div", { domProps: { innerHTML: _vm._s(_vm.banner) } })
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("VerticalMenu"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }