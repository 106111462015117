var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("left"),
      _vm._v(" "),
      _vm._t("middle"),
      _vm._v(" "),
      _vm._t("icon-outside"),
      _vm._v(" "),
      _vm._t("icon-home"),
      _vm._v(" "),
      _vm._t("nav-input"),
      _vm._v(" "),
      _vm._t("icon-task"),
      _vm._v(" "),
      _vm._t("icon-person"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }