var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "R3tree", attrs: { id: "R3tree" } },
    [
      _c("Ztree", {
        ref: "zTree",
        attrs: {
          "z-nodes": _vm.treeData,
          enterDefault: _vm.enterDefault,
          placeholder: _vm.placeholder,
          Notice: _vm.Notice,
        },
        on: { clickTreeNode: _vm.clickTreeNode, treeSearch: _vm.treeSearch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }