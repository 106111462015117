<template>
  <div ref="radio-container">
    <CheckboxGroup
      v-model="selectedValues"
      @on-change="handleChange"
    >
      <Checkbox
        v-for="item in combobox"
        :key="item.limitdesc"
        :label="item.limitdesc"
        :disabled="options.disabled"
        :size="options.size"
      />
    </CheckboxGroup>
  </div>
</template>

<script type="text/ecmascript-6">
import network from '../../__utils__/network';

export default {
  name: 'R3CheckboxGroup',

  model: {
    props: 'value',
    event: 'change'
  },

  props: {
    // 双向绑定值
    value: {
      type: [String, Array],
    },
    // 外部传入的所有参数
    options: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      selectedValues: [],
      FormItemValue:[], // 原生value
      combobox:[],
    };
  },

  watch: {
    // 主表组件被隐藏时，需要清空CheckboxGroup组件的值
    'options.show'(newVal) {
      if (newVal === false) {
        this.selectedValues = [];
      }
    },
    // 子表组件被隐藏时，需要清空CheckboxGroup组件的值
    'options.showCol'(newVal) {
      if (newVal === false) {
        this.selectedValues = [];
      }
    },
    'options.combobox'(newVal){
      this.combobox = newVal;
    },
    value: {
      handler(newVal) {
        this.FormItemValue =  newVal;
        if (newVal) {
          this.selectedValues = this.getSelectedValues(newVal);
        } else {
          this.selectedValues = []
        }
      },
      immediate: true
    }
  },

  methods: {
    filterData(data,props){
      // 过滤表明
      let self = this;
      if(props.url){
        this.combobox = [];
        // 监控对象  根据来源字段的值查询
       let parentVm = document.querySelector(`#${props.formName}`).__vue__;
       let DataSourceVm = this.$_live_getChildComponent(parentVm,'NA_MONITORTABLE_ID');
         const changeFun = DataSourceVm.$refs.TABLE_ID.valueChange;
        //  监听监控对象修改
           DataSourceVm.$refs.TABLE_ID.valueChange = function(){
                changeFun.call(this,...arguments);
                  self.FormItemValue = [];
                  self.combobox = [];
                  self.handleChange([]);
                  if( arguments[1] === 'clear'){
                    // 如果是清空
                    return false;

                  }
                  if(arguments[1] !== 'input'){
                    let sendData = {
                      ID:data.itemId.toLocaleUpperCase() ==='NEW' ? -1 :window.parseInt16(data.itemId),
                      TABLE_ID:window.parseInt16(arguments[0][0] ? arguments[0][0].ID :'')
                    }
                    // 走接口
                    self.getOptions(sendData,props.url)

                  }
                  
            }
            // 监听监控对象传参
            if(DataSourceVm.value[0]){
                  let sendData = {
                      ID:data.itemId.toLocaleUpperCase() ==='NEW' ? -1 :window.parseInt16(data.itemId),
                      TABLE_ID:window.parseInt16(DataSourceVm.value[0].ID)
                    }
                    // 走接口
                    self.getOptions(sendData,props.url)
            }
         }
       
    },
    getOptions(data,url){
      // 获取接口数据     
      ;
      network.post(url,data).then((res) => {
        this.combobox = res.data.data.concat([]);
        if(this.FormItemValue && this.FormItemValue.length>0){
          this.selectedValues = this.getSelectedValues(this.FormItemValue);
          console.log(this.selectedValues);
        }


      })

    },
    handleChange(values) {
      const checkedList = [];
      if (values.length === 0) {
        this.$emit('change', '');
        return;
      }

      this.combobox.forEach((item) => {
        if (values.includes(item.limitdesc)) {
          checkedList.push(item.limitval);
        }
      });

      this.$emit('change', checkedList.join(','));
    },

    // 获取勾选的值
    getSelectedValues(value) {
      const checkedList = [];
      const selectedArr = value.split(',');
      this.combobox.forEach((item) => {
        if (selectedArr.includes(item.limitval)) {
          checkedList.push(item.limitdesc);
        }
      });
      return checkedList
    }
  },
  mounted(){
      let { params } = this.$route;
      this.combobox = this.options.combobox;
      this.filterData(params,this.options);
  }
};
</script>
