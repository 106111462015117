var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    attrs: {
      id: "printframe",
      name: "printframe",
      width: "1000px",
      height: "600px",
      src: _vm.src,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }