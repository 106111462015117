import Vue from 'vue'
import i18n from '../assets/js/i18n'
import network, { urlSearchParams } from '../__utils__/network'
import { DispatchEvent } from '../__utils__/dispatchEvent';
import urlParse from './urlParse'
import axios from 'axios'
import { getLocaleUrl } from '../constants/global'
import pkg from '../../package.json'

const BCL_mergeLocaleMessage = $Bcl.BCL_mergeLocaleMessage
const BCL_setLocaleMessage = $Bcl.BCL_setLocaleMessage

// 扩展语言
window.R3_mergeLocaleMessage = function(language, options) {
  // {R3,Bcl} from options  用来替换或者是扩展语言
  // 扩展r3语言
  if (options && options.R3) {
    i18n.mergeLocaleMessage(language, options.R3)
  }
  // 扩展业务组件库语言
  if (options && options.BCL) {
    BCL_mergeLocaleMessage(language, options.BCL)
  }
}

// 新增语言
window.R3_setLocaleMessage = function(language, options) {
  // {R3,Bcl} from options  用来替换或者是扩展语言
  // 扩展r3语言
  if (options && options.R3) {
    i18n.setLocaleMessage(language, options.R3)
  }
  // 扩展业务组件库语言
  if (options && options.BCL) {
    BCL_setLocaleMessage(language, options.BCL)
  }
}

// 挂载语言切换api
window.R3I18n = function(language = 'zh-CN', options) {
  const serviceId = window.localStorage.getItem('serviceId')
  const url = serviceId
    ? `/${serviceId}/p/cs/langswitcher`
    : '/p/cs/langswitcher'

  // 是否请求切换语言接口
  if (options && options.enableApi) {
    return network
      .post(url, urlSearchParams({ language }), { noServiceId: true })
      .then((res) => {
        if (res.data.code === 0) {
          setLanguages(language)
        }
      })
  } else {
    setLanguages(language)
  }
}

window.__r3i18n__ = i18n
const DEFAULT_LOCALE = 'zh-CN'
// 挂载r3语言包
if (!window.R3_lang) {
  window.R3_lang = {}
}

// 处理大小写。例如zh-cn转zh-CN
function reviseLocale(locale = DEFAULT_LOCALE) {
  if(locale.includes('-')) {
    // 如zh-cn的情况
    const localeArr = locale.split('-')
    return localeArr[0].toLowerCase() + '-' + localeArr[1].toUpperCase()
  } else if(locale.includes('_')) {
    const localeArr = locale.split('_')
    return localeArr[0].toLowerCase() + '-' + localeArr[1].toUpperCase()
  } else if(locale.toLowerCase() === 'zh') {
    return 'zh-CN'
  } else {
    // 如en的情况
    return locale.toLowerCase()
  }
}

// 获取当前语言
export function getCurrentLocale(lang) {
  const localLocale = localStorage.getItem('r3-lang') || DEFAULT_LOCALE
  const params = urlParse(location.search)
  let locale = lang || params.locale || localLocale || DEFAULT_LOCALE
  locale = reviseLocale(locale)
  if (locale === 'zh') {
    locale = 'zh-CN' // 中文以zh-CN为准，并要兼容之前的zh。包文件是zh-CN。设置时再换成zh-CN
  } else if (locale.startsWith("zh") && locale !== 'zh-CN'){
    locale = 'zh-CHT'
  } else if(['en', 'en-US','en-GB'].includes(locale)){
    locale = 'en'
  }
  return locale
}

// 内置的中文语言包，直接读内存的
export function getLanguageCache(type, lang) {
  switch(type) {
    case 'R3':
      return window.R3_lang[lang]
    case 'ArkBcl':
      return window.R3_lang[lang] // 和R3用同一份
  }
}

// 获取语言包
export async function getLanguagePackage(lang) {
  const localLocale = localStorage.getItem('r3-lang') || DEFAULT_LOCALE
  const params = urlParse(location.search)
  let locale = lang || params.locale || localLocale || DEFAULT_LOCALE
  locale = reviseLocale(locale)
  if (locale === 'zh') {
    locale = 'zh-CN' // 中文以zh-CN为准，并要兼容之前的zh。包文件是zh-CN。设置时再换成zh-CN
  }
  const externalUrl = getLocaleUrl()
  const version = pkg.dependencies['@syman/burgeon-r3-locale'].replace('^', '')
  const defaultUrl = `https://ark-font.oss-cn-hangzhou.aliyuncs.com/CDN/%40syman/burgeon-r3-locale/${version}`
  let url = ''
  if(externalUrl) {
    url = `${externalUrl}/${locale}.json`
  } else {
    url = `${defaultUrl}/${locale}.json`
  }

  return axios
    .get(url)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return undefined
      }
    })
    .catch((err) => {
      console.error(err)
      return undefined
    })
}

// 设置ark-bus语言
async function setArkBclLocale(currentLocale, languagePackage) {
  BCL_mergeLocaleMessage(currentLocale, languagePackage)
  $Bcl.locale(currentLocale)
  localStorage.setItem('ark-ui-bcl-lang', currentLocale)
}

// 请求语言切换接口
async function requestLocaleApi(language) {
  const serviceId = window.localStorage.getItem('serviceId')
  const url = serviceId
    ? `/${serviceId}/p/cs/langswitcher`
    : '/p/cs/langswitcher'
  return axios.post(url, urlSearchParams({ language }))
}

// 设置R3语言
async function setR3Locale(currentLocale, languagePackage ) {
  i18n.mergeLocaleMessage(currentLocale, languagePackage)
  i18n.locale = currentLocale
  window.R3_lang[currentLocale] = i18n.messages[currentLocale]
}

// 设置r3和bcl语言
async function setBclAndR3Locale(lang) {
  const currentLocale = getCurrentLocale(lang)
  let languagePackage
  if(currentLocale === 'zh-CN') {
    languagePackage = getLanguageCache('R3', currentLocale)
  } else {
    languagePackage = await getLanguagePackage(currentLocale)
  }
  if(languagePackage) {
    await setR3Locale(currentLocale, languagePackage)
    await setArkBclLocale(currentLocale, languagePackage)
    lang && localStorage.setItem('r3-lang', lang) // 登录页地址没参数时,lang会不存在
  }
}

function setLanguages(lang, isRequestApi) {
  return Promise.all([
    // setArkLocale(lang), // 暂时不管arkui的切换
    setBclAndR3Locale(lang),
  ]).then(async () => {
    window.__r3I8n_Ready__ = true // 用于onR3I18nLoaded监听挂载晚了，可以利用这个标记判断时机
    DispatchEvent('onR3I18nLoaded', {
      detail: {
        i18n
      }
    })
    console.log('语言加载完毕')
    // 地址栏传了语言，就请求接口
    if(isRequestApi) {
      await requestLocaleApi(lang || DEFAULT_LOCALE)
    }
  })
}

// 提示刷新
function showRefreshModal(locale) {
  window.vm.$Modal.fcWarning({
    title: i18n.t('feedback.alert'),
    mask: true,
    content: i18n.t('messages.changeLang',{locale}),
    onOk: () => {
      location.reload()
    }
  })
}

// 提示加载失败
function showLoadError(locale) {
  window.vm.$Modal.fcWarning({
    title: i18n.t('feedback.alert'),
    mask: true,
    showCancel: true,
    content: i18n.t('messages.noLocalePack',{locale}),
  })
}

// 页面有语言参数时，才设置语言。否则根据hello返回语言进行设置
const params = urlParse(location.search)
if(!params.locale) {
  setLanguages()
}

window.addEventListener('userReady', async function(e) {
  const { detail } = e
  const params = urlParse(location.search)
  const locale = params.locale || detail.userInfo.locale // 路由参数的优先级比接口返回值高
  if(params.locale && location.pathname !== '/login') {
    console.log('路由参数设置语言', getCurrentLocale(params.locale))
    await requestLocaleApi(getCurrentLocale(params.locale))
  } 
  if(locale === 'null') { // 用户没设置语言
    return
  }
  const localLocale = reviseLocale(localStorage.getItem('r3-lang') || DEFAULT_LOCALE)

  const transformedLocale = reviseLocale(locale)
  const transformedLocalLocale = reviseLocale(localLocale)
  console.log('待生效语言',locale, '统一格式后', transformedLocale)
  console.log('本地缓存语言',localLocale, '统一格式后', transformedLocalLocale)

  if(locale && transformedLocalLocale !== transformedLocale) {
    const currentLocale = getCurrentLocale(locale)

    let R3LanguagePackage
    if(currentLocale === 'zh-CN') {
      R3LanguagePackage = getLanguageCache('R3', currentLocale)
    } else {
      R3LanguagePackage = await getLanguagePackage(currentLocale)
    }

    // 语言包加载都成功，提示刷新
    if(R3LanguagePackage) {
      localStorage.setItem('r3-lang', locale)
      localStorage.setItem('ark-ui-bcl-lang', locale)
      showRefreshModal(locale)
    } else {
      // 提示加载失败
      showLoadError(locale)
    }
  }
})