var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Dialog", {
    ref: "dialog",
    attrs: {
      footerHide: true,
      closable: false,
      mask: false,
      width: _vm.type === "list" ? 410 : 420,
      title: "",
      dialogComponentName: "skqNoticeQueue",
      dynamicAttrs: _vm.dynamicAttrs,
      "class-name":
        _vm.type === "list"
          ? "r3-dialog-skqNoticeQueue"
          : "r3-dialog-skqNotice",
      dynamicEvents: _vm.dynamicEvents,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }