var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.waterMarkContainerCls, style: _vm.waterMarkStyle },
    [
      _c("i", { staticClass: "iconfont iconbj_docstate" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "textTip",
          class: { smallText: _vm.textArr.length >= 5 },
          style: _vm.position,
        },
        _vm._l(_vm.textArr, function (item, index) {
          return _c("div", { key: index }, [
            _vm._v("\n      " + _vm._s(item) + "\n    "),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }