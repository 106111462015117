var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c("div", { staticClass: "r3-notice-content" }, [
        _c("div", { staticClass: "notice-header" }, [
          _c("div", { staticClass: "notice-header-l" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("tips.message")))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "notice-header-r" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0);" },
                on: { click: _vm.close },
              },
              [_c("Icon", { attrs: { type: "ios-close", size: "22" } })],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "notice-body", on: { click: _vm.handleClick } },
          [
            _c(
              "div",
              { staticClass: "notice-body-l" },
              [
                _vm.isSuccess
                  ? _c("Icon", {
                      staticClass: "notice-success",
                      attrs: {
                        type: "ios-checkmark-circle-outline",
                        size: "24",
                      },
                    })
                  : _c("Icon", {
                      staticClass: "notice-error",
                      attrs: { type: "ios-close-circle-outline", size: "24" },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "notice-body-r" }, [
              _c("div", { staticClass: "notice-task" }, [
                _vm._v(
                  _vm._s(_vm.noticeMessage) +
                    _vm._s(
                      this.isSuccess && this.isExport
                        ? "，" + _vm.$t("messages.seeDetails")
                        : ""
                    ) +
                    "\n        "
                ),
                this.isSuccess && this.isExport
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.link,
                          color: "#1200F5",
                          download: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.readTask($event)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "【" + _vm._s(_vm.$t("messages.downloadFile")) + "】"
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }