export default {
  batchChangePassword: {
    component: () => import(
      /* webpackChunkName: 'batchChangePassword' */
      /* webpackMode: 'eager' */
      '../__customizeDialog__/batchChangePassword/batchChangePassword.vue'
      )
  },
  ChangePassword: {
    component: () => import(
      /* webpackChunkName: 'ChangePassword' */
      /* webpackMode: 'eager' */
      '../__customizeDialog__/ChangePassword/ChangePassword.vue'
    )
  },
  clonePopUp: {
    component: () => import(
      /* webpackChunkName: 'clonePopUp' */
      /* webpackMode: 'eager' */
      '../__customizeDialog__/ClonePopUp/ClonePopUp.vue'
    )
  },
  Publish: {
    component: () => import(
      /* webpackChunkName: 'Publish' */
      /* webpackMode: 'eager' */
      '../__customizeDialog__/Publish/Publish.vue'
    )
  },
  PublishPrintTemplate: {
    component: () => import(
      /* webpackChunkName: 'PublishPrintTemplate' */
      /* webpackMode: 'eager' */
      '../__customizeDialog__/PublishPrintTemplate/PublishPrintTemplate.vue'
    )
  },
  printPreview: {
    component: () => import(
      /* webpackChunkName: 'PublishPrintTemplate' */
      /* webpackMode: 'eager' */
      '../__customizeDialog__/printPreview/printPreview.vue'
    )
  },
  printTemplate: {
    component: () => import(
      /* webpackChunkName: 'PublishPrintTemplate' */
      /* webpackMode: 'eager' */
      '../__customizeDialog__/printTemplate/printTemplate.vue'
    )
  },
  report: {
    component: () => import(
      /* webpackChunkName: 'PublishPrintTemplate' */
      /* webpackMode: 'eager' */
      '../__customizeDialog__/Report/report.vue'
    )
  },
  getMetaData: { // 获取元数据
    component: () => import(
      /* webpackChunkName: 'PublishPrintTemplate' */
      /* webpackMode: 'eager' */
      '../__customizeDialog__/getMetaData/getMetaData.vue'
    )
  },
  // exportValidate: {
  //   component: () => import(
  //     /* webpackChunkName: 'PublishPrintTemplate' */
  //     /* webpackMode: 'eager' */
  //     '../__customizeDialog__/exportValidate/exportValidate.vue'
  //   )
  // },
};
