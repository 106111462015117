var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "div",
        {
          ref: "container",
          class: [
            "container",
            { loginPro: _vm.type && _vm.typeToggle === 1 },
            { divErCode: _vm.type && _vm.typeToggle === 2 },
          ],
        },
        [
          _vm._t("logo"),
          _vm._v(" "),
          _c(
            "keep-alive",
            [
              !_vm.type || _vm.typeToggle === 1
                ? [
                    _c("AccountLogin", {
                      ref: "AccountLogin",
                      attrs: {
                        loginType: _vm.type,
                        TypeToggle: _vm.typeToggle,
                      },
                      on: { toggle: _vm.toggle },
                    }),
                  ]
                : [
                    _c("PhoneLogin", {
                      ref: "PhoneLogin",
                      attrs: {
                        phoneExp: _vm.phoneExp,
                        loginType: _vm.type,
                        TypeToggle: _vm.typeToggle,
                      },
                      on: { toggle: _vm.toggle },
                    }),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { on: { click: _vm.login } },
            [
              _vm.$slots.loginBtn ? [_vm._t("loginBtn")] : _vm._e(),
              _vm._v(" "),
              !_vm.$slots.loginBtn
                ? _c(
                    "Button",
                    { staticClass: "btn", attrs: { type: "text", id: "btn" } },
                    [_vm._v(_vm._s(_vm.$t("buttons.login")))]
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "Spin",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.spinShow,
                  expression: "spinShow",
                },
              ],
              attrs: { fix: "" },
            },
            [
              _c("div", { staticClass: "loader" }, [
                _c(
                  "svg",
                  {
                    staticClass: "circular",
                    attrs: { viewBox: "25 25 50 50" },
                  },
                  [
                    _c("circle", {
                      staticClass: "path",
                      attrs: {
                        fill: "none",
                        "stroke-width": "5",
                        "stroke-miterlimit": "10",
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("ChangeLang"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }