// 国际化
import Vue from 'vue';
import VueI18n from 'vue-i18n'
import zh from '@syman/burgeon-r3-locale/locale/zh-CN.json'
import { DispatchEvent } from '../../../__utils__/dispatchEvent';

if (!('$i18n' in Vue.prototype)) {
  Vue.use(VueI18n)
}

// 挂载r3语言包
if (!window.R3_lang) {
  window.R3_lang = {
    zh, // 兼容之前的。以后按zh-CN标准
    'zh-CN': zh, // 中文语言包
  }
}

const i18n = new VueI18n({
  locale: 'zh-CN', // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
  messages: window.R3_lang,
  silentTranslationWarn: true // 去掉警告
})
console.log('初始化语言')
// 以防万一出现实际问题，好用这个事件做处理
DispatchEvent('onR3I18nInit', {
  detail: {
    i18n
  }
})

export default i18n
