var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.prefixClass }, [
    _c("section", { staticClass: "field-page-header" }, [
      _c(
        "div",
        { staticClass: "field-page-header-l" },
        [
          _vm.hasTemplate
            ? _c(
                "arkButton",
                { staticClass: "config-btn", on: { click: _vm.saveAndApply } },
                [_vm._v(_vm._s(_vm.$t("fieldConfig.saveAndApply")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasTemplate
            ? _c(
                "arkButton",
                {
                  staticClass: "config-btn",
                  attrs: { type: "fcdefault" },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("fieldConfig.onlySave")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasTemplate
            ? _c(
                "arkButton",
                {
                  staticClass: "config-btn",
                  attrs: { type: "fcdefault" },
                  on: {
                    click: function ($event) {
                      return _vm.createTemplateDialog("saveAs")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("fieldConfig.saveAs")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "arkButton",
            {
              staticClass: "config-btn",
              attrs: { type: "fcdefault" },
              on: { click: _vm.add },
            },
            [_vm._v(_vm._s(_vm.$t("fieldConfig.increase")))]
          ),
          _vm._v(" "),
          _vm.hasTemplate
            ? _c(
                "arkButton",
                {
                  staticClass: "config-btn",
                  attrs: { type: "fcdefault" },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v(_vm._s(_vm.$t("fieldConfig.delete")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "arkButton",
            {
              staticClass: "config-btn",
              attrs: { type: "fcdefault" },
              on: { click: _vm.refresh },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.refresh")))]
          ),
          _vm._v(" "),
          _c(
            "arkButton",
            {
              staticClass: "config-btn",
              attrs: { type: "fcdefault" },
              on: { click: _vm.back },
            },
            [_vm._v(_vm._s(_vm.$t("fieldConfig.back")))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "field-page-header-r" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("fieldConfig.template")) + "：")]),
          _vm._v(" "),
          _c(
            "Select",
            {
              staticStyle: { width: "344px" },
              on: { "on-change": _vm.handleChange },
              model: {
                value: _vm.selectedTemplate,
                callback: function ($$v) {
                  _vm.selectedTemplate = $$v
                },
                expression: "selectedTemplate",
              },
            },
            _vm._l(_vm.templateList, function (item) {
              return _c(
                "Option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.hasTemplate
      ? _c(
          "section",
          { staticClass: "field-page-body" },
          [
            _c(
              "div",
              { staticClass: "config-wrap" },
              [
                _c(
                  "Tabs",
                  { attrs: { value: _vm.currentTab, animated: false } },
                  _vm._l(_vm.tabList, function (tab, index) {
                    return _c(
                      "TabPane",
                      {
                        key: index,
                        attrs: { label: tab.label, name: index.toString() },
                      },
                      [
                        _c("div", { staticClass: "config-content" }, [
                          _c("div", { staticClass: "config-panel" }, [
                            _c(
                              "div",
                              { staticClass: "fields-wrap" },
                              [
                                _c("FieldTree", {
                                  ref: "fieldTree",
                                  refInFor: true,
                                  attrs: { data: _vm.fieldTreeData },
                                  on: { "add-field": _vm.handleAddField },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: tab.type !== 2,
                                  expression: "tab.type !== 2",
                                },
                              ],
                              staticClass: "config-panel",
                            },
                            [
                              _c("div", { staticClass: "config-title" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("fieldConfig.listField")) +
                                    " （" +
                                    _vm._s(_vm.$t("fieldConfig.drag")) +
                                    "）"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "config-area" },
                                [
                                  _c("DragPanel", {
                                    attrs: { enableSort: "" },
                                    model: {
                                      value: _vm.visibleFields,
                                      callback: function ($$v) {
                                        _vm.visibleFields = $$v
                                      },
                                      expression: "visibleFields",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: tab.type !== 2,
                                  expression: "tab.type !== 2",
                                },
                              ],
                              staticClass: "config-panel",
                            },
                            [
                              _c("div", { staticClass: "config-title" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("fieldConfig.filterField")) +
                                    " （" +
                                    _vm._s(_vm.$t("fieldConfig.drag")) +
                                    "）"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "config-area" },
                                [
                                  _c("DragPanel", {
                                    model: {
                                      value: _vm.filterFields,
                                      callback: function ($$v) {
                                        _vm.filterFields = $$v
                                      },
                                      expression: "filterFields",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: tab.type === 2,
                                  expression: "tab.type === 2",
                                },
                              ],
                              staticClass: "config-panel",
                            },
                            [
                              _c("div", { staticClass: "config-title" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("fieldConfig.visibleFields"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "config-area" },
                                [_c("FoldTree")],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showLoading
              ? _c("Spin", { attrs: { size: "large", fix: "" } })
              : _vm._e(),
          ],
          1
        )
      : _c("section", { staticClass: "field-no-template" }, [
          _c("img", {
            attrs: { src: "/static/images/field-config-null.png", alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "add-tip" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("fieldConfig.addTipsOne")))]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "add-hl",
                on: {
                  click: function ($event) {
                    return _vm.createTemplateDialog("add")
                  },
                },
              },
              [_vm._v('"' + _vm._s(_vm.$t("buttons.add")) + '"')]
            ),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t("fieldConfig.addTipsTwo")))]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }