var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "Input",
        {
          attrs: {
            placeholder: _vm.placeholder,
            clearable: _vm.clearable,
            icon: "ios-search",
          },
          on: {
            "on-clear": _vm.searchInputClear,
            "on-change": _vm.searchInputChange,
          },
        },
        [
          _c("span", { attrs: { slot: "prepend" }, slot: "prepend" }, [
            _vm._v("检索"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menuContainer" },
        [
          _c(
            "Tree",
            _vm._g(
              _vm._b(
                { ref: "menuTree", attrs: { query: _vm.treeQuery } },
                "Tree",
                _vm.treeAttribute,
                false
              ),
              _vm.treeEvent
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }