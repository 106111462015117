var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes, attrs: { id: _vm.currentTableName } },
    [
      _c("TabPanels", {
        attrs: {
          type: "line",
          "is-keep-alive": "",
          "tab-margin-left": 20,
          "tab-panels": _vm.tabPanels,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }