/**
 * select组件的自定义逻辑处理
 */

 import SelectExclusive from '../form/SelectExclusive.vue'
 import { SetPlaceholder ,SetDisable} from './setProps';
 
 
 // 深拷贝
 
 class CustomSelectExclusive {
   constructor(item) {
     this.item = item;
     this.Vm = SelectExclusive;
     this.mergeProps();
     this.mergeMethods();
   }
 
   init() {
     return {
       Components:this.Vm,
       props:this.props
     };
 
   }
 
   // 合并props
   mergeProps() {
     this.settingOptions();
     this.props = {
       transfer:true,
       multiple:!this.item.detailType,
       clearable:true,
       chooseAll:true,
       options:this.item.props.options,
       keydata:this.item.keydata,
       placeholder:new SetPlaceholder(this.item).init(),
       disabled:new SetDisable(this.item).init() || false,
     }
 
   }
 
   // 合并methods
   mergeMethods() {
 
 
   }
 
 
   settingOptions() {
     if(!this.item.props){
       this.item.props = {}
     }
     if (this.item.combobox) {
       this.item.props.options = this.item.combobox.map((item) => {
         item.value = item.limitval;
         item.label = item.limitdesc;
         return item;
       });
     }
 
     // 合并类型字段
     if (this.item.conds) {
       const arr = [];
       this.item.conds.map((item) => {
         item.combobox.map((temp) => {
           temp.value = `${item.colname}|${temp.limitval}`;
           temp.label = temp.limitdesc;
           arr.push(temp);
           return temp;
         });
         return item;
       });
 
       this.item.props.options = arr;
     }
   }
 }
 
 export default CustomSelectExclusive;
 