var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", [
        !_vm.codeLoading
          ? _c("img", {
              staticClass: "codeimg",
              attrs: { src: _vm.imgSrc },
              on: { click: _vm.getCode },
            })
          : _c(
              "div",
              {
                staticClass: "codeimg",
                staticStyle: { "line-height": "inherit" },
              },
              [_c("Spin", { attrs: { fix: "" } })],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }