var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Drawer",
    _vm._b(
      {
        staticClass: "r3-drawer-iframe",
        attrs: { id: "r3-drawer-iframe" },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      },
      "Drawer",
      _vm.props,
      false
    ),
    [
      _c(_vm.props.components, { tag: "component" }),
      _vm._v(" "),
      _vm.url
        ? _c("iframe", {
            ref: "DrawerIframe",
            staticStyle: { height: "100%", width: "100%", border: "none" },
            attrs: {
              id: "DrawerIframe",
              "data-config": "btnBack=false",
              src: _vm.url,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }