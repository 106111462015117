var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm.isPageShow
        ? _c(
            "Page",
            _vm._b(
              {
                ref: "page",
                staticClass: "agPage",
                attrs: { size: "small" },
                on: {
                  "on-change": _vm.pageChange,
                  "on-page-size-change": _vm.pageSizeChange,
                },
              },
              "Page",
              _vm.pageAttribute,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isBig && _vm.bigBackground
        ? _c("div", { staticClass: "isBig" }, [
            _c("img", { attrs: { src: _vm.bigBackground } }),
            _vm._v(" "),
            _c("div", { staticClass: "big-data-tip" }, [
              _vm._v(_vm._s(_vm.$t("messages.bigDataTip"))),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isBig && !_vm.bigBackground
        ? _c("div", { staticClass: "isBig " }, [
            _c("div", { staticClass: "default-big-bg" }),
            _vm._v(" "),
            _c("div", { staticClass: "big-data-tip" }, [
              _vm._v(_vm._s(_vm.$t("messages.bigDataTip"))),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isCommonTable && !_vm.isBig && _vm.options
        ? _c("arkCommonTableByAgGrid", {
            ref: "agGridTableContainer",
            staticClass: "detailTable",
            attrs: {
              mode: "r3-list",
              r3ColumnRenderer: _vm.columnRenderer,
              columns: _vm.columns,
              data: _vm.rows,
              options: _vm.agOptions,
              height: "100%",
            },
            on: { "grid-ready": _vm.gridReady },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isCommonTable && !_vm.isBig
        ? _c(
            "div",
            { staticClass: "common-table" },
            [
              _c("CommonTable", {
                ref: "commonTable",
                attrs: {
                  datas: _vm.datas,
                  "buttons-data": _vm.buttonsData,
                  "is-big": _vm.isBig,
                  "css-status": _vm.cssStatus,
                  "error-arr": _vm.errorArr,
                  "on-row-double-click": _vm.onRowDoubleClick,
                  "on-sort-changed": _vm.onSortChanged,
                  "on-selection-changed": _vm.onSelectionChanged,
                  "on-row-single-click": _vm.onRowSingleClick,
                  "do-table-search": _vm.doTableSearch,
                },
                on: {
                  CustomizedDialog: _vm.customizedDialog,
                  btnclick: _vm.btnclick,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "queryDesc" }, [
        (_vm.legend.length > 0) & _vm.isLegendShow
          ? _c(
              "div",
              { staticClass: "legend" },
              [
                _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("tips.legend")) + ":\n      "
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.legend, function (item, index) {
                  return _c(
                    "p",
                    { key: index },
                    [
                      _c("span", [_vm._v(_vm._s(item.desc) + ": ")]),
                      _vm._v(" "),
                      _vm._l(item.value, function (temp, index2) {
                        return _c(
                          "button",
                          { key: index2, class: item.css[index2] },
                          [_vm._v("\n          " + _vm._s(temp) + "\n        ")]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }