/* eslint-disable import/no-dynamic-require */
<template>
  <!-- :show-tip="items.detailType" -->
  <ValidateCom :rules="rules"
               :labelWidth="labelWidth"
               :items="items"
               :colname="items.colname"
               :value="value">
    <div :class="_items.props.fkdisplay === 'pop' ? 'ItemComponentRoot AttachFilter-pop':'ItemComponentRoot'">
    <slot>
      <span class="itemLabel"
            :style="labelStyle"
            v-if="showLabel">
        <Poptip v-if="items.comment"
                word-wrap
                trigger="hover"
                transfer
                width="200"
                :content="items.comment">
          <div slot="content"
               class="api">
            <span><span>{{ items.comment }}</span>
              <a v-if="items.webconf && items.webconf.Outside"
                 style=""
                 :href="items.webconf.Outside"
                 target="_blank">...</a>
            </span>
          </div>
          <i class="iconfont iconios-information-circle-outline" />
        </Poptip>
        <span v-if="items.isnotnull"
              class="label-tip">*</span>
        <template v-if="getVersion() === '1.4' && items.fkobj && items.fkobj.fkdisplay === 'pop' && items.detailType">
          <!-- 路由跳转 -->
          <template v-if="value && value[0] && Array.isArray(value) && !items.onjump">
            <i class="iconfont iconbj_link"
               data-target-tag="fkIcon"
               style="color: #0f8ee9; cursor: pointer; font-size: 12px"
               @click="routerNext(value)" />
          </template>

        </template>
        <template v-if="getVersion() === '1.4' && items.fkobj && items.fkobj.fkdisplay === 'drp' && items.detailType">
          <!-- 路由跳转 -->
          <template v-if="value && value[0] && Array.isArray(value)  && !items.onjump">
            <i class="iconfont iconbj_link"
               data-target-tag="fkIcon"
               style="color: #0f8ee9; cursor: pointer; font-size: 12px"
               @click="routerNext(value)" />
          </template>
        </template>
        <span  :style="setlabelStyle" :title="items.coldesc">{{ items.coldesc }}:</span>
      </span>
      <div :class=" [_items.props.row >1 ? 'itemComponent height100':'itemComponent',items.isuppercase?'isuppercase':'']"
           :style="_items.display==='image' ? 'overflow:visible' :''">
        <component :is="componentsName"
                   :ref="items.colname"
                   v-bind="propsMessage"
                   v-model.trim="value"
                   @on-keydown="enterForm">
          <slot v-if="items.display === 'OBJ_SELECT'">
            <Option v-for="item in items.props.options"
                    :key="item.value"
                    :value="item.value"
                    :disabled="item.disabled">
              {{ item.label }}
            </Option>
          </slot>
        </component>
        <!-- 自定义组件 -->
        <component :is="_items.componentName"
                   v-if="_items.type === 'customization'"
                   :ref="items.colname"
                  v-model.trim="value"
                   v-bind="propsMessage"
                   :options="{
          ..._items,
          webConfSingle,
          index,
          formIndex,
        }" />
      </div>
      </slot>
      <div class="tip" v-html="items.tip" :title="items.tip"></div>
    </div>
  </ValidateCom>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import dataProp from '../../__config__/props.config';

import BusDropDownSelectFilter from '../inheritanceComponents/BusDropDownSelectFilter';
import CustomDropMultiSelectFilter from '../inheritanceComponents/DropMultiSelectFilter';
import CustomInput from '../inheritanceComponents/Input';
import CustomDatePicker from '../inheritanceComponents/DatePicker';
import CustomSelect from '../inheritanceComponents/Select';
import CustomAttachFilter from '../inheritanceComponents/AttachFilter';
import CustomPopAttachFilter from '../inheritanceComponents/PopAttachFilter';
import CustomCheckbox from '../inheritanceComponents/CheckBox';
import CustomImageUpload from '../inheritanceComponents/ImageUpload';
import CustomDocUpload from '../inheritanceComponents/DocUpload';
import CustomWangeditor from '../inheritanceComponents/Wangeditor';
import CustomEnumerableInput from '../inheritanceComponents/EnumerableInput';
import CustomExtensionProperty from '../inheritanceComponents/ExtensionProperty';
import CustomRadioGroup from '../inheritanceComponents/RadioGroup'
import CustomDefined from '../inheritanceComponents/Defined'
import CustomStringRender from '../inheritanceComponents/StringRender'
import CustomCheckboxGroup from '../inheritanceComponents/CheckboxGroup';
import CustomMonthDay from '../inheritanceComponents/MonthDay';
import CustomInputWithSelect from '../inheritanceComponents/inputWithSelect';
import CustomIconfontPicker from '../inheritanceComponents/IconfontPicker'
import CustomSwitch from '../inheritanceComponents/Switch';
import CustomSelectExclusive from '../inheritanceComponents/SelectExclusive';


import ParameterDataProcessing from './parameterDataProcessing';
import { Validate } from './PanelForm/Validate';

// 验证组件的插件
let ValidateCom = new Validate().init();
import {
  Version, MODULE_COMPONENT_NAME, ossRealtimeSave, defaultrange, setComponentsProps
} from '../../constants/global';
import createModal from '../PreviewPicture/index';
import getComponentName from '../../__utils__/getModuleName'
import i18n from '../../assets/js/i18n'



// const fkHttpRequest = () => require(`../../__config__/actions/version_${Version()}/formHttpRequest/fkHttpRequest.js`);

export default {
  components: {
    ValidateCom
  },
  // mixins: [mixins],
  // inject: [MODULE_COMPONENT_NAME],
  props: {
    webConfSingle: {// 当前子表webConf
      type: Object,
      default: () => ({})
    },
    labelWidth: {
      type: Number,
      default: 120
    },
    items: {
      type: Object,
      default () {
        return {};
      }
    },
    component: {
      type: Object,
      default () {
        return {};
      }
    },
    index: {
      type: Number,
      default () {
        return 0;
      }
    },
    formIndex: {
      type: Number,
      default () {
        return 0;
      }
    },
    type: {
      type: String,
      default () {
        return '';
      }
    }
  },
  data () {
    return {
      filterDate: {},
      resultData: {}, // 结果传值
      componentsName: '',// 组件名称
      show: false,// 是否展示报错内容
      setlabelStyle:'',
      propsMessage: {

      }, // 各个组件的props
      errorTip: '',
      value: '', //实时数据
    };
  },
  computed: {
    ...mapState('global', {
      activeTab: ({ activeTab }) => activeTab
    }),
    getVersion () {
      return Version;
    },
    labelStyle () {
      let style = '';
      style = `flex: 0 0 ${this.labelWidth}px;`;
      return style;
    },
    _items () {
      if (defaultrange()) {
        dataProp.DropDownSelectFilter.props.pageSize = defaultrange();
        dataProp.DropMultiSelectFilter.props.pageSize = defaultrange();
      }

      // 将设置的props和默认props进行assign
      const item = this.items;
      // const item = this.items;
      item.props = Object.assign(
        {},
        item.type ? dataProp[item.type] && dataProp[item.type].props : {},
        this.items.props
      );


      const placeholder = this.items.props.webconf && this.items.props.webconf.placeholder ? this.items.props.webconf.placeholder : null;
      item.props.placeholder = placeholder || `${(dataProp[item.type] && dataProp[item.type].props) ? dataProp[item.type].props.placeholder : this.$t('form.inputPlaceholder')}${item.title}`;


      if (item.type === 'docfile') {
        if (!Array.isArray(item.props.itemdata.valuedata)) {
          item.props.itemdata.valuedata = [];
        }
      }

      if (item.type === 'AttachFilter') {
        // 大弹窗卡槽页面
        if (item.props.fkdisplay === 'pop') {
          // item.componentType = myPopDialog;
          if (!item.props.disabled) {
            item.props.fkobj.show = false;
          }
        } else {
          // item.componentType = Dialog;
          if (!item.props.disabled) {
            item.props.fkobj.show = true;
            if (!item.props.datalist[0] || item.props.datalist[0].value !== this.$t('messages.moreFilters')) {
              item.props.datalist = dataProp[item.type].props.datalist.concat(
                item.props.datalist
              );
            }
          }


          item.props.dialog.model['footer-hide'] = false;
          item.props.datalist.forEach((option, i) => {
            if (option.value === this.$t('buttons.import')) {
              item.props.datalist[i].url = item.props.fkobj.url;
              item.props.datalist[i].sendData = {
                table: item.props.fkobj.reftable
              };
            }
          });
        }
      }
      // eslint-disable-next-line no-empty
      if (item.type === 'Wangeditor') {
        item.componentType = WangeditorVue;
      }

      if (item.type === 'input' && (item.props.webconf && item.props.webconf.display === 'YearMonth')) {
        item.type = 'DatePicker';
        item.props = Object.assign({}, item.props, {
          type: 'month',
          clearable: true
        });
      }
      item.event = Object.assign({}, this.items.event);

      return item;
    },
    filterList () {
      // 气泡选中过滤条件
      return this.filterDate;
    },

    showLabel () {
      if (this._items.webconf && this._items.webconf.hiddenLabel) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapMutations('global', ['tabOpen', 'addKeepAliveLabelMaps', 'addServiceIdMap']),

    enterForm (e,event) {
      if(this.items.fkobj && this.items.fkobj.searchmodel === 'mrp'){
        return;
      }
      if (event&& event.target) {
        // 兼容弹窗单选或者是弹窗多选回车事件
        const tagName = event.target.tagName.toLowerCase();
        if (tagName === 'input') {
          setTimeout(() => {
            this.$emit('on-keydown', event)
        },400)
        }
      }

      if (e && e.target) {
        const tagName = e.target.tagName.toLowerCase();
        if (tagName === 'input') {
          this.$emit('on-keydown', e)
        }
      }

    },

    inheritanceComponents () {
      let component = null;
      // 兼容webcof
      if (this.items.dynamicforcompute) {
        if (!this.items.webconf) {
          this.items.webconf = {
            dynamicforcompute: {}
          };
        } else {
          this.items.webconf.dynamicforcompute = {}
        }
        this.items.webconf.dynamicforcompute = this.items.dynamicforcompute;
      }
      // 兼容 definedType
      if( this.items.webconf && this.items.webconf.definedType){
        // popMultiSelect 内置框架的组件 
        if(this.items.webconf.definedType !=='popMultiSelect'){
          this.items.display ='defined'
        }
      }
  
      let item = this.items;

      let componentInstance = null
      switch (item.display) {
        case undefined:
        case 'none':
        case 'OBJ_TEXTAREA':
        case 'OBJ_DEFINED': // 列表的defined类型还没实现。先用input代替
          componentInstance = new CustomInput(item).init();
          break;
        case 'OBJ_FK':
          // 兼容webcof 数据
            ['refcolprem','refcolval'].forEach((option)=>{
              if(item[option]){
                if (!item.webconf) {
                  item.webconf = {}
                }
                item.webconf[option] = item[option];
              }
            })
          if (item.fkobj.searchmodel === 'drp') {
            componentInstance = new BusDropDownSelectFilter(item).init();
          }
          if (item.fkobj.searchmodel === 'mrp') {
            componentInstance = new CustomDropMultiSelectFilter(item).init();
          }
          if (item.fkobj.searchmodel === 'mop') {
            componentInstance = new CustomAttachFilter(item).init();
          }
          if (item.fkobj.searchmodel === 'pop') {
            componentInstance = new CustomPopAttachFilter(item).init();
          }
          break;
        case 'OBJ_DATE':
        case 'OBJ_DATENUMBER':
        case 'YearMonth':
        case 'OBJ_DATETIME':
        case 'OBJ_TIME':
          componentInstance = new CustomDatePicker(item).init();
          break;
        case 'OBJ_SELECT':
        case 'OBJ_SWITCH' :
        case 'RADIO_GROUP':
        case 'CHECKBOX_GROUP':
          // 列表界面把radio-group渲染成select
          // 列表界面把checkbox-group渲染成select
          // 列表界面把OBJ_SWITCH渲染成select
          const typeList = ['RADIO_GROUP', 'CHECKBOX_GROUP','OBJ_SWITCH']
          if (!item.detailType && typeList.includes(item.display)) {
            item.display = 'OBJ_SELECT'
          }
          componentInstance = new CustomSelect(item).init();
          break;
        case 'SelectExclusive':
          componentInstance = new CustomSelectExclusive(item).init();
          break; 
        case 'OBJ_CHECK':
          componentInstance = new CustomCheckbox(item).init();

          break; 
         case 'switch' :
           componentInstance = new CustomSwitch(item).init();
          break;  

        case 'image':
          componentInstance = new CustomImageUpload(item).init();
          break;
        case 'OBJ_DOC':
          componentInstance = new CustomDocUpload(item).init();
          break;
        case 'clob':
          componentInstance = new CustomWangeditor(item).init();
          break;
        case 'Enumerate':
          componentInstance = new CustomEnumerableInput(item).init();
          break;
        case 'ExtensionProperty':
          componentInstance = new CustomExtensionProperty(item).init();
          break;
        case 'radioGroup':
          componentInstance = new CustomRadioGroup(item).init();
          break;
        case 'checkboxgroup':
          componentInstance = new CustomCheckboxGroup(item).init();
          break;
        case 'MonthDay': 
          componentInstance = new CustomMonthDay(item).init();
          break;
        case 'String': 
          componentInstance = new CustomStringRender(item).init();
          break;
        case 'InputWithSelect':
          componentInstance = new CustomInputWithSelect(item).init();
          break;
        case 'defined':
          componentInstance = new CustomDefined(item).init();
          break;
        case 'iconfontpicker':
          componentInstance = new CustomIconfontPicker(item).init()
        default:
          break;
      }
      if (componentInstance) {
        component = componentInstance.Components || '';
        this.propsMessage = componentInstance.props || {};
        // 是否有外部配置
        this.propsMessage = Object.assign(
          this.propsMessage, (setComponentsProps())(this.items.display, this.propsMessage)
        );

      }

      return component;
    },
    onkeydown (e) {
      // 回车
      // this.$emit('on-keydown',e);

    },
    routerNext () {
      // 路由跳转
      const props = this.items;
      const type = props.objdisplay;
      const serviceId = props.serviceId;

      // console.log(this._items.props);
      const tableName = props.reftable;
      const tableId = props.reftableid;
      const label = props.fkdesc;
      // xhj修改，改为使用tabOpen方法，以下存serviceId逻辑已弃用

      let value = this.value;
      let id = 0;
      if (!props.readonly) {
        id = value[0].ID;
      } else {
        id = props.refobjid;
      }
      // xhj修改，改为根据接口返回的objdisplay类型跳转
      let types = '';
      if (type === 'tabpanle') { // 上下结构
        types = 'tableDetailHorizontal';
      } else if (type === 'object') {
        types = 'tableDetailVertical';
      } else {
        const data = {
          mask: true,
          title: this.$t('feedback.warning'),
          content: this.$t('messages.setAssociationTable')
        };
        this.$Modal.fcWarning(data);
        return;
      }
      window.sessionStorage.setItem('dynamicRoutingForHideBackButton', true);
      this.tabOpen({
        type: types,
        tableName,
        tableId,
        id,
        label,
        original: 'outclick',
        serviceId
      });
    },
    valueChange () {
      // 值发生改变时触发  只要是item中的value改变就触发该方法，是为了让父组件数据同步
      // console.log(this._items);
      this.value = this._items.value;
      // this.$emit('inputChange', this._items.value, this._items, this.index);
    },
    clearItem () {
      if (this._items.props.defaultSelected) {
        this._items.props.defaultSelected = [{
          ID: '',
          Label: ''
        }];
        this._items.value = this._items.props.defaultSelected;
      } else if (this._items.props.selected) {
        this._items.props.selected = [{
          ID: '',
          Label: ''
        }];
        this._items.value = '';
      } else if (this._items.type === 'select') {
        this._items.value = '';
      }
      this.valueChange();
    },
    setListenerSetProps (e) {
      if (e.value.type === 'equal') {
        // 表单赋值
        if (!Array.isArray(e.value.list)) {
          return false;
        }
        e.value.list.forEach((item) => {
          if (this._items.props.tableGetName !== e.value.tableName) {
            return false;
          }
          if (this._items.field === item.COLUMN_NAME || this._items.inputname === item.COLUMN_NAME) {
            if (e.value.key === this._items.field) {
              return false;
            }
            // 隐藏且配置了this._items.props.webconf
            // if (this._items.props.webconf && this._items.props.webconf.clearWhenHidden) {
            //   return false;
            // }
            if (item.COLUMN_TYPE === 0) {
              // 数组形式
              if (this._items.props.defaultSelected) {
                this._items.props.defaultSelected = [{
                  ID: item.LABLE_VALUES[0].VALUE || '',
                  Label: item.LABLE_VALUES[0].LABLE || ''
                }];
                this._items.props.refobjid = item.LABLE_VALUES[0].VALUE;
                this._items.value = this._items.props.defaultSelected;
              } else if (this._items.props.Selected) {
                this._items.props.Selected = [{
                  ID: item.LABLE_VALUES[0].VALUE || '',
                  Label: item.LABLE_VALUES[0].LABLE || ''
                }];
                this._items.props.refobjid = item.LABLE_VALUES[0].VALUE;
                this._items.value = item.LABLE_VALUES[0].LABLE;
              } else if (this._items.type === 'select') {
                this._items.value = item.LABLE_VALUES[0].VALUE || '';
              }
            } else if (item.COLUMN_TYPE === 1) {
              // INPUT
              if (this._items.type === 'checkbox') {
                this._items.value = item.LABLE_VALUES[0].VALUE || this._items.props.falseValue;
              } else {
                this._items.value = item.LABLE_VALUES[0].VALUE || '';
              }
            } else if (item.COLUMN_TYPE === 2) {
              const labelIput = [];
              this._items.props.defaultSelected = item.LABLE_VALUES.reduce((arr, options) => {
                if (options.VALUE) {
                  arr.push({
                    ID: options.VALUE || '',
                    Label: options.LABLE || ''
                  });
                  labelIput.push(options.LABLE);
                }

                return arr;
              }, []);
              this._items.value = this._items.props.defaultSelected;
            }
            this.valueChange();
          }
        });
      } else if (this._items.field === e.value.field) {
        // 表单修改属性
        if (!e.value.tableGetName) {
          e.value.tableGetName = '';
        }

        if (this._items.props.tableGetName !== e.value.tableGetName) {
          return false;
        }

        this._items.required = e.value.required;
        if (e.value.regx) {
          this._items.props.regx = e.value.regx;
        }
        // this._items.props = Object.assign(this._items.props, e.value.props);
        this._items.props.disabled = e.value.props.disabled;
        this._items.props.readonly = e.value.props.disabled;
        if (e.value.props.display === 'doc' || e.value.props.display === 'image') {
          this._items.props.itemdata.disabled = e.value.props.disabled;
          this._items.props.itemdata.readonly = e.value.props.disabled;
        }
      }
      return true;
    },
    setListenerSetLinkForm (e) {
      // 设置表单联动清空
      if (Object.hasOwnProperty.call(this._items.validate, 'refcolval')) {
        if (this._items.validate.refcolval.srccol === e.value.key) {
          if (e.value.tableName) {
            if (!this._items.validate.refcolval.maintable && this._items.props.tableGetName === e.value.tableName) {
              this.clearItem();
            }
          } else if (this._items.props.tableGetName === '' || this._items.validate.refcolval.maintable) {
            this.clearItem();
          }
        }
      }
    },
    setListenerSetHideForm (e) {
      const index = e.value.list.findIndex(x => x === this._items.field);

      if (index !== -1 && e.value.tableName === this._items.props.tableGetName) {
        setTimeout(() => {
          this.$parent.hidecolumn(this._items, this.index, e.value.data, 'mounted');
        }, 10);
      }
    },
    setListenerDynam (e) {
      if (this._items.props.colname === e.value.dynamicforcompute.computecolumn) {
        setTimeout(() => {
          this.$parent.dynamicforcompute(e.value);
        }, 10);
      }
    },

    uploadFileDblclick (array = [], index) { // 图片预览双击
      const { itemId } = this.$route.params;
      const obj = {
        field: `${this.items.field}_${itemId}`
      };
      createModal(array, obj, index);
    },

    resetItem () {
      this.value = new ParameterDataProcessing(JSON.parse(JSON.stringify(this.items))).defaultDataProcessing();
    },
    getLable () {
      return new ParameterDataProcessing(JSON.parse(JSON.stringify(this.items)), this.value).getLable();
    },

    nextInputFocus(currentWrapDom, e) {
      const keyCode = e.keyCode
      // 当前节点是textarea就不触发后面的逻辑。而是直接换行
      if (currentWrapDom) {
        const textarea = currentWrapDom.querySelector('textarea')
        if (textarea && keyCode === 13) {
          return
        }
      }

      const nextItemDom = currentWrapDom.nextElementSibling // 下一个兄弟节点
      console.log("🚀 ~ file: FormItem.vue ~ line 649 ~ nextInputFocus ~ nextItemDom", nextItemDom)
      if (!nextItemDom) {
        return
      }

      // 如果该表单隐藏，或者占位隐藏也继续查找
      const itemDom = nextItemDom.querySelector('.validate_item')
      if(nextItemDom.style.display === 'none' || itemDom.style.display === 'none') {
        this.nextInputFocus(nextItemDom, e)
        return
      }

      // 按tab键时需要激活select
      if(keyCode === 9) {
        const isDisabled = nextItemDom.querySelector('.ark-select-disabled') ? true : false
        const selectDom = nextItemDom.querySelector('.ark-select-selection')
        if(selectDom && !isDisabled) {
          selectDom.focus()
          e.preventDefault()
          return
        }
      }
      
      let inputDom = nextItemDom.querySelector('.ark-input')
      if(!inputDom){
        this.nextInputFocus(nextItemDom, e)
        return
      } 
      if (
        inputDom.disabled ||
        inputDom.type === 'checkbox'
      ) {
        // 继续查找下个节点
        this.nextInputFocus(nextItemDom, e)
      } else {
        inputDom.focus()
        e.preventDefault()
      }
    },

    handleKeyDown(e){
      const keyCode = e.keyCode
      // 回车切换下一个获取光标
      // 子表有回车保存。所以不需要切换光标
      if(keyCode === 13 && this.items.showPlace !== 'childrenForm'){
        this.nextInputFocus(this.$el.parentElement, e)
      } else if(keyCode === 9){
        // tab切换下一个获取光标
        this.$el.click() // 为了让一些含下拉弹框的组件，关闭弹框。主动触发下click失去焦点
        this.nextInputFocus(this.$el.parentElement, e)
      }
    },

    bindKeyDownEvent() {
      this.$el.addEventListener('keydown', this.handleKeyDown)
      this.$once('hook:beforeDestroy', () => {
        this.$el.removeEventListener('keydown', this.handleKeyDown)
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener(`${this.moduleComponentName}setProps`, this.setListenerSetProps);
    window.removeEventListener(`${this.moduleComponentName}setLinkForm`, this.setListenerSetLinkForm);
    window.removeEventListener(`${this.moduleComponentName}setHideForm`, this.setListenerSetHideForm);
    window.removeEventListener(`${this.moduleComponentName}Dynam`, this.setListenerDynam);
  },
  created () {
    this.$t = i18n.t.bind(i18n)
    this[MODULE_COMPONENT_NAME] = getComponentName()
    this.componentsName = this.inheritanceComponents();
  },
  mounted () {
    // window.addEventListener(`${this.moduleComponentName}setProps`, this.setListenerSetProps);
    // window.addEventListener(`${this.moduleComponentName}setLinkForm`, this.setListenerSetLinkForm);
    // window.addEventListener(`${this.moduleComponentName}setHideForm`, this.setListenerSetHideForm);
    // window.addEventListener(`${this.moduleComponentName}Dynam`, this.setListenerDynam);
    this.resetItem()
    if(this.items.detailType) {
      this.bindKeyDownEvent()
    }
  }
};
</script>
