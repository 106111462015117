var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showChangeLang
    ? _c(
        "Select",
        {
          staticClass: "changeLang",
          attrs: {
            placeholder:
              _vm.lang === "zh-CN" ? "请选择语言" : "Please select a language",
          },
          on: { "on-change": _vm.changeLang },
          model: {
            value: _vm.lang,
            callback: function ($$v) {
              _vm.lang = $$v
            },
            expression: "lang",
          },
        },
        _vm._l(_vm.langs, function (item) {
          return _c(
            "Option",
            { key: item.value, attrs: { value: item.value } },
            [_vm._v(_vm._s(item.key))]
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }