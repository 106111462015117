var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "r3-update-progress " }, [
    _c("div", { staticClass: "preloader", attrs: { id: "loader-wrapper" } }, [
      _c("div", { staticClass: "preloader__status" }, [
        _c(
          "div",
          { staticClass: "preloader__status-text", attrs: { status: "" } },
          [
            _vm._v("版本更新中 "),
            _c("span", { staticClass: "per" }, [_vm._v(_vm._s(_vm.loaded))]),
            _vm._v("%"),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "preloader__status-loader" }, [
          _c("div", {
            staticClass: "preloader__status-bar",
            style: _vm.loadStyle,
            attrs: { loader: "" },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "r3-loading" }, [
      _c("div", [_vm._v(" L ")]),
      _vm._v(" "),
      _c("div", [_vm._v(" O ")]),
      _vm._v(" "),
      _c("div", [_vm._v(" A ")]),
      _vm._v(" "),
      _c("div", [_vm._v(" D ")]),
      _vm._v(" "),
      _c("div", [_vm._v(" I ")]),
      _vm._v(" "),
      _c("div", [_vm._v(" N ")]),
      _vm._v(" "),
      _c("div", [_vm._v(" G ")]),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }