var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.titleName,
        "title-align": _vm.titleAlign,
        scrollable: _vm.scrollable,
        closable: _vm.closable,
        draggable: _vm.draggable,
        mask: _vm.mask,
        "action-id": _vm.actionId,
        "mask-closable": _vm.maskClosable,
        transfer: _vm.transfer,
        "footer-hide": _vm.footerHide,
        "ok-text": _vm.okText,
        "cancel-text": _vm.cancelText,
        width: _vm.modalWidth,
        "class-name": "ark-modal",
        styles: _vm.styles,
        className: _vm.className,
      },
      on: { "on-cancel": _vm.onCancel, "on-ok": _vm.onOk },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _vm.contentText
        ? _c("p", [_vm._v("\n    " + _vm._s(_vm.contentText) + "\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogComponentName
        ? _c(
            "div",
            [
              _vm.showModal
                ? _c(
                    _vm.dialogComponentName,
                    _vm._g(
                      _vm._b(
                        {
                          ref: "modalComponent",
                          tag: "component",
                          attrs: {
                            "id-array": _vm.idArray,
                            "obj-list": _vm.objList,
                            "action-id": _vm.actionId,
                            "select-row-data": _vm.selectRowData,
                            "item-id": _vm.itemId,
                            "popwin-message": _vm.popwinMessage,
                            saveDialog: _vm.saveDialog,
                            "obj-tab-action-dialog-config":
                              _vm.objTabActionDialogConfig,
                            externalOptions: _vm.externalOptions,
                          },
                          on: {
                            setTitle: _vm.setTitle,
                            closeActionDialog: _vm.closeActionDialog,
                            clearSelectIdArray: _vm.clearSelectIdArray,
                            setValue: _vm.setValue,
                            ok: _vm.componentClickOk,
                            cancel: _vm.close,
                          },
                        },
                        "component",
                        _vm.dynamicAttrs,
                        false
                      ),
                      _vm.dynamicEvents
                    )
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }