var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c(
      "div",
      { staticStyle: { margin: "15px" } },
      [
        _c("Button", { on: { click: _vm.doQuery } }, [
          _vm._v("\n      刷新\n    "),
        ]),
        _vm._v(" "),
        _c("Button", { on: { click: _vm.doEmpty } }, [
          _vm._v("\n      清空所有数据\n    "),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "margin-bottom": "15px" } },
      [
        _c("Page", {
          ref: "page",
          attrs: {
            size: "small",
            total: _vm.data.length,
            "show-total": "",
            "show-elevator": "",
            "show-sizer": "",
          },
          on: {
            "on-change": _vm.pageChange,
            "on-page-size-change": _vm.pageSizeChange,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tableWrapper" },
      [
        _vm.enableNetworkMonitor
          ? _c("Table", {
              attrs: {
                size: "small",
                columns: _vm.columns,
                data: _vm.viewData,
                "disabled-hover": "",
                height: true,
              },
            })
          : _c("div", [
              _c("h4", [
                _vm._v(
                  "当前系统未启动网络监视器功能，请联系开发人员开启此功能！"
                ),
              ]),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }