var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Drawer",
    {
      attrs: { closable: false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("SetPanel", {
        attrs: { panel: _vm.setPanel },
        on: { changePwdBox: _vm.changePwdBox },
      }),
      _vm._v(" "),
      _c("Dialog", {
        ref: "dialogRef",
        attrs: {
          title: _vm.dialogConfig.title,
          mask: _vm.dialogConfig.mask,
          "content-text": _vm.dialogConfig.contentText,
          "footer-hide": _vm.dialogConfig.footerHide,
          confirm: _vm.dialogConfig.confirm,
          "dialog-component-name": _vm.dialogComponentName,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }