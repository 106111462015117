var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "movebox " + _vm.className }, [
    _c("div", { staticClass: "movego" }),
    _vm._v(" "),
    _c("div", { staticClass: "txt", attrs: { id: "txt" } }, [
      _vm._v(_vm._s(_vm.$t("tips.loginVerification"))),
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "move",
          rawName: "v-move",
          value: _vm.moveend,
          expression: "moveend",
        },
      ],
      staticClass: "move moveBefore",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }