<template>
  <div class="content_wrap">
    <Input
      id="key"
      v-model="inputValue"
      :placeholder="placeholder"
      class="input"
      icon="ios-search"
      @on-click="search"
      @on-change="change"
      @on-enter="search"
      :disabled="disabledSearch"
    />
    <p class="orange" v-if="showTip && inputValue.length>0">"{{inputValue}}"{{Notice}}</p>
    <div v-show="zNodes.length === 0" class="no-tree-wrap">
      <img :src="treeImg" alt="" v-if="treeImg">
      <div class="no-tree-data" v-else>
        <div class="no-tree-data-bg"></div>
      </div>
      <div
          class="no-tree-tip"
          style="margin-top: 30px;text-align: center;"
        >{{$t('tips.noData')}}</div>
    </div>
    <div class="zTreeDemoBackground left" v-show="zNodes.length > 0">
      <ul
        :id="tableName"
        class="ztree"
      />
    </div>
  </div>
</template>

<script>
  import { fuzzySearch } from '../../static/js/ztree/fuzzysearch';
  import { mapState } from 'vuex';

  export default {
    name: 'ZTree',
    data() {
      return {
        inputValue: '',
        tableName: 'treeDemo',
        showTip:false,
        // setting: {
        //   check: {
        //     enable: false// checkbox
        //   },
        //   view: {
        //     selectedMulti: false,
        //     showIcon: false,
        //     nameIsHTML: true,
        //     dblClickExpand: false,
        //     // showLine: false,
        //     // fontCss: this.setFontCss
        //   },
        //   callback: {
        //     beforeClick: this.beforeClick,
        //     onClick: this.onClick
        //   },
        //   edit: {
        //     enable: false,
        //     editNameSelectAll: false
        //   },
        //   data: {
        //     key: {
        //       children: 'CHILDREN',
        //       name: 'NAME',
        //     },
        //     simpleData: {
        //       enable: true,
        //       idKey: 'ID', // 树节点ID名称
        //       pIdKey: 'PARENT_ID', // 父节点ID名称
        //     // rootPId: -1,//根节点ID
        //     }
        //   },
        // },
        isClick: false,
        treeId: '',
        searchNoData: false,
        zTreeObj: undefined
      };
    },

    computed: {
      setting() {
        const defalutSetting=  {
          check: {
            enable: false// checkbox
          },
          view: {
            selectedMulti: false,
            showIcon: false,
            nameIsHTML: true,
            dblClickExpand: false,
            // showLine: false,
            // fontCss: this.setFontCss
          },
          callback: {
            beforeClick: this.beforeClick,
            onClick: this.onClick
          },
          edit: {
            enable: false,
            editNameSelectAll: false
          },
          data: {
            key: {
              children: 'CHILDREN',
              name: 'NAME',
            },
            simpleData: {
              enable: true,
              idKey: 'ID', // 树节点ID名称
              pIdKey: 'PARENT_ID', // 父节点ID名称
            // rootPId: -1,//根节点ID
            }
          },
        }
        const result = Object.assign(defalutSetting, this.treeSetting)
        return result
      },

      ...mapState('global', {
        treeImg: state => state.imgSrc.treeImg,
      }),
    },

    watch: {
      zNodes: {
        handler() {
          this.zTreeObj = $.fn.zTree.init($(`#${this.tableName}`), this.setting, this.zNodes);
        },
        deep: true
      },
      // isChangeTreeConfigData: {
      //   handler(val) {
      //     debugger;
      //     if (val === 'Y') {
      //       this.inputValue = '';
      //       this.search();
      //       // this.$emit('changeTreeConfigData', '');
      //     }
      //   }
      // },
    },

    props: {
      // isChangeTreeConfigData: {
      //   type: String,
      //   default: ''
      // },
      placeholder: {// 设置查询框placeholder
        type: String,
        default: function(){return this.$t('messages.enterRole')}
      },
      Notice:{
        type: String,
        default: function(){return this.$t('messages.matchFail')}
      },
      treeDatas: {
        type: Function,
        default: () => {}
      },
      zNodes: {
        type: Array,
        default: () => []
      },
      // 树配置
      treeSetting: {
        type: Object,
        default: () => ({})
      },
      // 搜索回调
      customizedSearch: {
        type: Function
      },
      // 禁用搜索
      disabledSearch: {
        type: Boolean
      },
      enterDefault:{  // 树的查询过滤为空的时候是否默认选中
        type: Boolean,
        default:false
      }
    },
    methods: {

      // this.$emit('clickTreeNode', arr, treeNode.ID, true);
      // arr:当前点击的节点以及全部子节点
      // treeNode.ID:当前点击节点ID
      // 第三个参数为true:需查询当前点击节点以及全部子节点，为false:查空值


      // setFontCss(treeId, treeNode) {
      //   return { color: 'red' };
      // },
      // hasClass(element, cls) { // 判断当前节点是否包含需要添加的class
      //   return (` ${element.className} `).indexOf(` ${cls} `) > -1;
      // },
      // beforeClick(treeId, treeNode, clickFlag) {
      //   if (this.treeId === treeNode.tId) {
      //     const treeObj = $.fn.zTree.getZTreeObj('treeDemo');
      //     const nodes = treeObj.getNodes();
      //     if (nodes.length > 0) {
      //       nodes[0].name = '夏';
      //       nodes[0].isHover = false;

      //       console.log(111, nodes[0].name, nodes[0].isHover);

      //       treeObj.updateNode(nodes[0]);
      //     }
      //   }
      //   this.treeId = treeNode.tId;

      //   return true;
      // },
      init() {
        this.zTreeObj = $.fn.zTree.init($(`#${this.tableName}`), this.setting, this.zNodes);
      },
      checkNode() {
        // 选中
        const treeObj = $.fn.zTree.getZTreeObj(`${this.tableName}`);
        if (JSON.stringify(this.treeNode) !== '{}' && this.treeNode) {
          const node = treeObj.getNodeByTId(this.treeNode.tId);
          if (node) {
            treeObj.selectNode(node);
          }
        } else if (treeObj) {
          treeObj.refresh();
        }
      },
      onClick(e, treeId, treeNode) {
        const arr = [];
        this.treeNode = treeNode;
        if (this.treeId === treeNode.tId) {
          this.isClick = !this.isClick;
          if (this.isClick) { // 取消选中查空
            const treeObj = $.fn.zTree.getZTreeObj(`${this.tableName}`);
            treeObj.refresh();// 取消选中
            this.treeNode = {};
            
            this.$emit('clickTreeNode', arr, treeNode.ID, false);
          } else {
            arr.push(treeNode);
            this.$emit('clickTreeNode', arr, treeNode.ID, true);
          }
        } else {
          this.isClick = false;
          arr.push(treeNode);
          this.$emit('clickTreeNode', arr, treeNode.ID, true);
        }
        this.treeId = treeNode.tId;
      },
      isNull(str) {
        if (str === '') return true;
        const regu = '^[ ]+$';
        const re = new RegExp(regu);
        return re.test(str);
      },
      change(){
          this.showTip = false;
      },
      search() {
        if(this.customizedSearch) {
          this.customizedSearch(this.inputValue, this.zTreeObj)
          return
        }
        const isNull = this.isNull(this.inputValue);
        if (!isNull) {
          let checkoutZtree = fuzzySearch(`${this.tableName}`, this.inputValue, null, true); // 初始化模糊搜索方法
          checkoutZtree.then((res)=>{
            console.log(res,'====');
            if(res.length>0){
              this.showTip = false;
              this.searchNoData = false;
            }else{
              this.showTip = true;
              this.searchNoData = true;
              //this.expandAll();
            }
            this.$emit('treeSearch', this.inputValue, this.searchNoData)
          })
        } else {
          this.showTip = false;
          this.expandAll();
           if( this.treeNode  && this.enterDefault){
              // 空值查询应该还原之前的选中条件
              let tableName = this.$route.params.tableName;
              var treeObj = $.fn.zTree.getZTreeObj(`${tableName}treeDemo`);
              treeObj.selectNode(this.treeNode);
              this.isClick = false;
              this.treeId = this.treeNode.tId;
            }
          this.$emit('treeSearch', this.inputValue, this.searchNoData)
        }
      },
      expandAll() {
        // fuzzySearch('treeDemo','', null, false); // 初始化模糊搜索方法
        this.zTreeObj = $.fn.zTree.init($(`#${this.tableName}`), this.setting, this.zNodes);
        this.treeId = '';
        // const treeObj = $.fn.zTree.getZTreeObj('treeDemo');
        // treeObj.refresh();// 取消选中
      },
      callMethod() { // 重置
        this.inputValue = '';
        this.search();
      },
      clearInputVal() {
        this.inputValue = '';
        // this.search();
      },
      // freshArea() {
      //   $.fn.zTree.init($('#treeDemo'), this.setting, this.zNodes);
      // }
    },
    created() {
      this.tableName = this.$route.params.tableName ? `${this.$route.params.tableName}treeDemo` : 'treeDemo';
      if(this.$route.params.customizedModuleName){
        this.tableName = `${this.$route.params.customizedModuleName}treeDemo`;
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init();
      });

      // $(document).ready(() => {
      //   $.fn.zTree.init($('#treeDemo'), this.setting, this.zNodes);
      //   fuzzySearch('treeDemo', '#key', null, true); // 初始化模糊搜索方法
      // });
    }
  };
</script>
