var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Drawer",
    {
      attrs: { closable: false },
      model: {
        value: _vm.messagePanel.show,
        callback: function ($$v) {
          _vm.$set(_vm.messagePanel, "show", $$v)
        },
        expression: "messagePanel.show",
      },
    },
    [
      _vm.Version === "1.4"
        ? _c("messagePanel", {
            attrs: { panel: _vm.messagePanel },
            on: {
              markRead: _vm.markReadNote,
              ignoreMsg: _vm.ignoreMsg,
              jumpTask: _vm.jumpTask,
              nextPage: _vm.nextPage,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.Version === "1.3"
        ? _c("message-panel-older", {
            attrs: { panel: _vm.messagePanel },
            on: {
              markRead: _vm.markReadNote,
              ignoreMsg: _vm.ignoreMsg,
              jumpTask: _vm.jumpTask,
              nextPage: _vm.nextPage,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }