var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "panelForm",
        _vm._b(
          {
            ref: "panelForm",
            staticClass: "R3-childrenForm",
            attrs: {
              CollapseName: _vm.CollapseName,
              tableName: _vm.tableName,
              showPlace: _vm.name,
              defaultData: _vm.formList,
            },
            on: { "on-keydown": _vm.enterForm },
          },
          "panelForm",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }