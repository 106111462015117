var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "div",
        {
          class: _vm.classes,
          style: { width: _vm.collapseHistoryAndFavorite ? "50px" : "180px" },
        },
        [
          _c("div", { staticClass: "history-favorite-container" }, [
            _c(
              "div",
              {
                staticClass: "favorite",
                on: {
                  mouseenter: _vm.onMouseOverFavoriteSeen,
                  mouseleave: _vm.onMoueOutFavoriteSeen,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "label",
                    class: { collapse: _vm.collapseHistoryAndFavorite },
                    on: {
                      click: function ($event) {
                        _vm.collapseFavorite = !_vm.collapseFavorite
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "iconfont iconbj_col left-icon" }),
                    _vm._v(" "),
                    !_vm.collapseHistoryAndFavorite
                      ? _c("span", [
                          _c("span", { staticClass: "left-favorites" }, [
                            _vm._v(_vm._s(_vm.$t("tips.favorites"))),
                          ]),
                          _vm._v(" "),
                          !_vm.collapseFavorite
                            ? _c("i", {
                                staticClass:
                                  "iconfont iconios-arrow-up arrow-icon",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.collapseFavorite
                            ? _c("i", {
                                staticClass:
                                  "iconfont iconios-arrow-down arrow-icon",
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("transition", [
                  (!_vm.collapseFavorite && !_vm.collapseHistoryAndFavorite) ||
                  _vm.favoriteSeen
                    ? _c(
                        "ul",
                        { class: { onMouseOverShow: _vm.favoriteSeen } },
                        _vm._l(_vm.favorite, function (d, i) {
                          return _c(
                            "li",
                            {
                              key: "favorite-" + i,
                              attrs: { title: d.label },
                              on: {
                                click: function ($event) {
                                  return _vm.routeTo(d)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(d.label) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "history",
                on: {
                  mouseenter: _vm.onMouseOverHistorySeen,
                  mouseleave: _vm.onMoueOuthHistorySeen,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "label",
                    class: { collapse: _vm.collapseHistoryAndFavorite },
                    on: {
                      click: function ($event) {
                        _vm.collapseHistory = !_vm.collapseHistory
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "iconfont iconbj_record left-icon",
                    }),
                    _vm._v(" "),
                    !_vm.collapseHistoryAndFavorite
                      ? _c("span", [
                          _c("span", { staticClass: "left-favorites" }, [
                            _vm._v(_vm._s(_vm.$t("tips.recentlyUsed"))),
                          ]),
                          _vm._v(" "),
                          !_vm.collapseHistory
                            ? _c("i", {
                                staticClass:
                                  "iconfont iconios-arrow-up arrow-icon",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.collapseHistory
                            ? _c("i", {
                                staticClass:
                                  "iconfont iconios-arrow-down arrow-icon",
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("transition", [
                  (!_vm.collapseHistory && !_vm.collapseHistoryAndFavorite) ||
                  _vm.historySeen
                    ? _c(
                        "ul",
                        { class: { onMouseOverShow: _vm.historySeen } },
                        _vm._l(_vm.history, function (d, i) {
                          return _c(
                            "li",
                            {
                              key: "history-" + i,
                              attrs: { title: d.label },
                              on: {
                                click: function ($event) {
                                  return _vm.routeTo(d)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(d.label) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }