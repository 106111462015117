var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.componentName, {
    tag: "component",
    attrs: { options: _vm.items },
    on: { change: _vm.onChange, save: _vm.onSave },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }