<template>
  <div
    class="dispalyArea"
    :data-keep-alive="keepAliveContainer"
  >
    <!-- 模块页签组件 -->
    <TabLists v-show="enableTabLists&&openedMenuLists.length>0&&!flag && !displayAreas" />
    <div
      v-show="(openedMenuLists.length>0&&!flag)||!getDashboardConfig"
      id="content"
      style="height: 100%; padding: 0 15px 0;overflow: auto;position: relative"
    >
      <keep-alive :include="keepAliveContainer">
        <router-view />
      </keep-alive>
    </div>
    <div
      v-show="getDashboardConfig&&flag"
      id="content"
      style="height: 100%; padding: 0 15px 0;overflow: auto;position: relative"
    >
      <component
        :is="getDashboardConfig.compontent"
        v-if="getDashboardConfig&&getDashboardConfig.compontent && display"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import TabLists from './TabLists';
  import { enableTabLists, dashboardConfig } from '../constants/global';
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        enableTabLists: true,
        display:false, // 控制台是否初始化显示
        flag: false,
        displayAreas:false
      };
    },
    name: 'ContentDisplayArea',
    methods:{
      ...mapMutations('global', ['updateDashboardPageValue']),

    },
    watch: {
      $route(to) {
        if (to.path === '/') {
          this.flag = true;
          this.display = true;
        } else {
          if(to.path ==='/Noaccess'){
            // 无权限界面
            this.flag = true;
          }else{
           this.flag = false;
          }
        }
      },
      isShowDashboardPage(val) {
        if (val) {
          this.flag = true;
          this.display = true;
        } else {
          this.flag = false;
        }
      }
    },
    mounted() {
      // 是否iframe 嵌套
       this.displayAreas = window.ProjectConfig.displayAreas;

      if (this.$router.currentRoute.path === '/') {
        this.flag = true;
        this.display = true;
      }else{
        this.display = false;
      }

    },
    beforeCreate(){
        
    },
    computed: {
      ...mapState('global', ['keepAliveLists', 'openedMenuLists', 'isShowDashboardPage']),

      keepAliveContainer() {
        return Object.keys(this.keepAliveLists.map(d => d.split('.')[0]).reduce((a, c) => { a[c] = true; return a; }, {})).map(d => `${d}.Table.KeepAlive`);
      },
      getDashboardConfig() {
        if (dashboardConfig()) {
          return dashboardConfig();
        }
        return null;
      },
    },
    components: {
      TabLists
    },
    beforeMount() {
      this.enableTabLists = enableTabLists();
    }
  };
</script>
