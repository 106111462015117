var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%" } }, [
    _vm.urlName
      ? _c("iframe", {
          class: _vm.urlNameCls,
          attrs: { id: "iframe", src: _vm.urlName },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }