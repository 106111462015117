var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("div", { staticClass: "left_container" }, [
      _c(
        "div",
        { ref: "leftTable", staticClass: "left_table" },
        [
          _c("Table", {
            attrs: {
              columns: _vm.leftTableColumns,
              height: _vm.leftTableHeight,
              data: _vm.leftTableData,
              "highlight-row": "",
            },
            on: {
              "on-row-click": _vm.leftTableRowClick,
              "on-row-dblclick": _vm.rightSingle,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "center_container" }, [
      _c("div", { staticClass: "right_single" }, [
        _c("i", {
          staticClass: "iconfont iconbj_right",
          on: {
            click: function ($event) {
              return _vm.rightSingle()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "right_double" }, [
        _c("i", {
          staticClass: "iconfont iconbj_transfer_right",
          on: {
            click: function ($event) {
              return _vm.rightDouble()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "left_single" }, [
        _c("i", {
          staticClass: "iconfont iconbj_left",
          on: {
            click: function ($event) {
              return _vm.leftSingle()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "left_double" }, [
        _c("i", {
          staticClass: "iconfont iconbj_transfer_left",
          on: {
            click: function ($event) {
              return _vm.leftDouble()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "right_container" }, [
      _c(
        "div",
        { ref: "rightTable", staticClass: "right_table" },
        [
          _c("Table", {
            attrs: {
              columns: _vm.rightTableColumns,
              height: _vm.rightTableHeight,
              data: _vm.rightTableData,
              "highlight-row": "",
            },
            on: {
              "on-row-click": _vm.rightTableRowClick,
              "on-row-dblclick": _vm.leftSingle,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }