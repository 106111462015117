<template>
  <div :class="classes">
    <Table
      ref="table"
      :columns="columns"
      class="table"
      :height="true"
      :multiple="multiple"
      :multipleRow="multipleRow"
      :data="tableData"
      :highlight-row="highlightRow"
      :row-class-name="rowClassName"
      :total-data="tableTotalData"
      @on-selection-change="tableSelectionChange"
      @on-row-dblclick="tableRowDbclick"
      @on-row-click="tableRowClick"
      @on-sort-change="tableSortChange"
      @on-select-all="tableSelectionChange"
    />
    <Spin
      v-show="spinShow"
      class="table-spin-fix"
      fix
    >
      <Icon
        type="ios-loading"
        size="48"
        class="demo-spin-icon-load"
      />
      <div>Loading</div>
    </Spin>
    <Dialog
      ref="dialogRef"
      :title="dialogConfig.title"
      :mask="dialogConfig.mask"
      :content-text="dialogConfig.contentText"
      :footer-hide="dialogConfig.footerHide"
      :confirm="dialogConfig.confirm"
      :dialog-component-name="dialogComponentName"
    />
  </div>
</template>

<script>
  /* eslint-disable no-lonely-if */

  import { mapMutations } from 'vuex';
  import Vue from 'vue';
  import Dialog from './Dialog.vue';
  import ButtonComponent from './tableButtons';

  import network from '../__utils__/network';
  import store from '../__config__/store.config';
  import DispatchEvent from '../__utils__/dispatchEvent';
  import createModal from './PreviewPicture/index.js';

  import { classFix } from '../constants/global';

  Vue.component('buttons', Vue.extend(ButtonComponent));
  export default {
    data() {
      return {
        multiple:true,
        highlightRow:false,
        multipleRow:false,
        dataArray: {
          refresh: true, // 显示刷新
          back: true, // 显示返回
          temporaryStorage: false, // 显示暂存
          printValue: false, // 是否显示打印
          actionCollection: false,
          collectiImg: false, // 是否收藏
          waListButtonsConfig: {// 自定义按钮
            waListButtons: []
          },
          buttonGroupShowConfig: {// 标准按钮
            buttonGroupShow: []
          },
          jflowButton: [], // jflow配置按钮
          btnclick: (type, item) => {
            const self = this;
            return self.buttonClick(type, item);
          }
        },
        spinShow: false,
        dialogComponentName: null,
        dialogConfig: {
          title: this.$t('feedback.alert'),
          mask: true,
          footerHide: false,
          contentText: '',
          confirm: () => {
          }
        }, // 弹框配置信息
        selectedIndex: [],
        tableOb: '',
      };
    },
    name: 'CommonTable',
    components: { Dialog },
    props: {
      // buttonsData: {// 获取自定义按钮组
      //   type: Array,
      //   default: () => []
      // },
      showType:{ // 表格展示位置
        type: String,
        default: () => ''
      },
      getThAk:{  // 列表中配置isak 的字段
        type: Function,
        default: () => {},
      },
      doTableSearch: {
        type: Function,
        default: () => {},
      },
      cssStatus: {
        type: Array,
        default: () => []
      }, // 颜色配置信息  /p/cs/getTableQuery 接口返回
      datas: {
        type: Object,
        default: () => ({})
      }, // 所有返回数据  /p/cs/getTableQuery 接口返回
      errorArr: {
        type: Array,
        default: () => []
      }, // 错误信息数组
      onRowSingleClick: {
        type: Function,
        default: () => {
          // 参数说明
          // colDef：包含表头信息的对象
          // row：包含当前行所有数据的对象
          // target：事件触发对象，即event.target所返回的dom结点
        }
      }, // 行单击回调
      onRowDoubleClick: {
        type: Function,
        default: () => {
          // 参数说明
          // colDef：包含表头信息的对象
          // row：包含当前行所有数据的对象
          // target：事件触发对象，即event.target所返回的dom结点
        }
      }, // 行双击回调
      onSortChanged: {
        type: Function,
        default: () => {
          // 参数说明
          // arrayOfSortInfo: 返回当前用户触发的排序信息
          // 形如： [{"colId":"PS_C_BRAND_ID.val","sort":"asc"},{"colId":"ECODE.val","sort":"desc"}]
        }
      }, // 排序事件触发回调
      onSelectionChanged: {
        type: Function,
        default: () => {
          // 参数 rowIdArray选中的数据id数组 rowArray选中的数据数组
        }
      }, // 行选中事件
    },
    computed: {
      columns() {
        let defaultColumns = [];
        if(!this.showType || this.showType === 'multiple'){
            defaultColumns.push({
            type: 'selection',
            width: 22,
            align: 'right',
            fixed: 'left'
          })
        }
        if (Object.keys(this.datas).length > 0 && this.datas.tabth && this.datas.tabth.length > 1) {
          const columns = defaultColumns.concat(this.datas.tabth.reduce((acc, cur) => {
            // 弹窗单选要获取isak 为true 的字段
            if(this.showType && cur.isak){
               if (typeof this.getThAk === 'function') {
                   this.getThAk(cur.colname);
                }
            }
            // 添加跳转标准列表的逻辑
            if(cur.webconf && cur.webconf.routeWithParam){
                cur.customerurl = {}
            }

            if (cur.comment && !this.showType) {
              if (cur.name === 'ID') {
                acc.push(Object.assign({
                  title: this.$t('table.index'),
                  align: 'left',
                  fixed: 'left',
                  key: 'ID',
                  width: 40,
                  render: this.collectionIndexRender(),
                  renderHeader: this.tooltipRenderHeader()
                }, cur));
              } else if (['doc', 'image'].indexOf(cur.display) > -1) {
                switch (cur.display) {
                case 'doc':
                  if (cur.isorder) {
                    cur.sortable = 'custom';
                  }
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    // sortable: cur.isorder ? 'custom' : false,
                    render: this.docRender(),
                    renderHeader: this.tooltipRenderHeader(),
                    width: cur.webconf && cur.webconf.standard_width
                  }, cur));
                  break;
                case 'image':
                  if (cur.isorder) {
                    cur.sortable = 'custom';
                  }
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    // sortable: cur.isorder ? 'custom' : false,
                    render: this.imageRender(cur.colname),
                    renderHeader: this.tooltipRenderHeader(),
                    width: cur.webconf && cur.webconf.standard_width
                  }, cur));
                  break;
                default:
                  break;
                }
              } else if (cur.isfk && cur.fkdisplay !== 'mrp' && cur.fkdisplay !== 'mop') {
                if (this.datas.ordids && this.datas.ordids.length > 0 && this.datas.ordids.findIndex(item => item.colname === cur.colname) > -1) {
                  if (cur.isorder) {
                    cur.sortable = 'custom';
                    cur.sortType = this.datas.ordids.find(item => item.colname === cur.colname).ordasc ? 'asc' : 'desc';
                  }
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    // sortable: cur.isorder ? 'custom' : false,
                    // sortType: this.datas.ordids.find(item => item.colname === cur.colname).ordasc ? 'asc' : 'desc',
                    render: this.fkIconRender(cur),
                    renderHeader: this.tooltipRenderHeader(),
                    width: cur.webconf && cur.webconf.standard_width
                  }, cur));
                } else {
                  if (cur.isorder) {
                    cur.sortable = 'custom';
                  }
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    // sortable: cur.isorder ? 'custom' : false,
                    render: this.fkIconRender(cur),
                    renderHeader: this.tooltipRenderHeader(),
                    width: cur.webconf && cur.webconf.standard_width
                  }, cur));
                }
              } else if (this.datas.ordids && this.datas.ordids.length > 0 && this.datas.ordids.findIndex(item => item.colname === cur.colname) > -1) {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                  cur.sortType = this.datas.ordids.find(item => item.colname === cur.colname).ordasc ? 'asc' : 'desc';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  // sortable: cur.isorder ? 'custom' : false,
                  // sortType: this.datas.ordids.find(item => item.colname === cur.colname).ordasc ? 'asc' : 'desc',
                  renderHeader: this.tooltipRenderHeader(),
                  render: this.textRender(cur),
                  width: cur.webconf && cur.webconf.standard_width
                }, cur));
              } else if (cur.customerurl) {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  // sortable: cur.isorder ? 'custom' : false,
                  render: this.customerUrlRender(),
                  renderHeader: this.tooltipRenderHeader(),
                  width: cur.webconf && cur.webconf.standard_width
                }, cur));
              }  else if(cur.display === 'iconfontpicker') {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  render: this.iconRender(cur),
                  width: cur.webconf && cur.webconf.standard_width
                }, cur));
              } else if(cur.display === 'switch') {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  render: this.switchTextRender(cur),
                  width: cur.webconf && cur.webconf.standard_width
                }, cur));
              } else {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  // sortable: cur.isorder ? 'custom' : false,
                  renderHeader: this.tooltipRenderHeader(),
                  render: this.textRender(cur),
                  width: cur.webconf && cur.webconf.standard_width
                }, cur));
              }
            } else {
              if (cur.name === 'ID') {
                acc.push(Object.assign({
                  title: this.$t('table.index'),
                  align: 'left',
                  fixed: 'left',
                  key: 'ID',
                  width: 40,
                  render: this.collectionIndexRender(),
                  //  this.buttonsRender()
                }, cur));
              } else if (['command', 'image', 'doc', 'operatebuts'].indexOf(cur.display) > -1) {
                switch (cur.display) {
                case 'switch':
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    render: this.switchRender(),
                    width: cur.webconf && cur.webconf.standard_width
                  }, cur));
                  break;
                case 'command':
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    render: this.commandRender(cur),
                    width: cur.webconf && cur.webconf.standard_width
                  }, cur));
                  break;
                case 'image':
                  if (cur.isorder) {
                    cur.sortable = 'custom';
                  }
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    // sortable: cur.isorder ? 'custom' : false,
                    render: this.imageRender(cur.colname),
                    width: cur.webconf && cur.webconf.standard_width
                  }, cur));
                  break;
                case 'doc':
                  if (cur.isorder) {
                    cur.sortable = 'custom';
                  }
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    // sortable: cur.isorder ? 'custom' : false,
                    render: this.docRender(),
                    width: cur.webconf && cur.webconf.standard_width
                  }, cur));
                  break;
                case 'operatebuts':
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    fixed: 'right',
                    render: this.buttonRender(),
                    width: cur.webconf && (cur.webconf.width || cur.webconf.standard_width)
                  }, cur));
                  break;
                default:
                  break;
                }
              } else if (cur.isfk && cur.fkdisplay && cur.fkdisplay !== 'mop' && !this.showType) {
                if (this.datas.ordids && this.datas.ordids.length > 0 && this.datas.ordids.findIndex(item => item.colname === cur.colname) > -1) {
                  if (cur.isorder) {
                    cur.sortable = 'custom';
                    cur.sortType = this.datas.ordids.find(item => item.colname === cur.colname).ordasc ? 'asc' : 'desc';
                  }
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    // sortable: cur.isorder ? 'custom' : false,
                    // sortType: this.datas.ordids.find(item => item.colname === cur.colname).ordasc ? 'asc' : 'desc',
                    render: this.fkIconRender(cur),
                    width: cur.webconf && cur.webconf.standard_width
                  }, cur));
                } else {
                  if (cur.isorder) {
                    cur.sortable = 'custom';
                  }
                
                  acc.push(Object.assign({
                    title: cur.name,
                    key: cur.colname,
                    // sortable: cur.isorder ? 'custom' : false,
                    render: this.fkIconRender(cur),
                    width: cur.webconf && cur.webconf.standard_width
                    }, cur));
                  
                }
              } else if (this.datas.ordids && this.datas.ordids.length > 0 && this.datas.ordids.findIndex(item => item.colname === cur.colname) > -1) {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                  cur.sortType = this.datas.ordids.find(item => item.colname === cur.colname).ordasc ? 'asc' : 'desc';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  width: cur.webconf && cur.webconf.standard_width
                  // sortable: cur.isorder ? 'custom' : false,
                  // sortType: this.datas.ordids.find(item => item.colname === cur.colname).ordasc ? 'asc' : 'desc'
                }, cur));
              } else if (cur.customerurl) {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  // sortable: cur.isorder ? 'custom' : false,
                  render: this.customerUrlRender(),
                  width: cur.webconf && cur.webconf.standard_width
                }, cur));
              } else if(cur.display === 'iconfontpicker') {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  render: this.iconRender(cur),
                  width: cur.webconf && cur.webconf.standard_width
                }, cur));
              } else if(cur.display === 'switch') {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  render: this.switchTextRender(cur),
                  width: cur.webconf && cur.webconf.standard_width
                }, cur));
              } else {
                if (cur.isorder) {
                  cur.sortable = 'custom';
                }
                acc.push(Object.assign({
                  title: cur.name,
                  key: cur.colname,
                  width: cur.webconf && cur.webconf.standard_width,
                  render: this.textRender(cur)
                  // sortable: cur.isorder ? 'custom' : false
                }, cur));
              }
            }


            return acc;
          }, []));
          return columns;
        }
        return [];
      }, // 表头

      tableData() {
        if (Object.keys(this.datas).length > 0) {
          const data = this.datas.row && this.datas.row.reduce((acc, cur) => {
            const obj = {};
            Object.keys(cur).forEach((item) => {
              obj[item] = cur[item].val;
            });
            acc.push(obj);
            return acc;
          }, []);

          // 回填勾选
          this.selectedIndex.forEach((curIndex) => {
            if (data[curIndex]) {
              data[curIndex]._checked = true;
            }
          });

          this.spinShow = false;
          return data;
        }
        this.spinShow = false;
        return [];
      }, // 表体
      tableTotalData() {
        if(this.showType){
          return []
        }
        const total = [];
        if (this.datas.isSubTotalEnabled) {
          const cell = {
            ID: this.$t('table.summation')
          };
          // const needSubtotalList = this.columns.filter(ele => ele.issubtotal);
          // needSubtotalList.map((ele) => {
          //   const needSubtotalDatas = [];
          //   this.tableData.reduce((a, c) => {
          //     let str = c[ele.colname];
          //     if (str.indexOf(',') > -1 || str.indexOf('，') > -1) {
          //       str = str.replace(/,/g, '');
          //     }
          //     return needSubtotalDatas.push(str);
          //   }, []); //
          //   let totalNumber = needSubtotalDatas.reduce((a, c) => Number(a) + Number(c), []);
          //   if (typeof totalNumber === 'number') {
          //     totalNumber = totalNumber.toFixed(2);
          //   }
          //   cell[ele.colname] = `${totalNumber}`;
          //   return ele;
          // });
          if (this.datas.subtotalRow && Object.keys(this.datas.subtotalRow).length > 0) {
            Object.keys(this.datas.subtotalRow).forEach((key) => {
              cell[key] = this.datas.subtotalRow[key];
            });
          }
          total.push(cell);
        }
        // if (this.isHorizontal) {
        if (this.datas.isFullRangeSubTotalEnabled) {
          // 总计
          const cell = {
            ID: this.$t('table.total'),
          };
          // if (this.datas.fullRangeSubTotalRow) {
          //   for (const key in this.datas.fullRangeSubTotalRow) {
          //     if (Object.prototype.hasOwnProperty.call(this.datas.fullRangeSubTotalRow, key)) {
          //       const element = this.datas.fullRangeSubTotalRow[key];
          //       cell[key] = element.val;
          //     }
          //   }
          // }
          if (this.datas.fullRangeSubTotalRow && Object.keys(this.datas.fullRangeSubTotalRow).length > 0) {
            Object.keys(this.datas.fullRangeSubTotalRow).forEach((key) => {
              const element = this.datas.fullRangeSubTotalRow[key];
              cell[key] = element.val;
            });
          }
          total.push(cell);
        }
        return total;
      }, // 总计和合计
      classes: () => `${classFix}commonTable`
    },
    watch: {},
    // mounted() {
    //   console.log(this.$refs.table)
    //   this.tableOb = new ResizeObserver(((entries) => {
    //     entries.forEach(() => {
    //       (this.$refs.table && this.$refs.table.handleResize) ? this.$refs.table.handleResize() : null;
    //     });
    //   }));
    //   this.tableOb.observe(document.getElementsByClassName('commonTable')[0]); // dom
    // },
    // beforeDestroy() {
    //   this.tableOb.unobserve(document.getElementsByClassName('commonTable')[0])
    // },
    methods: {
      ...mapMutations('global', ['tabOpen', 'directionalRouter', 'updateCustomizeMessage']),
      // btnclick(obj) {
      //   this.$emit('btnclick', obj);
      //   // switch (obj.vuedisplay) {
      //   // case 'slient':
      //   //   this.$emit('objTabActionSlient', obj);
      //   //   break;
      //   // case 'dialog':
      //   //   this.$emit('objTabActionDialog', obj);
      //   //   break;
      //   // case 'navbar':
      //   //   this.$emit('objTabActionNavbar', obj);
      //   //   break;

      //   // default:
      //   //   break;
      //   // }
      // },
      // buttonsRender() {
      //   // 按钮组
      //   return (h, info) => h('div',
      //                         [
      //                           h('buttons', {
      //                             on: {
      //                               btnclick: (obj) => {
      //                                 obj.ID = info.row.ID;
      //                                 this.btnclick(obj);
      //                               }
      //                             },
      //                             props: {
      //                               buttonsData: this.buttonsData
      //                               // value: info.row[info.column.colname] === 'true',
      //                               // size: 'small',
      //                               // loading: false
      //                             },
      //                           })
      //                         ]);
      // },
      deselectAll() {
        this.$refs.table.selectAll(false);
      }, // 取消表格全部选中
      rowClassName(row) {
        let cssStr = '';
        // let cssColorStr = '';
        const cssStatus = JSON.parse(JSON.stringify(this.cssStatus));
        cssStatus.sort((a, b) => a.priority - b.priority);
        cssStatus.forEach((item) => {
          const columnKeydesc = this.columns.find(tem => tem.title === item.desc);
          const columnKey = columnKeydesc && columnKeydesc.key;
          const cssFindindex = item.value.findIndex(cur => cur === row[columnKey]);
          if (cssFindindex > -1) {
            // if (item.css[cssFindindex].indexOf('color') > -1) {
            //   cssColorStr = item.css[cssFindindex];
            // } else {
            //   cssStr = `${cssStr + item.css[cssFindindex]} `;
            // }
            cssStr = `${cssStr + item.css[cssFindindex]} `;
          }
        });
        // cssStr = `${cssStr} ` + cssColorStr;
        return cssStr;
      }, // 行样式

      switchTextRender() {
        return (h, params) => {
          const rowData = params.row[params.column.colname]
          if(!rowData) {
            return null
          }
          return h('span',{
            style: {
            },
            class: {
              'table-switch': true
            },
            domProps: {
              innerText: rowData
            }
          })
        }
      },

      iconRender() {
        return (h, params) => {
          const rowData = params.row[params.column.colname]
          if(!rowData) {
            return null
          }
          return h('IconfontComponent',{
            style: {
            },
            class: {
              'table-icon': true
            },
            props:{
              params: {
                value: rowData
              }
            },
          })
        }
      },
      onrouteWithParam(params){
        // 跳转标准列表
          if(params.column.webconf){
             let {routeWithParam} = params.column.webconf;
             if(routeWithParam){
               this.$parent.onCellSingleClick(params.column,params.row)
                return;
             }
          }

      },
      customerUrlRender() {
        let self = this;
       
         if(self.showType){
          //  弹窗单选
            return (h, params) => h('span', {
              domProps: {
                innerHTML:`<span title='${params.row[params.column.colname] || ""}'>${params.row[params.column.colname] || ''}</span>`
              }
          });
        }

        return (h, params) => h('span', {
          domProps: {
            innerHTML:`<span title='${params.row[params.column.colname] || ""}'>${params.row[params.column.colname] || ''}</span>`
          },
          style: {
            color: '#0f8ee9',
            'text-decoration': 'underline',
            cursor: 'pointer'
          },
          on: {
            click: () => {
              // 跳转标准列表
              this.onrouteWithParam(params);
              const objdistype = params.column.customerurl.objdistype;
              if (objdistype === 'popwin') {
                // 自定义弹窗
                this.$emit('CustomizedDialog', params);
              } else if (objdistype === 'tabpanle') {
                // 左右结构单对象界面
                const type = 'tableDetailHorizontal';
                const tab = {
                  type,
                  tableName: params.column.customerurl.reftablename,
                  tableId: params.column.customerurl.reftableid,
                  label: params.column.customerurl.reftabdesc,
                  id: params.row[params.column.customerurl.refobjid]
                };
                this.tabOpen(tab);
              } else if (objdistype === 'object') {
                // 上下结构单对象界面
                const type = 'tableDetailVertical';
                const tab = {
                  type,
                  tableName: params.column.customerurl.reftablename,
                  tableId: params.column.customerurl.reftableid,
                  label: params.column.customerurl.reftabdesc,
                  id: params.row[params.column.customerurl.refobjid]
                };
                this.tabOpen(tab);
              } else if (objdistype === 'customized') {
                // 自定义界面
                const type = 'tableDetailAction';
                const field = params.column.customerurl.refobjid;
                const url = `/${params.column.customerurl.tableurl.toUpperCase()}/${params.row[field]}`;
                const tab = {
                  type,
                  label: params.column.customerurl.reftabdesc,
                  url
                };
                this.tabOpen(tab);
              } else if (objdistype === 'link') { // 支持跳转外链界面配置动态参数
                const param = {
                  url: params.column.customerurl.tableurl,
                  query: params.column.customerurl.refobjid,
                  label: params.column.customerurl.reftabdesc,
                  isMenu: true,
                  linkName: params.column.customerurl.linkname,
                  linkId: params.column.customerurl.refobjid,
                };
                this.directionalRouter(param);// 定向路由跳转方法
                const data = {
                  type: 'standardCustomerurlLink',
                  value: params.row,
                  customizedModuleName: params.column.customerurl.linkname.toUpperCase()
                  // 因外链界面tablinkName相同时，只激活一个tab,所以外链界面用linkName作为key存入session,避免因勾选的id不同存入多个，导致关闭当前tab时无法清除存入的多个
                };
                this.updateCustomizeMessage(data);
              }
            }
          },
        });
      }, // 外键连接定制界面
      tooltipRenderHeader() {
        return (h, params) => h('span', [
          h('Poptip', {
            style: {},
            props: {
              trigger: 'hover',
              transfer: true,
              wordWrap: true,
              content: 'content',
              placement: 'top'

            },
            scopedSlots: {
              default: () => h('div', {
                style: {},
                domProps: {
                  innerHTML: `<span>${params.column.name}</span> <i class="iconfont comment iconios-information-circle-outline" style="color: orangered"></i>`
                }
              }),
              content: () => h('div', {
                style: {},
                domProps: {
                  innerHTML: `<span>${params.column.comment}</span>`
                }
              }),
            },
          })
        ]);
      }, 
      fkInnerHtml(params,cellData){
        const data = this.datas.row[params.index][cellData.colname];
        // 外键列表渲染
        let refobjid = data.refobjid && data.refobjid.toString() ? data.refobjid.toString().split(',') :['']
         let innerHtml = data.val&&data.val.toString().split(',').reduce((arr,item,index) => {
          if(item){
              if(cellData.fkdisplay ==='mrp'){
                arr.push(`<span class="span" data-refobjid="${refobjid[index]}" style="color:#0f8ee9;text-decoration:underline;cursor:pointer;padding: 0 3px;" title=${item}>${item}</span>`)
              }else{
                arr.push(`<i class="iconfont iconbj_link" index="${index}" data-target-tag="fkIcon" data-refobjid="${refobjid[index]}" style="color: #0f8ee9; cursor: pointer; font-size: 12px;" ></i> <span title=${item}>${item}</span>`)
              }
          }else{
            arr.push('')
          }
          return arr;
        },[]);
        return innerHtml && innerHtml.join('');

      },
      // 表头提示的render
      fkIconRender(cellData) {
        // 外键关联到icon

        if(cellData.customerurl){
          // 外键字段跳转定制路径
          return this.customerUrlRender();
          
        }
        return (h, params) => h('div', {
          class: ['one-line-flow'],
          domProps: {
            innerHTML: this.fkInnerHtml(params,cellData)
          },
          on: {
            click: (event) => {
              // TODO 外键关联跳转
              let refobjid = event.target.getAttribute('data-refobjid');
              if (event.target.className) {
                const data = this.datas.row[params.index][cellData.colname];
                if (cellData.objdistype === 'object') {
                  window.sessionStorage.setItem('dynamicRoutingForHideBackButton', true);
                  this.tabOpen({
                    type: 'tableDetailVertical',
                    tableName: data.reftablename,
                    tableId: data.reftableid,
                    label: data.reftabdesc,
                    id:cellData.fkdisplay ==='mrp' ? refobjid : data.refobjid,
                    serviceId: data.serviceId
                  });
                } else if (cellData.objdistype === 'tabpanle') {
                  window.sessionStorage.setItem('dynamicRoutingForHideBackButton', true);
                  this.tabOpen({
                    type: 'tableDetailHorizontal',
                    tableName: data.reftablename,
                    tableId: data.reftableid,
                    label: data.reftabdesc,
                    original:'outclick',
                    id:cellData.fkdisplay ==='mrp' ? refobjid : data.refobjid,
                    serviceId: data.serviceId
                  });
                } else {
                  const messageData = {
                    mask: true,
                    title: this.$t('feedback.warning'),
                    content: this.$t('messages.setAssociationTable')
                  };
                  this.$Modal.fcWarning(messageData);
                }
              }
              event.stopPropagation();
            }
          }
        },);
      },
      // 纯文本渲染
      textRender(cellData) {
        return (h, params) => h('div', {
          class: ['one-line-flow'],
          domProps: {
            innerHTML: `<span title='${params.row[cellData.colname] || ''}'>${params.row[cellData.colname] || ''}</span>`
          }
        });
      },

      imageRender(colname) {
        return (h, params) => {
          if (!params.row[colname]) {
            return null;
          }
          return h('div', {
            on: {
              dblclick: (event) => {
                const item = {
                  field: `${params.column.colname}_${params.row.ID}`
                };
                createModal(JSON.parse(params.row[colname]), item);

                event.stopPropagation();
              }
            }
          }, [
            h('Poptip', {
              style: {},
              props: {
                trigger: 'hover',
                transfer: true,
                content: 'content',
                placement: 'right'
              },
              scopedSlots: {
                default: () => h('img', {
                  style: {
                    height: '20px',
                    width: '20px'
                  },
                  domProps: {
                    src: params.row[colname] && this.isJsonString(params.row[colname]) ? JSON.parse(params.row[colname])[0].URL : params.row[colname]
                  }
                }),
                content: () => h('img', {
                  style: {
                    width: '120px',
                    height: '120px',
                    margin: '8px 0px',
                    'vertical-align': 'middle',
                  },
                  domProps: {
                    src: params.row[colname] && this.isJsonString(params.row[colname]) ? JSON.parse(params.row[colname])[0].URL : params.row[colname]
                  }
                }),
              },
              nativeOn: {
                // click: (e) => {
                //   e.stopPropagation();
                // }
              },
            })
          ]);
        };
      }, // 图片render
      docRender() {
        return (h, params) => {
          if (!params.row[params.column.colname]) {
            return null;
          }
          const data = JSON.parse(params.row[params.column.colname]);
          let html = '';
          // webconf配置docFile则走docFile配置程序，点击上传的文件取消下载功能，改为预览功能
          let getDocFileWebConfUrl = '';
          let getDocFileWebConf = false;
          if (params.column && params.column.webconf && params.column.webconf.docFile && params.column.webconf.docFile.isPreview) {
            getDocFileWebConf = params.column.webconf.docFile.isPreview;
            getDocFileWebConfUrl = params.column.webconf.docFile.url;
          }
          data.forEach((item) => {
            if (getDocFileWebConf) {
              html += `<span class="doc-wrapper"><a href="${getDocFileWebConfUrl}?url=${item.url}" target="_blank"><i class="iconfont iconmd-document" data-target-tag="fkIcon" style="color: #20a0ff; cursor: pointer; font-size: 16px" ></i> ${item.name}</a></span>`;
            } else {
              html += `<span class="doc-wrapper"><a href="${item.url}"><i class="iconfont iconmd-document" data-target-tag="fkIcon" style="color: #20a0ff; cursor: pointer; font-size: 16px" ></i> ${item.name}</a></span>`;
            }
          });
          return h('div', {
            domProps: {
              innerHTML: html
            }
          });
        };
      }, // 图片render
      isJsonString(str) {
        try {
          if (typeof JSON.parse(str) === 'object') {
            return true;
          }
        } catch (e) {
        }
        return false;
      },
      collectionIndexRender() {
        // 序号的render
        return (h, params) => {
          const index = Number(this.datas.start) + params.index + 1;
          if (this.datas.deleteFailInfo && this.datas.deleteFailInfo.length > 0 && this.datas.deleteFailInfo.findIndex(item => item.objid.toString() === params.row.ID.toString()) > -1) {
            const errIndex = this.datas.deleteFailInfo.findIndex(item => item.objid.toString() === params.row.ID.toString());
            return h('div', [
              h('Poptip', {
                style: {},
                props: {
                  trigger: 'hover',
                  transfer: true,
                  wordWrap: true,
                  content: 'content',
                  placement: 'right'

                },
                scopedSlots: {
                  default: () => h('div', {
                    style: {},
                    domProps: {
                      innerHTML: `<span>${index}</span>
                            <i class="iconfont iconios-warning-outlin" style="margin-left:5px; color: red" />`
                    }
                  }),
                  content: () => h('div', {
                    style: {
                      width: '180px',
                    },
                    domProps: {
                      innerHTML: `<span>${this.datas.deleteFailInfo[errIndex].message}</span>`
                    }
                  }),
                },
              })
            ]);
          }
          return h('div', {
            domProps: {
              innerHTML: `<span>${index}</span>`
            }
          });
        };
      }, // 序号render
      fieldMergeRender(cur) {
        return (h, params) => {
          const array = [];
          if (true) { // 存在数据时
            cur.key_group.map((item) => {
              const value = params.row[item.col_name]; // 来源字段的值
              item.label.map((temp) => {
                if (temp.value == value) {
                  array.push({
                    description: temp.description,
                    class: temp.cssclass
                  });
                }
                return temp;
              });
              return item;
            });

            return h('div', {}, array.map(item => h('span', {
              class: item.class,
              style: {
                display: 'inline-block',
                padding: '4px 6px',
                border: '1px solid',
                borderRadius: '4px'
              }
            }, item.description)));
          }
          return h('span', {}, '');
        };
      },
      tableRowClick(parms,index,event) {
        let column = {};
        let dataset = event.target.dataset;
        if(dataset && dataset.columnindex){
          column = this.columns[dataset.columnindex];
          let paramsData = {
            row:parms,
            index:index,
            column:column
          }
        // 跳转标准列表传参
          this.onrouteWithParam(paramsData);
        }
         if(this.showType){
            this.$emit('on-row-click',parms,index,event,column)
        }

      }, // 普通表格单击
      tableSelectionChange(val) {
        if(this.showType){
            this.$emit('on-selection-change',val)
        }
        const self = this;
        const rowIdArray = val.reduce((acc, cur) => {
          acc.push(cur.ID);
          return acc;
        }, []);
        if (typeof self.onSelectionChanged === 'function') {
          self.onSelectionChanged(rowIdArray, val);
          // 缓存勾选索引用于回填
          const selectedArr = [];
          this.datas.row.forEach((row, index) => {
            if (rowIdArray.includes(row.ID.val)) {
              selectedArr.push(index);
            }
          });

          this.selectedIndex = selectedArr;
        }
      }, // 普通表格选中事件
      tableRowDbclick(row, index, event, colDef) {
        const self = this;
        const rowData = this.datas.row[index];
        if (typeof self.onRowDoubleClick === 'function') {
          self.onRowDoubleClick(colDef, rowData, event.target);
        }
      }, // 普通表格双击事件
      tableSortChange(val) {
        const self = this;
        const arrayOfSortInfo = [{ colId: val.key, sort: val.order }];
        if (typeof self.onSortChanged === 'function') {
          self.onSortChanged(arrayOfSortInfo);
        }
      }, // 表格排序触发
      switchRender() {
        const $this = this;
        // 开关选择器
        return (h, info) => h('div',
                              [
                                h('i-switch', {
                                  on: {
                                    'on-change': (status) => {
                                      const conf = info.column.combobox;
                                      const valPool = conf.reduce((a, c) => {
                                        if (c.limitdis) {
                                          a.Y = c.limitval;
                                        } else {
                                          a.N = c.limitval;
                                        }
                                        return a;
                                      }, {});
                                      const currentTableName = store.state.global.activeTab.tableName;
                                      const webconf = info.column.webconf;
                                      if (webconf && !webconf.disable) {
                                        network.post(webconf && webconf.switchurl ? webconf.switchurl : '/p/cs/objectSave', {
                                          table: currentTableName,
                                          objId: info.row.ID,
                                          fixedData: {
                                            [currentTableName]: {
                                              [info.column.colname]: status ? valPool.Y : valPool.N
                                            }
                                          }
                                        }).then(() => {
                                          $this.$Message.info(`${info.column.name}：${`${status}`.toUpperCase()}`);
                                        }, (err) => {
                                          console.log('err', err);
                                        });
                                      }
                                    }
                                  },
                                  props: {
                                    value: info.row[info.column.colname] === 'true',
                                    size: 'small',
                                    loading: false
                                  },
                                })
                              ]);
      },
      buttonRender() {
        const $this = this;
        // 开关选择器
        return (h, info) => h('div',
                              info.column.combobox.reduce((a, c) => {
                                try {
                                  if (info.row[info.column.colname] && JSON.parse(info.row[info.column.colname]).indexOf(c.limitval) > -1) {
                                    return a.concat(c);
                                  }
                                } catch (e) {
                                  return a;
                                }
                                return a;
                              }, []).map(d => h('Button', {
                                nativeOn: {
                                  click(event) {
                                    DispatchEvent('oprButtonClick', {
                                      detail: {
                                        event,
                                        info,
                                        allWebConf: info.column.webconf || {},
                                        webConf: info.column.webconf ? info.column.webconf[d.limitval] || {} : {},
                                        symbol: d.limitval,
                                        methods: {
                                          doTableSearch: $this.doTableSearch
                                        },
                                      }
                                    });
                                  }
                                },
                                props: {
                                  type: 'primary',
                                  size: 'small'
                                },
                                style: {
                                  marginRight: '2px',
                                  padding: '3px 5px',
                                  borderRadius: '2px'
                                }
                              }, d.limitdesc)));
      },
      commandRender(data) {
        const params = [
          {
            label: this.$t('buttons.edit'),
            type: 'fcdefault',
            display: 'dialog',
            component: 'commonTable/componentName',
            disabled: false,
            dialogTitle: this.$t('tips.popUp')
          },
          {
            label: this.$t('buttons.delete'),
            type: 'fcdefault',
            display: 'delete',
            disabled: false

          },
          {
            label: this.$t('buttons.jump'),
            type: 'fcdefault',
            display: 'url',
            url: '',
            disabled: false

          }
        ];
        // display按钮操作类型（3种）
        // 跳转/弹出框/删除/
        return (h, param) => params.map(item => h('ButtonGroup',
                                                  [
                                                    h('i-Button', {
                                                      on: {
                                                        click: () => {
                                                          if (item.display === 'dialog') {
                                                            this.$refs.dialogRef.open();
                                                            const title = `${item.dialogTitle}`;
                                                            this.dialogConfig = {
                                                              title,
                                                            };
                                                            this.dialogConfig.footerHide = true;
                                                            const url = item.component;
                                                            const index = url.lastIndexOf('/');
                                                            const filePath = url.substring(index + 1, url.length);
                                                            this.dialogComponentName = filePath;
                                                          }
                                                        }
                                                      },

                                                      props: {
                                                        type: item.type,
                                                        disabled: item.disabled,
                                                        size: 'small'
                                                      },
                                                      style: {
                                                        marginRight: '10px'
                                                      }
                                                    }, item.label)
                                                  ]));
      }
    }
  };
</script>
