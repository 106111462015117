var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "api-tree" }, [
    _c(
      "div",
      { staticClass: "api-header" },
      [
        _c(
          "p",
          { staticClass: "api-header-l" },
          [
            _vm.currentAccount && _vm.currentAccount.name
              ? _c("span", [
                  _vm._v(
                    "【" +
                      _vm._s(_vm.currentAccount.name) +
                      "】" +
                      _vm._s(_vm.$t("messages.managementAuthority"))
                  ),
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm.$t("messages.selectAccountFirst"))),
                ]),
            _vm._v(" "),
            _vm.currentAccount && _vm.currentAccount.name
              ? _c(
                  "Tooltip",
                  {
                    attrs: {
                      content: _vm.$t("messages.refreshPermission"),
                      placement: "top",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "refresh", on: { click: _vm.refresh } },
                      [
                        _c("img", {
                          staticClass: "refresh-icon",
                          attrs: {
                            src: require("../../assets/image/refresh.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "Button",
          {
            attrs: {
              type: "posdefault",
              size: "small",
              disabled: !_vm.isUpdated,
            },
            on: { click: _vm.save },
          },
          [_vm._v(_vm._s(_vm.$t("buttons.save")))]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "api-body" }, [
      _c(
        "div",
        { staticClass: "all-panel" },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("messages.interfacePermissions")) + "："),
          ]),
          _vm._v(" "),
          _c(
            "Checkbox",
            {
              attrs: { disabled: _vm.treeData.length === 0 },
              model: {
                value: _vm.isSelectAll,
                callback: function ($$v) {
                  _vm.isSelectAll = $$v
                },
                expression: "isSelectAll",
              },
            },
            [_vm._v(_vm._s(_vm.$t("tips.all")))]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "count" }, [
            _vm._v(
              "(" + _vm._s(_vm.checkedTotal) + "/" + _vm._s(_vm.total) + ")"
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "api-wrap" },
        [
          _c(
            "Spin",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              attrs: { fix: "" },
            },
            [
              _c("Icon", {
                staticClass: "demo-spin-icon-load",
                attrs: { type: "ios-loading", size: "18" },
              }),
              _vm._v(" "),
              _c("div", [_vm._v("Loading")]),
            ],
            1
          ),
          _vm._v(" "),
          _c("Ztree", {
            ref: "zTree",
            attrs: {
              placeholder: _vm.$t("messages.pleaseEnterContent"),
              "z-nodes": _vm.treeData,
              treeSetting: _vm.treeSetting,
              customizedSearch: _vm.search,
              disabledSearch: !_vm.currentAccount,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }