var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.panel.show,
          expression: "panel.show",
        },
      ],
      class: _vm.classes,
    },
    [
      _c("div", {
        staticClass: "modal-backdrop",
        on: { click: _vm.closeMessage },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "panel-main" }, [
        _c("div", { staticClass: "panel-title" }, [
          _c("label", { staticClass: "left" }, [
            _vm._v(_vm._s(_vm.$t("tips.message"))),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "right", on: { click: _vm.ignoreMsg } }, [
            _vm._v(_vm._s(_vm.$t("buttons.ignore"))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "messageBox",
            staticClass: "panel-content",
            on: { scroll: _vm.handleScroll },
          },
          [
            _vm.panel.list.length == 0
              ? _c("div", { staticClass: "no-message" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("messages.noMessage")))]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.messagePanel.list, function (item, index) {
              return _c(
                "div",
                {
                  key: item.ID.val,
                  staticClass: "panel-item",
                  on: {
                    click: function ($event) {
                      return _vm.markRead(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-time" }, [
                    _c("i", {
                      staticClass: "circle green",
                      class: item.TASKSTATE.val === "已完成" ? "green" : "red",
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.calcdateDiff(item.ENDTIME.val) || item.ENDTIME.val
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "item-title" }, [
                      _c("span", [
                        _c("b", [
                          _vm._v("[" + _vm._s(_vm.$t("tips.asyncTask")) + "]"),
                        ]),
                        _vm._v(
                          _vm._s(_vm.$t("tips.task")) +
                            ": " +
                            _vm._s(item.MENU.val) +
                            _vm._s(item.TASKTYPE.val) +
                            _vm._s(item.TASKSTATE.val)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-content" }, [
                      _c(
                        "p",
                        { staticStyle: { "-webkit-box-orient": "vertical" } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.CONTENT.val.substring(0, 200)) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "panel-bottom" }, [
          _c("span", { staticClass: "right", on: { click: _vm.jumpTask } }, [
            _vm._v(_vm._s(_vm.$t("tips.allTasks"))),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }