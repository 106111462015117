import network from '../../__utils__/network.js';
import i18n from '../../assets/js/i18n'

export default {
  data() {
    return {
      actived:false, // 渲染未完成
      rules:{},
      initTimer:null
    };
  },
  methods:{
    validateInput() {
      return new Promise((resolve) => {
      const preverifyenabled = this.items.preverifyenabled;
      // 子表唯一性不校验
      let checked = new RegExp(this.items.tableName).test(this.items.moduleComponentName);
      if (preverifyenabled && checked) {
        network.post('/p/cs/verifyObject', {
          OBJ_ID: this.$route.params.itemId === 'New' ? -1 : this.$route.params.itemId,
          TABLE_NAME: this.$route.params.tableName,
          VERIFY_COLUMN: {
            [this.items.colname]: this.value
          }
        }).then((res) => {
          if (res.data.code === 1) {
            resolve(res.data.message);
          }else{
            resolve('');
          }
        });
      }
    });
    },
    setRules(){
      let required = this.items.show && !this.items.readonly && this.items.isnotnull ; 
      if(this.items.show && this.items.show_occupied === false){
        // 兼容隐藏时候的占位
        required = false;
      }
      let self = this;
      this.rules = {
        required: {
          type:required,
          message: `${this.items.name}${i18n.t('tips.notEmpty')}!`, 
          trigger: 'blur'
        }, 
        trigger:{
          blur:{
            callback:function(val){
              return self.validateInput();
            },
        }                
      }
        
      }
    

    }
  },
  async mounted() {
    // 设置校验规则
    this.setRules();
    let self = this;
    let ParentForm = await  this.findParentForm();
    ParentForm.$nextTick(()=>{
      setTimeout(()=>{
        // 延时渲染完成,阻止页面初始化调用事件
         self.actived = true;    
      },100);
    })
   
  }  
};