/* eslint-disable func-names */
/* eslint-disable no-prototype-builtins */

/**
 * EnumerableInput读写规则组件自定义
 */

// import { EnumerableInput } from '@syman/ark-ui-bcl';
import { SetPlaceholder ,SetDisable} from './setProps';
let  EnumerableInput = $Bcl.arkEnumerableInput;


class CustomEnumerableInput{
  constructor(item) {
    this.item = item;
    this.Vm = Object.create(EnumerableInput);
    this.mergeProps();   
  }

  init() {
    return {
      Components:this.Vm,
      props:this.props
    };

  }


  // 合并props
  mergeProps() {
    if(this.item.tableName === "BatchModal"){
       // 兼容批量修改tablename 失效问题
      this.item.tableName = window.vm.$route.params.tableName
    }
    this.props = {
        disabled:new SetDisable(this.item).init(),
        tableName:this.item.tableName
      }
  }


  mergeMethods() {}
}

export default CustomEnumerableInput;