var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showColorSetting && _vm.userInfo.isadmin
    ? _c(
        "div",
        { class: _vm.classes },
        [
          _c("div", { staticClass: "panel-item" }, [
            _c(
              "p",
              [
                _c("i", { staticClass: "iconfont iconmd-map explanatory" }),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("messages.SwitchToShow")) +
                    "\n      "
                ),
                _c("i-switch", {
                  staticClass: "switch",
                  on: {
                    "on-change": function ($event) {
                      return _vm.updatesystemcolor($event, "is_up_down")
                    },
                  },
                  model: {
                    value: _vm.switchToShow,
                    callback: function ($$v) {
                      _vm.switchToShow = $$v
                    },
                    expression: "switchToShow",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.show
            ? [
                _c("changeTheme", {
                  ref: "changeTheme",
                  attrs: {
                    name: _vm.$t("changeTheme.title"),
                    value: _vm.themeColor,
                  },
                  on: {
                    "on-change": function ($event) {
                      return _vm.updatesystemcolor($event, "color")
                    },
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }