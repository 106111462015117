var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "string-wrap one-line-flow",
    style: _vm.options.webconf && _vm.options.webconf.styleForString,
    attrs: { title: _vm.customizedDefaultValue || _vm.value },
    domProps: { innerHTML: _vm._s(_vm.customizedDefaultValue || _vm.value) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }