/**
 * 该文件写js常用操作
 */
// 防抖
export function debounce(func, delay = 200) {
  let inDebounce
  return function(...arg) {
    const context = this
    const args = arg
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

// 深拷贝
export function deepClone(value) {
  return JSON.parse(JSON.stringify(value))
}

// 循环深拷贝
export function deepCloneIn(target) {
 // 定义一个变量
 let result;
 // 如果当前需要深拷贝的是一个对象的话
 if (typeof target === 'object') {
 // 如果是一个数组的话
   if (Array.isArray(target)) {
     result = []; // 将result赋值为一个数组，并且执行遍历
     if(target.length > 0){
       for (const i in target) {
         // 递归克隆数组中的每一项
         if(JSON.stringify(target[i])){
           result.push(deepCloneIn(target[i]));
         }
       }

     }
     
     // 判断如果当前的值是null的话；直接赋值为null
   } else if (target === null) {
     result = null;
     // 判断如果当前的值是一个RegExp对象的话，直接赋值    
   } else if (target.constructor === RegExp) {
     result = target;
   } else {
     // 否则是普通对象，直接for in循环，递归赋值对象的所有值
     result = {};
     for (const i in target) {
       result[i] = deepCloneIn(target[i]);
     }
   }
   // 如果不是对象的话，就是基本数据类型，那么直接赋值
 } else {
   result = target;
 }
 // 返回最终结果
 return result;
}


// 获取getCookie
export function R3getCookie(name) {
  var prefix = name + "="
  var start = document.cookie.indexOf(prefix);
  console.log('============',document.cookie);

  if (start == -1) {
      return null;
  }

  var end = document.cookie.indexOf(";", start + prefix.length)
  if (end == -1) {
      end = document.cookie.length;
  }

  var value = document.cookie.substring(start + prefix.length, end)
  return unescape(value);
}
/*
obj
*/
// 判空处理
export const isEmpty = (obj)=>{
  if(typeof obj ==='object'){
      obj = JSON.stringify(obj || {}).replace(/null|,/g,'');
  }

  if(obj === undefined || obj === "null" || obj === "" || obj === "[]" || obj === "{}"){
      return true;
  }else{
      return false;
  }
 
}

export const copy = function(text){
  const dom = document.createElement('input')
  dom.style.opacity = 0
  dom.style.position = 'fixed'
  dom.style.left='-99999px'
  dom.setAttribute('id', 'r3-copy-input')
  document.body.appendChild(dom)

  dom.value = text
  dom.select()
  document.execCommand('copy') 
  document.body.removeChild(dom)
}