var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.classess,
      attrs: { id: this.$router.currentRoute.params.tableName },
    },
    [
      _vm.isTreeList
        ? _c(
            "div",
            [
              _c("tree", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.treeShow,
                    expression: "treeShow",
                  },
                ],
                ref: "tree",
                attrs: { "tree-datas": _vm.treeConfigData, enterDefault: true },
                on: { menuTreeChange: _vm.menuTreeChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTreeList
        ? _c(
            "div",
            {
              staticClass: "treeSwitch",
              on: {
                click: function ($event) {
                  _vm.treeShow = !_vm.treeShow
                },
              },
            },
            [
              !_vm.treeShow
                ? _c("i", { staticClass: "iconfont iconbj_right" })
                : _vm._e(),
              _vm._v(" "),
              _vm.treeShow
                ? _c("i", { staticClass: "iconfont iconbj_left" })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        _vm.slotName,
        { tag: "div", attrs: { getVm: _vm.getVm } },
        [
          _c("ButtonGroup", {
            ref: "R3ButtonGroup",
            attrs: {
              slot: "list-buttons",
              "data-array": _vm.buttons.dataArray,
              "id-array": _vm.idArray,
            },
            on: {
              buttonClick: _vm.buttonClick,
              clearSelectIdArray: _vm.clearSelectIdArray,
            },
            slot: "list-buttons",
          }),
          _vm._v(" "),
          _vm.formItems.defaultFormItemsLists &&
          _vm.formItems.defaultFormItemsLists.length > 0
            ? _c("listsForm", {
                ref: "R3listform",
                attrs: {
                  slot: "list-form",
                  id: _vm.$route.params.tableName,
                  moduleComponentName: _vm.moduleComponentName,
                  "form-item-lists": _vm.formItems.defaultFormItemsLists,
                  "default-spread": _vm.changeSearchFoldnum.switchValue,
                  search: true,
                  listFormTemple: _vm.slotTemple
                    ? _vm.slotTemple.listForm
                    : function () {},
                  "table-webconf": _vm.webConf,
                  treeShow: _vm.treeShow,
                  "default-column": Number(4),
                  "search-foldnum": Number(
                    _vm.changeSearchFoldnum.queryDisNumber ||
                      _vm.formItems.searchFoldnum
                  ),
                },
                on: { onHandleEnter: _vm.searchClickData },
                slot: "list-form",
              })
            : _vm._e(),
          _vm._v(" "),
          _c(_vm.defined, {
            tag: "component",
            attrs: { slot: "list-defind" },
            slot: "list-defind",
          }),
          _vm._v(" "),
          _vm.getFilterTable
            ? _c("tabBar", {
                ref: "R3tabBar",
                attrs: { slot: "list-tabBar", data: _vm.ag.tablequery },
                on: { tabClick: _vm.tabClick },
                slot: "list-tabBar",
              })
            : _vm._e(),
          _vm._v(" "),
          _c("AgTable", {
            ref: "agTableElement",
            style: _vm.agTableElementStyles,
            attrs: {
              slot: "list-table",
              columnRenderer: _vm.columnRendererHandler,
              agProcessColumns: _vm.agProcessColumns,
              agProcessRows: _vm.agProcessRows,
              processAgOptions: _vm.processAgOptions,
              moduleComponentName: _vm.moduleComponentName,
              "page-attribute": _vm.pageAttribute,
              datas: _vm.ag.datas,
              listData: _vm.listData,
              "css-status": _vm.ag.status4css,
              legend: _vm.ag.status4css,
              "is-filter-table": _vm.getFilterTable,
              "user-config-for-ag-table": _vm.userConfigForAgTable,
              "on-page-change": _vm.getFilterTable
                ? _vm.onPageChangeForFilterTable
                : _vm.onPageChange,
              "on-page-size-change": _vm.getFilterTable
                ? _vm.onPageSizeChangeForFilterTable
                : _vm.onPageSizeChange,
              "on-selection-changed": _vm.onSelectionChanged,
              "on-row-double-click": _vm.onRowDoubleClick,
              "on-sort-changed": _vm.onSortChange,
              "is-big": _vm.buttons.isBig,
              "on-column-moved": _vm.onColumnMoved,
              "on-column-pinned": _vm.onColumnPinned,
              "on-column-visible-changed": _vm.onColumnVisibleChanged,
              "on-cell-single-click": _vm.onCellSingleClick,
              "is-common-table": _vm.commonTable,
              "buttons-data":
                _vm.buttons.dataArray.waListButtonsConfig.waListButtons,
              "do-table-search": _vm.searchClickData,
            },
            on: {
              CommonTableCustomizedDialog: _vm.commonTableCustomizedDialog,
              "grid-ready": _vm.gridReady,
            },
            slot: "list-table",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.buttons.importData.importDialog
        ? _c("ImportDialog", {
            attrs: {
              name: _vm.buttons.importData.importDialog,
              visible: _vm.buttons.importData.importDialog,
              "show-close": true,
              title: _vm.buttons.importData.importDialogTitle,
              tablename: _vm.buttons.tableName,
              "main-id": _vm.buttons.tableId,
            },
            on: {
              confirmImport: _vm.searchClickData,
              closeDialog: _vm.closeDialog,
              imporSuccess: _vm.imporSuccess,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ErrorModal", {
        ref: "dialogRefs",
        attrs: {
          mask: _vm.buttons.dialogConfig.mask,
          title: _vm.buttons.dialogConfig.title,
          "content-text": _vm.buttons.dialogConfig.contentText,
          "footer-hide": _vm.buttons.dialogConfig.footerHide,
          confirm: _vm.buttons.dialogConfig.confirm,
        },
        on: {
          confirmDialog: function ($event) {
            return _vm.confirmDialog()
          },
        },
      }),
      _vm._v(" "),
      _c("dialogComponent", {
        ref: "dialogRef",
        attrs: {
          "popwin-message": _vm.popwinMessage,
          "id-array": _vm.buttons.selectIdArr,
          "select-row-data": _vm.buttons.selectArr,
          title: _vm.dialogComponentNameConfig.title,
          "obj-tab-action-dialog-config": _vm.objTabActionDialogConfig,
          mask: _vm.dialogComponentNameConfig.mask,
          "content-text": _vm.dialogComponentNameConfig.contentText,
          "footer-hide": _vm.dialogComponentNameConfig.footerHide,
          confirm: _vm.dialogComponentNameConfig.confirm,
          "dialog-component-name": _vm.dialogComponentName,
          isrefrsh: _vm.buttons.isrefrsh,
        },
        on: { dialogComponentSaveSuccess: _vm.dialogComponentSaveSuccess },
      }),
      _vm._v(" "),
      _vm.modifyDialogshow
        ? _c("modifyDialog", {
            ref: "dialogmodify",
            attrs: { title: _vm.activeTab.label },
            on: {
              "on-oncancle-success": _vm.onCancleSuccess,
              "on-save-success": _vm.onSaveSuccess,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.VirtualTableDialogshow
        ? _c("contenVirtualTable", {
            ref: "VirtualTableDialog",
            on: {
              "on-oncancle-success": _vm.R3GetVirtualTableClose,
              "on-save-success": _vm.R3GetVirtualTablesave,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }