<template>
  <div :class="classes">
    <!-- 新弹窗多选  :type="type" -->
    <PopDialog
      :fkobj="fkobj"
      ref="pop"
      :type="type"
      typeValue="popMultiSelect"
      :echoData="echoData"
      @on-row-click="rowclick"
      @on-getIsak="getIsak"
      @on-selection-change="rowSelect"
    >
      <div slot="button-list" class="button-list">
        <!-- <Button
          type="fcdefault"
          class="border"
          style="margin: 0 20px"
          @click="excludClick"
        >
           <checkbox v-model="exclude"></checkbox> 排除 
        </Button> -->
         <Button type="primary" style="margin: 0 20px" @click="chooseConditions">
          {{$t('choice')+$t("extensionProperty.condition")}}
        </Button>
        <Button type="primary" style="margin: 0 20px" @click="chooseItem">
          {{ $t("buttons.save") }}
        </Button>
       
      </div>
      <div slot="table-operation">
        <div class="dialog-operation">
          <div>
            <i
              class="ark-icon operation-icon iconfont border iconbj_open"
              @click="tableSellectALL"
            ></i>
          </div>
        </div>
        <div class="table-result">
          <div class="table-result-list border">
            <h5>
              <span
                >{{ $t("feedback.selected")}}
                <!-- <em>{{ resultData.total + tableTotal}}</em> -->
                </span
              ><i
                class="ark-icon iconfont iconbj_delete"
                @click="delectAll"
              ></i>
            </h5>
            <div
              class="table-result-bar"
              v-for="(item, i) in resultData.list"
              :key="i"
            >
              <template v-if="!item.type">
                <span>{{ item.screen_string }}</span>
              </template>
              <template v-if="item.type">
                <span
                  ><span>{{ $t("tips.contain") }}</span
                  >( {{ item.screen_string }} )</span
                >
              </template>
              <i class="iconfont iconbj_delete2" @click="delectLi(i)"></i>
            </div>
          </div>
        </div>
      </div>
    </PopDialog>
  </div>
</template>
<script>
import i18n from "../../assets/js/i18n";
import PopDialog from "../PopDialog.vue";
import { Version, classFix } from "../../constants/global";

let compleHttpRequest = undefined;
import(
  `../../__config__/actions/version_${Version()}/formHttpRequest/compleHttpRequest.js`
).then((data) => {
  compleHttpRequest = () => data.default;
});
// let fkHttpRequest = undefined;
// import(
//   `../../__config__/actions/version_${Version()}/formHttpRequest/fkHttpRequest.js`
// ).then((data) => {
//   fkHttpRequest = () => data;
// });

export default {
  name: "PopmultiSelect",
  components: { PopDialog },
  data() {
    return {
      type: "singlemop",
      showKey: "",
      resultData: {
        list: [], // 选中的值
        total: 0,
      }, // 选中结果
      resultDataPage: [], // 选中的id
      exclude: false, // 是否排除的配置
      ids: [],
      total: 0,
      sendMessage: {}, // 传参值
      tableName: "",
      IN: [], // 选中结果
      NOTIN: [], // 排除结果
      CONDITION:[], // 当前条件
      tableTotal:0, // 条件的值
      text: {
        // 后台 传值
        result: [],
      },
      formChangeData: {}, // 当前的选中值
    };
  },
  props: {
    fkobj: {
      type: Object,
      default() {
        return {};
      },
    },
    filter: {
      type: Object,
      default() {
        return {};
      },
    },
    default: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    classes() {
      return [`${classFix}PopmultiSelect`];
    },
  },
  created() {
    this.$t = i18n.t.bind(i18n);
    this.sendMessage = {};
    this.initSendMessage();
    this.initForm();
    // fix: 表格里的表单，无法从$route里拿到表名，需要自己从url地址去取出
  },
  mounted() {},
  methods: {
    checkedId() {
      // 提示
      this.$Message.error(this.$t('extensionProperty.selectOtherField'));
    },
    chooseConditions() {
      // 选中条件 (表单的查询条件
      let listsForm = this.$refs.pop.$refs.listsForm;
      if (listsForm) {
        listsForm.getFormData().then((res) => {
          this.formChangeData = JSON.parse(JSON.stringify(res));
          let screen_string = Object.keys(res).reduce((string, key) => {
            if (key) {
              let name = listsForm.ItemLists[key].coldesc;
              string = string + `${name}:${this.formChangeData[key]},`;
            }
            return string;
          }, "");
          let index = this.resultData.list.findIndex((x) => {
            return (
              JSON.stringify(this.formChangeData) === JSON.stringify(x.screen)
            );
          });
          if (index === -1) {
            this.resultData.list.push({
              type: "Conditions",
              screen: JSON.parse(JSON.stringify(this.formChangeData)),
              screen_string: screen_string,
            });
            this.initCondition();

            // 初始化条件
          }else{
            this.checkedId()
          }
          

          // this.multipleScreenResultCheck(this.sendMessage,this.CONDITION);
        });
      }
    },
    getlist(){
      if(JSON.stringify(this.CONDITION) === '{}'){
        this.tableTotal = 0
        return false;
      }
       const searchObject = {
        refcolid: Number(this.fkobj.colid),
        isdroplistsearch: true,
        startindex:0,
        range: 1,
        fixedcolumns:this.CONDITION
      };
      fkHttpRequest().fkQueryListPop({
        searchObject,
        serviceId: this.fkobj.serviceId,
        success: (res) => {
          this.tableTotal = res.data.data.totalRowCount;

        }
      })

    },
    initCondition(){
      // 重组条件
      this.CONDITION.push(this.formChangeData);

    },
    // initCondition(){
     
    //     // if(JSON.stringify(this.CONDITION)==='{}'){
    //     //     this.CONDITION = this.formChangeData;

    //     //   }else{
            
    //     //   }
    //     Object.keys(this.formChangeData).forEach((key)=>{
    //            if(!Array.isArray(this.formChangeData[key]) && this.formChangeData[key]){
    //               this.formChangeData[key] = this.formChangeData[key].replace(/,$/,'').split(',')
    //           }
    //             if(this.CONDITION[key]){
    //               if(!Array.isArray(this.CONDITION[key])){
    //                   this.CONDITION[key] = [this.CONDITION[key]];
    //               }
    //               if(Array.isArray(this.formChangeData[key])){
    //                 this.CONDITION[key] = this.CONDITION[key].concat(this.formChangeData[key])
    //               }else{
    //                 this.CONDITION[key].push(this.formChangeData[key])
    //               }
    //             }else{
    //               this.CONDITION[key] = this.formChangeData[key]
    //             }
    //         });
    //       // this.getlist();
    // },
    delectCondition(data){
      // 删除查询条件
      let index = this.CONDITION.findIndex((x)=>{
        console.log(data,'=====',x);
        return JSON.stringify(x) === JSON.stringify(data)
      })
      if(index!== -1){
          this.CONDITION.splice(index,1);
      }
      console.log(this.CONDITION,'=====1212');

    },
    // delectConditionold(data){
    //   // 删除查询条件
    //     Object.keys(data).forEach((key)=>{
    //             if(this.CONDITION[key]){
    //               if(data[key]){
    //                 if(Array.isArray(data[key])){
    //                    data[key].forEach((item)=>{
    //                       this.delectKey(key,item)
    //                    });
                      
    //                 }else{
    //                     this.delectKey(key,data[key])
    //                 }
    //               }
    //             }
    //     });
    //   console.log(this.CONDITION,'====this.CONDITION=');
    // },
    // delectKey(key,value){
    //    if(Array.isArray(this.CONDITION[key])){
    //         const nIndex =  this.CONDITION[key].indexOf(value);
    //         this.CONDITION[key].splice(nIndex,1);
    //         if(this.CONDITION[key].length<1){
    //         delete this.CONDITION[key]
    //         }
    //     }else{
    //         delete this.CONDITION[key]
    //     }

    // },
    chooseItem() {
      this.$parent.$parent.$parent.attachFilterOk(this);
      console.log(this.$parent.$parent.$parent);
    },
    cancle() {
      this.$parent.$parent.$parent.attachFilterCancel(this);
    },
    savemessage() {
      //   初始化 默认值
      this.sendMessage.TABLENAME = this.sendMessage.reftable;
      const s_value = this.sendMessage;
      s_value.EXCLUDE = this.EXCLUDE;
      s_value.IN = this.IN;
      s_value.NOTIN = this.NOTIN;
      s_value.CONDITION = this.CONDITION;
      this.resultData.total = this.resultData.total+this.tableTotal;
      this.tableTotal = 0;
      return {
        value: s_value,
        text: JSON.stringify({
          total:  this.resultData.total,
          idArray: [],
          lists: { result: this.resultData },
        }),
      };
    },
    getIsak(isak) {
      this.isak = isak;
    },
    rowdblclick(parms) {
      // this.parms = parms;
      // this.$parent.ok();
      // this.$parent.cancel();
    },
    delectAll() {
      // 清空全部
      // this.$refs.pop.$refs.selection.$refs.table.selectAll(false);
      this.resultDataPage = [];
      this.resultData = {
        list: [],
        total: 0,
      };
      this.NOTIN = [];
      this.IN = [];
      this.CONDITION = [];
      this.tableTotal = 0;
      this.getSelection();
    },
    delectLi(index) {
      // 删除当前条
      if (this.resultData.list[index].exclude) {
        this.delectID(this.resultData.list[index], this.NOTIN);
      } else {
        this.delectID(this.resultData.list[index], this.IN);
      }
      if(this.resultData.list[index].type){
         this.delectCondition(this.resultData.list[index].screen);
      }

      this.resultData.list.splice(index, 1);
      if (this.resultData.list.length > 0) {
        this.resultData.total = this.resultData.total - 1;
        // this.multipleScreenResultCheck(this.sendMessage, 1, "init");
      } else {
        this.resultData.total = 0;
      }
      // console.log(this.SelectionData,'====1212===');
      this.getSelection();
      // this.SelectionData.row = this.echoData(this.SelectionData.row).concat([]);
    },
    delectID(resultData, arr) {
      const indexI = arr.findIndex(
        (x) => Number(x) === Number(resultData.screen)
      );
      if (indexI !== -1) {
        arr.splice(indexI, 1);
      }
    },
    tableSellectALL() {
      // 表格全选功能
      let tableVm = this.$refs.pop.$refs.selection;

      this.removeDuplicates(tableVm.$refs.table.rebuildData);
      this.getSelection();
    },
    excludClick() {
      // 排除点击事件
      this.exclude = !this.exclude;
    },
    // select(parms,current){
    //   console.log(parms,current);
    //   current.exclude = this.exclude;

    // },
    rowSelect(parms, index) {
      // 添加排除的配置
      // let excludeData = JSON.parse(JSON.stringify(parms)).map((item) => {
      //   // item.exclude = this.exclude;
      //   return item;
      // });
      this.removeDuplicates(parms);
    },
    initSendMessage() {
      this.sendMessage.reftable = this.fkobj.reftable;
      if (Object.prototype.hasOwnProperty.call(this.filter, "value")) {
        if (this.filter.text) {
          //  有默认值
          this.text.result = JSON.parse(this.filter.text).result;
        }
        //  有默认值
        this.sendMessage = { ...JSON.parse(JSON.stringify(this.filter.value)) };
      }
      this.sendMessage.reftable = this.fkobj.reftable;
      this.sendMessage.PAGENUM = 1;
      this.sendMessage.PAGESIZE = 50;

      // 谢世华， this.sendMessage中不存在EXCLUDE字段会保存，暂时注释
      this.sendMessage.TABLENAME = this.fkobj.reftable;
    },
    initForm() {
      if (this.default && this.default.length > 0 && this.default[0].ID) {
        const arr = this.default.reduce((array, current) => {
          if (/lists/.test(current.ID)) {
            // 兼容单对象保存
            try {
              let result = JSON.parse(current.ID);
                            console.log(result.value)

              this.IN = result.value && result.value.IN;
              this.NOTIN = result.value && result.value.NOTIN;
              this.resultData.list = result.lists.result;
              this.CONDITION = result.value && result.value.CONDITION;
              // this.getlist();
            } catch (error) {}
          } else {
            // 兼容模糊查询
            this.resultData.list.push({
              exclude: false,
              id_list: [current.ID],
              screen: current.ID,
              screen_string: current.Label,
            });
            this.IN.push(current.ID);
          }
          return array;
        }, []);
        this.multipleScreenResultCheck(this.sendMessage, 1, "init");
      } else {
        if (Object.prototype.hasOwnProperty.call(this.filter, "text")) {
          if (this.filter.text && !this.filter.value) {
            //  兼容导入
            this.text.result = this.filter.text.result;
            this.resultData.total = this.text.result.length;
            this.resultData.list = this.text.result.reduce((arr, item) => {
              if (!item.id_list) {
                item.id_list = item.screen;
              }
              this.IN.push(item.screen);
              arr.push(item);
              return arr;
            }, []);
            if (this.text.result.length > 1) {
              this.multipleScreenResultCheck(this.sendMessage, 1, "init");
            }
          }
        }
      }
    },
    savObjemessage() {
      // 保存的值
      const sendMessage = {
        idArray: [],
        lists: {
          result: this.resultData.list,
        },
        total: this.resultData.total + this.tableTotal,
        value: {
          CONDITION: this.CONDITION,
          EXCLUDE: [],
          GLOBAL: "",
          IN: this.IN,
          NOTIN: this.NOTIN,
          TABLENAME: "POINT",
        },
      };
      return JSON.stringify(sendMessage);
    },
    removeDuplicates(parms) {
      // 重新渲染结果列表
      //this.resultData = [];
      // this.IN = [];
      // this.NOTIN = [];
      //this.resultDataPage = this.resultDataPage.concat(parms);
     parms.forEach((option) => {
        let check = this.resultData.list.some((x) => {
          // 结果和选中id 不重复才可写入
          return Number(x.screen) === Number(option.ID);
        });
        if (!check) {
          if (this.exclude) {
            this.NOTIN.push(Number(option.ID));
          } else {
            this.IN.push(Number(option.ID));
          }

          this.resultData.list.push({
            exclude: this.exclude,
            id_list: [option.ID],
            screen: option.ID,
            screen_string: option[this.isak],
          });
        }else{
           this.checkedId()
        }
      });
      this.multipleScreenResultCheck(this.sendMessage);
    },
    multipleScreenResultCheck(sendMessage) {
      if (this.IN.length < 1) {
        return;
      }
      compleHttpRequest().multipleScreenResultCheck({
        searchObject: {
          param: {
            TABLENAME: sendMessage.TABLENAME,
            CONDITION: [],
            GLOBAL: "",
            PAGENUM: sendMessage.PAGENUM,
            PAGESIZE: sendMessage.PAGESIZE,
            EXCLUDE: [],
            IN: this.IN,
            NOTIN: this.NOTIN,
          },
        },
        success: (res) => {
          let data = res.data.data.data;
          this.resultData.total = data.total;
        },
      });
    },
    rowclick(parms, index, event, column) {
      this.removeDuplicates([parms]);
      // this.$refs.pop.$refs.selection.$refs.table.handleCurrentRow(
      //   "highlight",
      //   index
      // );
    },
    cancelDialog() {
      this.$parent.cancel();
    },
    getSelection() {
      // 获取回填index
      // let tableVm = this.$refs.pop.$refs.selection;
      // tableVm.multiple = false;
      // tableVm.highlightRow = true;
      // tableVm.multipleRow = true;
      // let self = this;
      // tableVm.selectedIndex = [];
      // setTimeout(() => {
      //   console.log( this.resultData.list,'===== this.resultData.list=')
      //   tableVm.$refs.table.rebuildData.forEach((item, index) => {
      //     tableVm.$refs.table.objData[index]._isHighlight = false;
      //     self.resultData.list.forEach((option) => {
      //       if (Number(option.screen) === Number(item.ID)) {
      //         tableVm.$refs.table.handleCurrentRow("highlight", index);
      //       }
      //     });
      //   });
      // }, 200);
    },
    echoData() {
      // 左侧表格和右侧结果联动
      // selectedIndex
      this.getSelection();
    },
  },
};
</script>
