var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", { staticClass: "titleTOP" }, [
      _vm._v(_vm._s(_vm.$t("tips.phoneLogin"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "divAccount" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: require("../../assets/image/account.png") },
      }),
      _vm._v(" "),
      _c("input", {
        ref: "phone",
        staticClass: "username",
        attrs: {
          type: "text",
          value: "",
          placeholder: _vm.$t("messages.enterPhone"),
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "divCode" },
      [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("../../assets/image/password.png") },
        }),
        _vm._v(" "),
        _c("input", {
          ref: "sendcode",
          staticClass: "pwd code",
          attrs: { value: "", placeholder: _vm.$t("messages.enterSMS") },
        }),
        _vm._v(" "),
        _c(
          "Button",
          {
            staticClass: "erCodeBtn",
            attrs: { disabled: _vm.sended },
            on: { click: _vm.sendErCode },
          },
          [
            _vm._v(
              _vm._s(
                _vm.btnTips === 1
                  ? _vm.$t("tips.getVerificationCode")
                  : "" + this.$t("messages.reacquire") + this.count + "s"
              )
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.loginType
      ? _c("div", { staticClass: "divToggle" }, [
          _c("span", { staticClass: "sanjiao" }, [
            _c("img", {
              staticClass: "toggle home",
              attrs: { src: require("../../assets/image/home.png") },
              on: { click: _vm.toggles },
            }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }