var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("v-object-button"),
      _vm._v(" "),
      _c(
        "div",
        { ref: "detailContent", staticClass: "verticalTableDetailContent" },
        [_vm._t("v-object-from"), _vm._v(" "), _vm._t("v-object-TabPanels")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }