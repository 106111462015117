var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "colorComponent", staticClass: "color_container" }, [
    _c("div", { staticClass: "left_container" }, [
      _c(
        "div",
        { staticClass: "left_button" },
        [
          _c(
            "Button",
            { attrs: { type: "fcdefault" }, on: { click: _vm.listAllColor } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("messages.listAllColor")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "leftTable", staticClass: "left_table" },
        [
          _c("Table", {
            attrs: {
              columns: _vm.leftTableColumns,
              height: _vm.leftTableHeight,
              "highlight-row": "",
              data: _vm.leftTableData,
            },
            on: {
              "on-row-click": _vm.leftTableRowClick,
              "on-row-dblclick": _vm.rightSingle,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "center_container" }, [
      _c("div", { staticClass: "right_single" }, [
        _c("i", {
          staticClass: "iconfont iconbj_right",
          on: {
            click: function ($event) {
              return _vm.rightSingle()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "right_double" }, [
        _c("i", {
          staticClass: "iconfont iconbj_transfer_right",
          on: {
            click: function ($event) {
              return _vm.rightDouble()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "left_single" }, [
        _c("i", {
          staticClass: "iconfont iconbj_left",
          on: {
            click: function ($event) {
              return _vm.leftSingle()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "left_double" }, [
        _c("i", {
          staticClass: "iconfont iconbj_transfer_left",
          on: {
            click: function ($event) {
              return _vm.leftDouble()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "right_container" }, [
      _c(
        "div",
        { staticClass: "right_input" },
        [
          _c("Input", {
            attrs: {
              placeholder: _vm.$t("messages.enterColorInfo"),
              icon: "ios-add-circle-outline",
            },
            on: {
              "on-change": _vm.addColorInputChange,
              "on-click": _vm.addIconClick,
              "on-enter": _vm.addIconClick,
            },
            model: {
              value: _vm.addColorInputValue,
              callback: function ($$v) {
                _vm.addColorInputValue = $$v
              },
              expression: "addColorInputValue",
            },
          }),
          _vm._v(" "),
          _c(
            "Button",
            {
              attrs: { slot: "prepend" },
              on: { click: _vm.addColor },
              slot: "prepend",
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("messages.addColor")) + "\n      "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "rightTable", staticClass: "right_table" },
        [
          _c("Table", {
            attrs: {
              columns: _vm.rightTableColumns,
              data: _vm.rightTableData,
              "highlight-row": "",
              height: _vm.rightTableHeight,
            },
            on: {
              "on-row-click": _vm.rightTableRowClick,
              "on-row-dblclick": _vm.leftSingle,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }