<template>
  <div :class="classes">
    <div class="buttonGroup">
      <Button
        v-for="(item, index) in buttonsData"
        :key="index"
        type="fcdefault"
        class="Button"
        @click="btnClick(item)"
        :loading="item.webname === 'CmenuPermissionSaveCmd' && saveLoading"
      >
        {{ item.webdesc }}
      </Button>
      <!-- <Button
        type="fcdefault"
        class="Button"
        @click="customize"
      >
        测试跳转到单对象界面
      </Button> -->
      <!-- <Button
        type="fcdefault"
        class="Button"
        @click="customize1"
      >
       返回
      </Button> -->
    </div>
    <div class="content">
      <div class="contentLeft">
        <Input
          v-if="false"
          :placeholder="$t('messages.enterUserName')"
          clearable
          icon="ios-search"
          @on-change="searchInputChange"
        >
        <span slot="prepend">{{$t('buttons.find')}}</span>
        </Input>
        <div class="menuContainer">
          <tree
                  ref="ztree"
                  :tree-datas="treeConfigData"
                  @menuTreeChange="menuTreeChange"
                  @treeSearch="treeSearch"
          />
        </div>
        <!--<ul class="menuContainer">-->
        <!--<li-->
        <!--v-for="(item, index) in menuList"-->
        <!--:key="index"-->
        <!--class="menuList"-->
        <!--:class="index === menuHighlightIndex? 'menuHighlight':''"-->
        <!--@click="menuClick(index, item)"-->
        <!--&gt;-->
        <!--{{ item.NAME }}-->
        <!--</li>-->
        <!--</ul>-->
      </div>
      <div class="contentRight">
        <div class="left-tree">
          <Tree
            ref="tree"
            :data="treeData"
            @on-select-change="treeChange"
          />
        </div>
        <div class="right-list">
          <div class="upper-part">
            <div
              ref="upperTable"
              class="upper-table"
            >
              <div
                class="upper-table-tabth"
                :style="{left: upperTableTabthLeft}"
              >
                <table>
                  <thead>
                    <tr>
                      <th
                        ref="functionColumnTh"
                        class="functionColumnClass"
                        :style="{'min-width': `${functionColumnWidth}px`}"
                      >
                        {{$t('tips.function')}}
                      </th>
                      <th
                        v-for="(item, index) in columns"
                        :key="index"
                        :ref="`tableTabth${index}`"
                        :style="{width: theadThMinWidth}"
                      >
                        <Checkbox
                          v-model="item[`${item.key}Value`]"
                          @on-change="(currentValue) => tabthCheckboxChange(currentValue, {index: index, column: item})"
                        />{{ item.title }}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div
                v-show="tableData.length === 0"
                class="upper-table-tabtd-empty"
              >
                {{$t('tips.noData')}}
              </div>
              <div
                v-show="tableData.length > 0"
                ref="upperTableTabtd"
                class="upper-table-tabtd"
                @scroll="upperTableTbodyScroll"
              >
                <table>
                  <tbody>
                    <tr
                      v-for="(item, index) in tableData"
                      :key="index"
                      :class="upperTableTbodyHighlightIndex === index ? 'upper-table-tabtd-highlight' : ''"
                      @click="upperTableTbodyClick(item, index)"
                    >
                      <td
                        ref="functionColumnTd"
                        :style="{'min-width': functionColumnTdWidth}"
                      >
                        {{ item.description }}
                      </td>
                      <td
                        v-for="(tem, temIdx) in columns"
                        :key="temIdx"
                        :style="{'min-width': tem.tbodyWidth}"
                      >
                        <Checkbox
                          v-model="item[`${tem.key}Value`]"
                          :disabled="item[`${tem.key}Disabled`]"
                          @on-change="(currentValue) => rowCheckboxChange(currentValue, {row: item, index: index, column: tem})"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="bottom-part">
            <div class="bottom-table">
              <!--<Table-->
              <!--class="table"-->
              <!--highlight-row-->
              <!--:height="true"-->
              <!--:data="extendTableData"-->
              <!--:columns="columnsBottom"-->
              <!--/>-->
              <div class="bottom-table-tabth">
                <table>
                  <thead>
                    <tr>
                      <th>{{$t('messages.extensions')}}</th>
                      <th>{{$t('tips.function')}}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div
                v-show="extendTableData.length === 0"
                class="bottom-table-tbody-empty"
              >
                {{$t('tips.noData')}}
              </div>
              <div
                v-show="extendTableData.length > 0"
                class="bottom-table-tbody"
              >
                <table>
                  <tbody>
                    <tr
                      v-for="(item, index) in extendTableData"
                      :key="index"
                      :class="bottomTableTbodyHighlightIndex === index ? 'bottom-table-tbody-highlight' : ''"
                      @click="bottomTableTbodyClick(index)"
                    >
                      <td style="width: 200px">
                        <Checkbox
                          :value="item.permission === 128 ? true : false"
                          @on-change="(currentValue) => extendFunctionCheckboxChange(currentValue, {row: item, index: index})"
                        />{{ item.description }}
                      </td>
                      <td>
                         <template
                            v-for="(checkItem, j) in item.children"
                          >
                         
                            <Checkbox
                              :key="j"
                              :value="checkItem.permission === 128"
                              @on-change="(currentValue) => functionCheckboxChange(currentValue, {row: item, index: index, itemIndex: j})"
                            />{{ checkItem.description ? checkItem.description : '' }}
                          </template>
                        <!-- <Checkbox
                          v-show="item.children && item.children.length > 0"
                          :value="item.children && item.children.length > 0 ? item.children[0].permission === 128 : false"
                          @on-change="(currentValue) => functionCheckboxChange(currentValue, {row: item, index: index})"
                        />{{ item.children.length > 0 ? item.children[0].description : '' }} -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="copyPermission"
      closable
      :width="420"
      mask
      footer-hide
      :title="$t('tips.copyPermission')"
    >
      <div class="modalContent">
        <div class="itemContent">
          <div class="labelContent">
            <div class="labelTip">
              *
            </div>
            <div>{{$t('messages.originalRole')}}:</div>
          </div>
          <DropDownSelectFilter
            class="itemCom"
            :total-row-count="totalRowCount"
            :page-size="dropPageSize"
            :auto-data="singleAutoData"
            :columns-key="['NAME']"
            :hidecolumns="['ID']"
            :default-selected="singleDefaultSelected"
            :data="singleDropDownSelectFilterData"
            @on-fkrp-selected="singleDropSelected"
            @on-page-change="singleDropPageChange"
            @on-popper-hide="singlePopperHide"
            @on-clear="singleDropClear"
            @on-input-value-change="singleInputChange"
          />
        </div>
        <div class="itemContent">
          <div class="labelContent">
            <div class="labelTip">
              *
            </div>
            <div>{{$t('messages.targetRole')}}:</div>
          </div>
          <DropDownSelectFilter
            :single="false"
            class="itemCom"
            :total-row-count="totalRowCount"
            :page-size="dropPageSize"
            :columns-key="['NAME']"
            :hidecolumns="['ID']"
            :default-selected="multipleDefaultSelected"
            :auto-data="multipleAutoData"
            :data="multipleDropDownSelectFilterData"
            @on-fkrp-selected="multipleDropSelected"
            @on-page-change="multipleDropPageChange"
            @on-popper-hide="multiplePopperHide"
            @on-clear="multipleDropClear"
            @on-input-value-change="multipleInputChange"
          />
        </div>
        <div class="itemContent">
          <div class="labelContent">
            <div class="labelTip">
              *
            </div>
            <div>{{$t('messages.copyMethod')}}:</div>
          </div>
          <Select
            v-model="copyType"
            class="itemCom"
            :placeholder="$t('messages.selectCopyMethod')"
          >
            <Option value="cover">
              {{$t('messages.overwritePermission')}}
            </Option>
            <Option value="copy">
              {{$t('messages.keepPermission')}}
            </Option>
          </Select>
        </div>
        <div class="modalButton">
          <Button
            type="fcdefault"
            class="Button"
            @click="modalConfirm"
          >
            {{$t('buttons.confirm')}}
          </Button>
          <Button
            type="fcdefault"
            class="Button"
            @click="modalCancel"
          >
            {{$t('buttons.cancel')}}
          </Button>
        </div>
      </div>
    </Modal>
    <Spin v-show="spinShow" fix>
      <Icon
        type="ios-loading"
        size="48"
        class="demo-spin-icon-load"
      />
      <div>Loading</div>
    </Spin>
  </div>
</template>

<script>
  /* eslint-disable arrow-parens,no-lonely-if,no-empty */
  // import network, { urlSearchParams } from '../../__utils__/network';
  import { Version, classFix } from '../../constants/global';
  import store from '../../__config__/store.config';
  import tree from '../../__component__/tree';

  const functionPowerActions = () => require(`../../__config__/actions/version_${Version()}/functionPower.actions.js`);

  export default {
    components: {tree},
    data() {
      return {
        pageInit: false,
        isSaveError: false, // 是否保存失败
        spinShow: false, // loading是否显示
        saveLoading: false,

        copyPermission: false, // 复制权限弹框
        copyType: '', // 复制权限弹框  复制方式
        singlePermissionId: null, // 复制权限外键单选id
        multiplePermissionId: null, // 复制权限外键多选id
        backupsDropData: [], // 备份复制权限外键数据
        singleDropDownSelectFilterData: {}, // 复制权限外键单选数据
        singleAutoData: [], // 复制权限外键单选模糊搜索数据
        singleDefaultSelected: [], // 复制权限单选默认数据
        multipleDefaultSelected: [], // 复制权限多选默认数据
        multipleDropDownSelectFilterData: {}, // 复制权限外键多选数据
        multipleAutoData: [], // 复制权限外键多选模糊搜索数据
        totalRowCount: 0, // 复制权限外键数据的totalRowCount
        dropPageSize: 10, // 复制权限外键数据的pageSize

        buttonsData: [], // 按钮数据
        menuHighlightIndex: 0, // 菜单高亮的index
        menuList: [], // 菜单数据
        groupId: '', // 菜单id
        newGroupId: '', // 切换菜单时，当前切换的id

        menuTreeData: [], // 菜单树数据
        menuTreeQuery: '', // 菜单树检索的值
        oldMenuTreeObj: null, // 上一次选中的菜单节点的数据
        newMenuTreeObj: null, // 当前选中的菜单节点的数据

        treeData: [], // 树数据
        adSubsystemId: '', // 树节点ID
        adTableCateId: null, // 树子节点ID
        newAdSubsystemId: '', // 树节点ID
        newAdTableCateId: null, // 树子节点ID
        oldTreeObj: null, // 上一次选中的树节点的数据
        newTreeObj: null, // 当前选中的树节点的数据

        tableDefaultSelectedRowIndex: 0, // 表格默认选中的行的index
        tableData: [], // 表格数据
        backupsTableData: [], // 备份表格数据
        tableSaveData: [], // 表格修改后要保存的数据
        // columns: [
        //   {
        //     key: 'see',
        //     seeValue: false,
        //   },
        //   {
        //     key: 'edit',
        //     editValue: false,
        //   },
        //   {
        //     key: 'delete',
        //     deleteValue: false,
        //   },
        //   {
        //     key: 'toVoid',
        //     toVoidValue: false,
        //   },
        //   {
        //     key: 'commit',
        //     commitValue: false,
        //   },
        //   {
        //     key: 'unCommit',
        //     unCommitValue: false,
        //   },
        //   {
        //     key: 'export',
        //     exportValue: false,
        //   },
        //   {
        //     key: 'print',
        //     printValue: false,
        //   },
        //   {
        //     key: 'extend',
        //     extendValue: false,
        //   }
        // ], // 表格头部,
        extendTableData: [], // 扩展功能表格数据
        columnsBottom: [
          {
            title: this.$t('messages.extensions'),
            key: 'extendFunction',
            width: 200,
            render: (h, params) => h('div', [
              h('Checkbox', {
                style: {},
                props: {
                  value: params.row.permission === 128
                },
                on: {
                  'on-change': (val) => this.extendFunctionCheckboxChange(val, params)
                }
              }, params.row.description)
            ]),
          },
          {
            title: this.$t('tips.function'),
            key: 'function',
            render: (h, params) => h('div', [
              h(params.row.children.length > 0 ? 'Checkbox' : '', {
                style: {},
                props: {
                  value: params.row.children && params.row.children.length > 0 ? params.row.children[0].permission === 128 : false
                },
                on: {
                  'on-change': (val) => this.functionCheckboxChange(val, params)
                }
              }, params.row.children.length > 0 ? params.row.children[0].description : '',)
            ]),
          }
        ], // 扩展功能表格头部

        columns: [
          {
            title: this.$t('buttons.view'),
            key: 'see',
            seeValue: false,
            tbodyWidth: '62px'
          },
          {
            title: this.$t('buttons.edit'),
            key: 'edit',
            editValue: false,
            tbodyWidth: '62px'
          },
          {
            title: this.$t('buttons.delete'),
            key: 'delete',
            deleteValue: false,
            tbodyWidth: '62px'
          },
          {
            title: this.$t('buttons.invalid'),
            key: 'toVoid',
            toVoidValue: false,
            tbodyWidth: '62px'
          },
          {
            title: this.$t('buttons.submit'),
            key: 'commit',
            commitValue: false,
            tbodyWidth: '62px'
          },
          {
            title: this.$t('buttons.antiSubmit'),
            key: 'unCommit',
            unCommitValue: false,
            tbodyWidth: '74px'
          },
          {
            title: this.$t('buttons.export'),
            key: 'export',
            exportValue: false,
            tbodyWidth: '62px'
          },
          {
            title: this.$t('buttons.print'),
            key: 'print',
            printValue: false,
            tbodyWidth: '62px'
          },
          {
            title: this.$t('tips.expand'),
            key: 'extend',
            extendValue: false,
            tbodyWidth: '62px'
          },
        ], // 表格表头
        upperTableTabthLeft: '0px',
        functionColumnWidth: 100, // 功能列的表头
        functionColumnTdWidth: '100px', // 功能列的表体的宽度
        theadThMinWidth: '62px', // 表头th的最小宽度，单位px
        unCommitThMinWidth: '74px', // 反提交的宽度
        upperTableTbodyHighlightIndex: 0, // 上边表格高亮的下标
        bottomTableTbodyHighlightIndex: null, // 下边表格高亮的下标

        menuPromise: null,
        treePromise: null,
      };
    },
    watch: {
      copyPermission(val) {
        if (val) {
          this.getCopyPermissionData();
        } else {
          this.singleDefaultSelected = [];
          this.multipleDefaultSelected = [];
          this.singleAutoData = [];
          this.multipleAutoData = [];
          this.copyType = '';
        }
      }
    },
    computed: {
      classes: () => `${classFix}functionPower`,
      treeConfigData() {
        let data = this.treeData;  
        if(data){
          return this.getTreeConfig();
        }else{
          return this.getTreeConfig();
        }
      },
    },
    created() {
      this.refresh();
      this.getButtonData();
    },
    mounted() {
      if (!this._inactive) {
        window.addEventListener('resize',
                                this.fixTableColumnWidth());
        window.addEventListener('doCollapseHistoryAndFavorite',
                                this.fixTableColumnWidth());
      }
    },
    updated() {
      this.$nextTick(() => {
        if (!this.pageInit && !this.spinShow) {
          this.selectFirstOnce();
        }
      })
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.fixTableColumnWidth);
      window.removeEventListener('doCollapseHistoryAndFavorite', this.fixTableColumnWidth);
    },
    activated() {
      this.fixTableColumnWidth();
    },
    methods: {
      bottomTableTbodyClick(index) {
        this.bottomTableTbodyHighlightIndex = index;
      }, // 下边表格行单击
      upperTableTbodyClick(row, index) {
        this.bottomTableTbodyHighlightIndex = null;
        this.upperTableTbodyHighlightIndex = index;
        this.tableRowClick(row, index);
      }, // 上边表格表体点击
      upperTableTbodyScroll(e) {
        this.upperTableTabthLeft = `${-e.target.scrollLeft}px`;
      }, // 上边表格表体滚动
      fixTableColumnWidth() {
        if (this.tableData.length > 0) {
          this.$nextTick(() => {
            const { upperTable, functionColumnTd, functionColumnTh } = this.$refs;
            if (functionColumnTd) {
              this.functionColumnWidth = functionColumnTd[0].offsetWidth;
            }
            const upperTableWidth = upperTable.offsetWidth;
            const theadThWidth = (upperTableWidth - this.functionColumnWidth) / 9;
            if (theadThWidth > 62) {
              this.theadThMinWidth = `${(theadThWidth / upperTableWidth) * 100}%`;
            } else {
              this.theadThMinWidth = '62px';
            }
            this.$nextTick(() => {
              this.functionColumnTdWidth = `${functionColumnTh.offsetWidth}px`;
              this.columns.map((item, index) => {
                const tableTabth = `tableTabth${index}`;
                item.tbodyWidth = `${this.$refs[tableTabth][0].offsetWidth}px`;
                return item;
              });
            });
          });
        }
      }, // 计算表格的列宽
      refresh() {
        // console.log('refresh-Promise')
        this.spinShow = true;
        this.menuPromise = new Promise((resolve, reject) => this.getMenuData(resolve, reject));
        this.treePromise = new Promise((resolve, reject) => this.getTreeData(resolve, reject));
        Promise.all([this.menuPromise, this.treePromise]).then(() => {
          this.groupId && this.getTableData();
          setTimeout(()=>{
            this.selectFirstOnce();
          },1000)
        });


      }, // 刷新数据
      refreshButtonClick() {
        this.isSaveError = false;
        if (this.checkNoSaveData('refresh')) {
        } else {
          this.$refs.ztree.clearInputVal();
          this.$refs.ztree.search();
          this.refresh();
          // this.selectFirstOnce();
        }
      }, // 刷新按钮
      checkNoSaveData(type) {
        this.getSaveData();
        if (this.tableSaveData.length > 0) {
          this.$Modal.fcWarning({
            title: this.$t('feedback.alert'),
            mask: true,
            showCancel: true,
            content: this.$t('messages.ifSave'),
            onOk: () => {
              this.adSubsystemId = this.newAdSubsystemId;
              this.adTableCateId = this.newAdTableCateId;
              this.savePermission(type);
            },
            onCancel: () => {
              console.log('type', type)
              this.tableSaveData = [];
              if (type === 'refresh') {
                this.pageInit = false;
                this.refresh();
                // setTimeout(() => this.selectFirstOnce(), 2000);
              } else {
                this.groupId = this.newGroupId;
                this.adSubsystemId = this.newAdSubsystemId;
                this.adTableCateId = this.newAdTableCateId;
                this.getTableData();
              }
            }
          });
          return true;
        }
        return false;
      }, // 校验是否有未保存的数据
      getButtonData() {
        const params = { AD_ACTION_NAME: 'functionPermission' };
        functionPowerActions().fetchActionsInCustomizePage({
          params,
          success: (res) => {
            if (res.data.code === 0) {
              const buttonsData = res.data.data;
              if (Version() === '1.4') {
                buttonsData.push({
                  webdesc: this.$t('buttons.refresh')
                });
              }
              const saveObj = buttonsData.find(item => item.webname === 'CmenuPermissionSaveCmd');
              const copyObj = buttonsData.find(item => item.webname === 'copyPermissionCmd');
              const refreshObj = buttonsData.find(item => item.webdesc === this.$t('buttons.refresh'));
              if (saveObj) {
                this.buttonsData.push(saveObj);
              }
              if (copyObj) {
                this.buttonsData.push(copyObj);
              }
              if (refreshObj) {
                this.buttonsData.push(refreshObj);
              }
            }
          }
        });
      }, // 获取按钮数据
      menuClick(index, item) {
        this.menuHighlightIndex = index;
        this.groupId = item.ID;
        const treePromise = new Promise((resolve, reject) => {
          this.getTreeData(resolve, reject);
        });
        treePromise.then(() => {
          this.getTableData();
        });
      }, // 点击左侧的菜单
      searchInputChange(e) {
        console.log(e,'=====');
        this.menuTreeQuery = e.target.value;
      }, // 检索输入框值改变
      menuTreeChange(val, item, flag) {
        const onceFetch = this.groupId === item;
        // console.log('onceFetch', onceFetch)
        // console.log('this.groupId', this.groupId)
        // console.log('item', item)
        this.oldMenuTreeObj = JSON.parse(JSON.stringify(this.newMenuTreeObj));
        this.newMenuTreeObj = JSON.parse(JSON.stringify(item));
        // if (val.length === 0) {
        //   this.$refs.menuTree.handleSelect(item.nodeKey);
        // }
        this.newGroupId = flag ? item : '';
        this.groupId = flag ? item : '';
        if (!this.isSaveError) {
          if (this.checkNoSaveData('menuTree')) {
          } else {
            if (flag) {
              this.spinShow = true;
              const treePromise = new Promise((resolve, reject) => {
                this.spinShow = false;
                !onceFetch && this.getTreeData(resolve, reject);
              });
              treePromise.then(() => {
                if (onceFetch) {
                  this.spinShow = false;
                  return;
                }
                this.getTableData();
              });
            }
          }
        }
      }, // 左侧树点击
      getTreeData(resolve, reject) {
        functionPowerActions().getMenuTree({
          success: (res) => {
            if (res.data.code === 0) {
              resolve();
              const resData = res.data.data;
              this.restructureTreeDada(resData);
              this.treeData = [...resData];
            } else {
              reject();
            }
          }
        });
        // network.post('/p/cs/getMenuTree', urlSearchParams({}))
        //   .then((res) => {
        //     if (res.data.code === 0) {
        //       resolve();
        //       const resData = res.data.data;
        //       this.restructureTreeDada(resData);
        //       this.treeData = [...resData];
        //     } else {
        //       reject();
        //     }
        //   })
        //   .catch((err) => {
        //     reject();
        //     throw err;
        //   });
      }, // 获取树数据
      restructureTreeDada(data) {
        data.map((item) => {
          if (item.nodeType === 'ROOT') {
            item.expand = true;
            item.selected = true;
            this.oldTreeObj = item;
            this.newTreeObj = item;
            this.adSubsystemId = item.ad_subsystem_id;
            this.newAdSubsystemId = item.ad_subsystem_id;
            this.adTableCateId = item.ad_tablecategory_id;
          }
          item.title = item.description;
          if (item.children && item.children.length > 0 && item.children.find(cur => cur.children.length > 0)) {
            this.restructureTreeDada(item.children);
          } else {
            delete item.children;
          }
          return item;
        });
      }, //  整合树数据

      // 数据
      getTreeConfig() {
        return async () => {
          const treeData = {
            data: [], // 树结构列表数据
            // name: 'eeee', // 定义查询参数
            query: {// 支持配置多参数
              name: 'NAME', // 参数中的key:需要筛选的字段
              id: 'ID'
            },
            placeholder: '', // placeholder自定义
            searchData: {// 定义查询接口参数
              table: '1111',
              value: 'value'
            }

          };
          await this.menuPromise.then(res => treeData.data = this.handleChildrenKey(res));
          return treeData;
        }
      },
      handleChildrenKey(arr) {
        return arr.map(v => {
          v.CHILDREN = v.children;
          if (v.children && v.children.length > 0) {
            this.handleChildrenKey(v.children)
          }
          return v
        })
      },
      selectFirstOnce() {
        var treeObj = $.fn.zTree.getZTreeObj("FUNCTIONPERMISSIONtreeDemo");
         if(!treeObj){
            return;
         }
        // console.log('this.groupId', this.groupId)
        // console.log('this.pageInit', this.pageInit)
        // console.log('nodes', nodes)
        var nodes = treeObj.getNodes();
        if (this.pageInit && nodes.length > 0 && nodes[0].ID === this.groupId) return false;
        if (nodes.length > 0) {
          treeObj.selectNode(nodes[0]);
          this.groupId = nodes[0].ID;
          treeObj.setting.callback.onClick('','FUNCTIONPERMISSIONtreeDemo',nodes[0]);//手动触发onClick事件
          // treeObj.checkNode(nodes[0], true, true, true);
          this.pageInit = true;
        }
      },
      treeSearch(e, flag) {
        if (!e) {
          this.pageInit = false;
          this.selectFirstOnce();
          // this.refreshButtonClick();
        }
        if (flag) {
          this.groupId = '';
          this.newGroupId = '';
        }
      },

      getMenuData(resolve, reject) {
        functionPowerActions().groupTreeload({
          success: (res) => {
            if (res.data.code === 0) {
              // res.data.data = [];
              resolve(res.data.data);
              if(res.data.data[0]){
                this.groupId = res.data.data[0].ID;
                this.newGroupId = res.data.data[0].ID;
                this.oldMenuTreeObj = JSON.parse(JSON.stringify(res.data.data[0]));
                this.newMenuTreeObj = JSON.parse(JSON.stringify(res.data.data[0]));
              }else{
                 this.spinShow = false;
              }
             
              // this.menuTreeData = this.restructureMenuTreeData(res.data.data, true);
              // this.getTableData()
            } else {
              reject();
            }
          }
        });
        // network.post('/p/cs/groupTreeload', urlSearchParams({}))
        //   .then((res) => {
        //     if (res.data.code === 0) {
        //       resolve();
        //       // this.menuHighlightIndex = 0;
        //       // this.menuList = res.data.data;
        //       // this.groupId = this.menuList[this.menuHighlightIndex].ID;
        //
        //       this.groupId = res.data.data[0].ID;
        //       this.newGroupId = res.data.data[0].ID;
        //       this.menuTreeData = this.restructureMenuTreeData(res.data.data, true);
        //     } else {
        //       reject();
        //     }
        //   })
        //   .catch((err) => {
        //     reject();
        //     throw err;
        //   });
      }, // 获取菜单数据
      restructureMenuTreeData(data, first) {
        return data.map((item, idx) => {
          item.title = item.NAME;
          if (first && idx === 0) {
            item.selected = true;
          }
          if (item.children && item.children.length > 0) {
            this.restructureMenuTreeData(item.children);
          }
          return item;
        });
      }, // 重构树数据
      checkGroupID() {
        // console.log('this.groupId', this.groupId)
        this.spinShow = false;
        if (!this.groupId) {
          this.saveLoading = false;
          this.$Modal.fcWarning({
            mask: true,
            title: this.$t('feedback.warning'),
            content: this.$t('messages.noGroupInfo')
          });
          return false;
        }
        return true
      },
      getTableData() {
        this.tableSaveData = []; // 清空保存的数据
        let obj = {};
        if (this.adSubsystemId) {
          obj = {
            AD_SUBSYSTEM_ID: this.adSubsystemId,
            AD_TABLECATE_ID: this.adTableCateId,
            GROUP_ID: this.groupId
          };
          console.log(obj,'=======1212=====');
          if (this.newTreeObj.dataSource) {
            obj.DATA_SOURCE = this.newTreeObj.dataSource;
          }
        } else {
          obj = {
            GROUP_ID: this.groupId
          };
        }
        if(!this.checkGroupID()) return false;
        this.spinShow = true;
        functionPowerActions().queryMenuPermission({
          params: obj,
          success: (res) => {
            this.spinShow = false;
            this.bottomTableTbodyHighlightIndex = null;
            this.upperTableTbodyHighlightIndex = 0;
            if (this.$refs.upperTableTabtd) {
              this.$refs.upperTableTabtd.scrollTo(0, 0);
            }
            if (res.data.code === 0) {
              if (res.data.data) {
                const resData = res.data.data;
                this.tableData = resData.reduce((acc, cur) => {
                  const disabledArr = cur.mask.split('');
                  const valueArr = this.toBin(cur.permission).split('');
                  // 查看
                  cur.seeDisabled = disabledArr[0] === '0';
                  cur.seeValue = valueArr[0] === '1';

                  // 编辑
                  cur.editDisabled = disabledArr[1] === '0';
                  cur.editValue = valueArr[1] === '1';

                  // 删除
                  cur.deleteDisabled = disabledArr[2] === '0';
                  cur.deleteValue = valueArr[2] === '1';

                  // 作废
                  cur.toVoidDisabled = disabledArr[3] === '0';
                  cur.toVoidValue = valueArr[3] === '1';

                  // 提交
                  cur.commitDisabled = disabledArr[4] === '0';
                  cur.commitValue = valueArr[4] === '1';

                  // 反提交
                  cur.unCommitDisabled = disabledArr[5] === '0';
                  cur.unCommitValue = valueArr[5] === '1';

                  // 导出
                  cur.exportDisabled = disabledArr[6] === '0';
                  cur.exportValue = valueArr[6] === '1';

                  // 打印
                  cur.printDisabled = disabledArr[7] === '0';
                  cur.printValue = valueArr[7] === '1';

                  // 扩展
                  cur.extendDisabled = cur.actionList.length === 0;
                  cur.extendValue = cur.actionList.length > 0 ? this.getExtendValue(cur.actionList) : false;

                  acc.push(cur);
                  return acc;
                }, []);
                this.getExtendTableData(this.tableData[0], 0);
                this.backupsTableData = JSON.parse(JSON.stringify(this.tableData));
                this.tableDefaultSelectedRowIndex = 0;

                this.allTabthSelected();
                // setTimeout(() => {
                //   this.fixTableColumnWidth();
                // }, 1000);
                this.$nextTick(() => {
                  this.fixTableColumnWidth();
                });
              } else {
                this.$Modal.fcWarning({
                  title: this.$t('feedback.alert'),
                  mask: true,
                  content: res.data.message,
                });
              }
            }
          }
        });
      }, // 获取表格数据
      getExtendValue(data) {
        const arr = data.reduce((acc, cur) => {
          if (cur.permission === 0) {
            acc.push(cur.permission);
          }
          if (cur.children.length > 0 && cur.children[0].permission === 0) {
            acc.push(cur.children[0].permission);
          }
          return acc;
        }, []);
        if (arr.length > 0) {
          return false;
        }
        return true;
      }, // 获取表格里的扩展是否选中
      treeChange(val, obj) {
        this.oldTreeObj = this.newTreeObj;
        this.newTreeObj = obj;
        if (val.length === 0) {
          this.$refs.tree.handleSelect(obj.nodeKey);
        }
        this.newAdSubsystemId = obj.ad_subsystem_id;
        this.newAdTableCateId = obj.ad_tablecategory_id;
        if (!this.isSaveError) {
          if (this.checkNoSaveData('tree')) {
          } else {
            this.spinShow = true;
            this.adSubsystemId = obj.ad_subsystem_id;
            this.adTableCateId = obj.ad_tablecategory_id;
            this.getTableData();
          }
        }
      }, // 树选中改变触发
      btnClick(item) {
        if (item.webdesc === this.$t('buttons.refresh')) {
          this.refreshButtonClick();
        } else if (item.webname === 'copyPermissionCmd') {
          this.copyPerm();
        } else if (item.webname === 'CmenuPermissionSaveCmd') {
          this.saveLoading = true;
          this.savePermission();
        }
      }, // 点击按钮触发
      customize() {
        const { fullPath } = this.$route;// 获取当前路由fullPath
        const { keepAliveModuleName, tableName } = this.$store.state.global.activeTab;// 获取当前缓存模块名称，自定义标识
        const params = {
          routeFullPath: fullPath, // 当前路由fullPath
          keepAliveModuleName, // 当前模块名称
          tableName, // 当前自定义表标识
          event: () => {
            // alert(177);
          }
        };
        store.commit('global/tabCloseAppoint', params);
      },
      customize1() {
         this.customize();
         this.$store.commit('global/tabOpen', {
          type: 'S',
          tableName:'V_ORDER_INFO',
          tableId:'24503',
          //url: '/SYSTEM/TABLE/FUNCTIONPERMISSION/24627',
          back: true
        });


      },
      copyPerm() {
        this.copyPermission = true;
      }, // 复制权限
      tableRowClick(row, index) {
        this.tableDefaultSelectedRowIndex = index;
        this.getExtendTableData(this.tableData[index], index);
      }, // 表格单击某一行
      getExtendTableData(row, index) {
        if (row && row.actionList && row.actionList.length > 0) {
          this.extendTableData = row.actionList.reduce((acc, cur) => {
            cur.extendIndex = index;
            acc.push(cur);
            return acc;
          }, []);
        } else {
          this.extendTableData = [];
        }
      }, // 获取扩展功能表格的数据，也就是下边表格的数据
      toBin(intNum) {
        let answer = '';
        if (/\d+/.test(intNum)) {
          while (intNum !== 0) {
            answer = Math.abs(intNum % 2) + answer;
            intNum = window.parseInt16(intNum / 2);
          }
          if (answer.length === 0) {
            answer = '0';
          }
          if (answer.length < 8) {
            return (Array(8).join('0') + answer).slice(-8);
          }
          return answer;
        }
        return '0';
      }, // 十进制转二进制
      modalCancel() {
        this.copyPermission = false;
      }, // 复制权限弹框取消按钮
      modalConfirm() {
        if (this.singlePermissionId === null) {
          this.$Message.warning({
            content: this.$t('messages.selectOriginRole')
          });
          return;
        }
        if (this.multiplePermissionId === null) {
          this.$Message.warning({
            content: this.$t('messages.selectTargetRole')
          });
          return;
        }
        if (this.multiplePermissionId.indexOf(this.singlePermissionId.toString()) !== -1) {
          this.$Message.warning({
            content: this.$t('messages.notContainRole')
          });
          return;
        }
        if (this.copyType === '') {
          this.$Message.warning({
            content: this.$t('messages.selectCopyMethod')
          });
          return;
        }
        this.copyPermission = false;
        const obj = {
          sourceid: this.singlePermissionId,
          targetids: this.multiplePermissionId,
          type: this.copyType
        };
        this.spinShow = true;
        functionPowerActions().copyPermission({
          params: obj,
          success: (res) => {
            this.spinShow = false;
            if (res.data.code === 0) {
              this.singlePermissionId = null;
              this.multiplePermissionId = null;
              this.copyType = '';
              if (this.groupId) {
                this.getTableData();
              } else {
                return this.refreshButtonClick();
              }
              this.$Message.success({
                content: res.data.message
              });
            }
          }
        });
        // network.post('/p/cs/copyPermission', obj)
        //   .then((res) => {
        //     if (res.data.code === 0) {
        //       this.singlePermissionId = null;
        //       this.multiplePermissionId = null;
        //       this.copyType = '';
        //       this.getTableData();
        //       this.$Message.success({
        //         content: res.data.message
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     throw err;
        //   });
      }, // 复制权限弹框确定按钮
      rowCheckboxChange(currentValue, params) {
        if (params.column.key === 'extend') {
          this.extendRowCheckboxChange(currentValue, params);
        } else {
          // 选中该行数据
          params.row[`${params.column.key}Value`] = currentValue;
          this.tableData[params.index] = params.row;

          // 修改要保存的数据
          // this.editSaveData(currentValue, params);

          // 判断该列是否全选
          this.tabthCheckboxSelected(params.column, params.column.key);


          if (params.column.key === 'see') {
            // 如果该列是查看列，当取消选中的时候将该行都取消选中
            if (!currentValue) {
              this.cancelRowSelected(params);
            }
          } else {
            // 如果该列不是查看列，并且查看列的没有选中，将查看列选中
            this.selectedSeeColumn(params, currentValue);
          }
        }
      }, // 表格单元格的checkbox改变时触发
      cancelRowSelected(params) {
        // 取消上边表格整行的选中状态
        this.columns.reduce((acc, cur, idx) => {
          if (idx > 0) {
            acc.push(cur.key);
          }
          return acc;
        }, [])
          .forEach((item) => {
            params.row[`${item}Value`] = false;
          });
        // 表头取消选中
        this.columns.forEach((item) => {
          this.tabthCheckboxSelected(item, item.key);
        });
        // 如果该行有扩展功能的表格的数据，取消下边表格的选中状态
        if (params.row.actionList && params.row.actionList.length > 0) {
          params.row.actionList.map((item) => {
            item.permission = 0;
            if (item.children && item.children.length > 0) {
              item.children.map((tem) => {
                tem.permission = 0;
                return tem;
              });
            }
            return item;
          });
          this.cancelExtendTableAllSelected();
        }
        // const findIndex = this.tableData.findIndex(item => item.ad_table_id === params.row.ad_table_id);
        this.tableData[params.index] = params.row;
      }, // 取消整行的选中
      selectedSeeColumn(params, currentValue) {
        if (currentValue) {
          this.tableData[params.index].seeValue = currentValue;
        }
        this.tabthCheckboxSelected(this.columns[0], 'see');
      }, // 选中查看列
      editSaveData(currentValue, params) {
        if (currentValue === this.backupsTableData[params.index][`${params.column.key}Value`]) {
          const findIndex = this.tableSaveData.findIndex(item => item.AD_MENU_ID === params.row.ad_menu_id);
          if (findIndex !== -1) {
            this.tableSaveData.splice(findIndex, 1);
          }
        } else {
          this.tableSaveData.push({
            AD_MENU_ID: params.row.ad_menu_id,
            DATA_SOURCE: params.row.data_source,
            ID: params.row.id,
            PERMISSION: this.getSavePermission(params.index)
          });
        }
      }, // 修改上边表格的保存数据
      getExtendTableSaveData(currentValue, row) {
        const tableObj = this.backupsTableData.find(item => item.ad_table_id === row.ad_table_id);
        if (tableObj.actionList && tableObj.actionList.length > 0) {
          const val = tableObj.actionList.find(item => item.ad_action_id === row.ad_action_id).permission === 128;
          if (currentValue === val) {
            const findIndex = this.tableSaveData.findIndex(item => item.AD_ACTION_ID === row.ad_action_id);
            if (findIndex !== -1) {
              this.tableSaveData.splice(findIndex, 1);
            }
          } else {
            this.tableSaveData.push({
              AD_ACTION_ID: row.ad_action_id,
              ID: row.id,
              PERMISSION: currentValue === true ? 1 : 0
            });
          }
        }
      }, // 获取下边表格的保存数据
      editTableExtendData(permission, row) {
        // const tableIndex = this.tableData.findIndex(item => item.ad_table_id === row.ad_table_id);
        // const tableObj = this.tableData.find(item => item.ad_table_id === row.ad_table_id);
        const tableObj = this.tableData[row.extendIndex];
        if (tableObj.actionList && tableObj.actionList.length > 0) {
          const actionListIndex = tableObj.actionList.findIndex(item => item.ad_action_id === row.ad_action_id);
          tableObj.actionList[actionListIndex].permission = permission;
          this.tableData[row.extendIndex] = tableObj;
        }
      }, // 下边表格扩展功能数据修改
      editTableDataForFunction(permission, row,itemIndex) {
        // const tableIndex = this.tableData.findIndex(item => item.ad_table_id === row.ad_table_id);
        // const tableObj = this.tableData.find(item => item.ad_table_id === row.ad_table_id);
        const tableObj = this.tableData[row.extendIndex];
        if (tableObj.actionList && tableObj.actionList.length > 0) {
          const actionListIndex = tableObj.actionList.findIndex(item => item.ad_action_id === row.ad_action_id);
          tableObj.actionList[actionListIndex].children[itemIndex].permission = permission;
          this.tableData[row.extendIndex] = tableObj;
        }
      }, // 下边表格功能数据修改
      getSavePermission(index) {
        const arr = this.columns.reduce((acc, cur, idx) => {
          if (idx !== 8) {
            if (this.tableData[index][`${cur.key}Value`]) {
              acc.push('1');
            } else {
              acc.push('0');
            }
          }
          return acc;
        }, []);
        return arr.join('');
      }, // 获取保存数据的权限的二进制数据
      allTabthSelected() {
        if (this.tableData.length > 0) {
          this.columns.forEach((item) => {
            this.tabthCheckboxSelected(item, item.key);
            // if (item.key !== 'see') {  // 注释掉的这个代码是默认的查看列没有选中
            //   this.tabthCheckboxSelected(item, item.key);
            // }
          });
        } else {
          this.columns.map((item) => {
            item[`${item.key}Value`] = false;
            return item;
          });
        }
      }, // 判断所有表头是不是应该选中
      tabthCheckboxSelected(column, columnKey) {
        if (this.tableData.length > 0) {
          const disabledArr = [];
          const arr = this.tableData.reduce((acc, cur, idx) => {
            if (cur[`${columnKey}Disabled`]) {
              disabledArr.push(idx);
            }
            if (cur[`${columnKey}Disabled`] === false && cur[`${columnKey}Value`] === false) {
              acc.push(idx);
            }
            return acc;
          }, []);
          if (arr.length === 0) {
            if (disabledArr.length === this.tableData.length) {
              column[`${columnKey}Value`] = false;
            } else {
              const findIndex = this.columns.findIndex(item => item.key === columnKey);
              if (!column[`${columnKey}Value`]) {
                column[`${columnKey}Value`] = true;
                this.columns[findIndex] = column;
                // this.columns[findIndex][`${columnKey}Value`] = true;
              }
            }
          } else {
            const findIndex = this.columns.findIndex(item => item.key === columnKey);
            if (column[`${columnKey}Value`]) {
              column[`${columnKey}Value`] = false;
              this.columns[findIndex] = column;
              // this.columns[findIndex][`${columnKey}Value`] = false;
            }
          }
          this.columns = this.columns.concat([]);
        }
      }, // 判断是否将表头选中
      tabthCheckboxChange(currentValue, params) {
        // 如果点击的不是查看列，并且是选中状态的时候，将查看列选中
        if (params.column.key !== 'see') {
          if (currentValue) {
            this.columns[0].seeValue = true;
            this.tableData.map((item) => {
              if (!item.seeDisabled) {
                item.seeValue = true;
              }
              return item;
            });
          }
          const findColumnIndex = this.columns.findIndex((item) => item.key === params.column.key);
          this.columns[findColumnIndex][`${params.column.key}Value`] = currentValue;
        }
        // 点击查看列的表头，并且是取消选中的状态
        if (params.column.key === 'see' && currentValue === false) {
          this.columns[0].seeValue = false;
          this.columns = [].concat(this.columns);
          this.cancelAllSelected();
        }

        // 点击查看列的表头，并且是选中的状态
        if (params.column.key === 'see' && currentValue === true) {
          this.columns[0].seeValue = true;
        }

        // 选中表头以及表体里的数据
        params.column[`${params.column.key}Value`] = currentValue;
        this.tableData.map((item) => {
          if (!item[`${params.column.key}Disabled`]) {
            item[`${params.column.key}Value`] = currentValue;
          }
          return item;
        });

        // 选中扩展的表头
        if (params.column.key === 'extend') {
          this.selectedExtendTabth(currentValue);
        }
      }, // 表格表头的checkbox改变时触发
      cancelAllSelected() {
        this.columns.reduce((acc, cur, idx) => {
          if (idx > 0) {
            acc.push(cur.key);
          }
          return acc;
        }, [])
          .forEach((key) => {
            // const columns = this.columns.map((item) => {
            //   if (item[`${key}Value`]) {
            //     item[`${key}Value`] = false;
            //   }
            //   return item;
            // });
            // this.columns = columns.concat([]).concat([]);
            this.tableData.map((item) => {
              item[`${key}Value`] = false;
              if (item.actionList && item.actionList.length > 0) {
                item.actionList.map((tem) => {
                  tem.permission = 0;
                  if (tem.children && tem.children.length > 0) {
                    tem.children.map((cur) => {
                      cur.permission = 0;
                      return cur;
                    });
                  }
                  return tem;
                });
              }
              return item;
            });
          });
        this.allTabthSelected();
        // 下边表格取消选中
        this.cancelExtendTableAllSelected();
      }, // 取消所有选中
      cancelExtendTableAllSelected() {
        this.extendTableData.map((item) => {
          item.permission = 0;
          if (item.children && item.children.length > 0) {
            item.children.forEach((tem) => {
              tem.permission = 0;
              return tem;
            });
          }
          return item;
        });
      }, // 取消下边表格所有选中状态
      selectedExtendTabth(currentValue) {
        this.editExtendTableData(currentValue);
        this.tableData.map((cur) => {
          if (cur.actionList && cur.actionList.length > 0) {
            cur.actionList.map((item) => {
              if (currentValue) {
                item.permission = 128;
              } else {
                item.permission = 0;
              }
              if (item.children && item.children.length > 0) {
                item.children.map((tem) => {
                  if (currentValue) {
                    tem.permission = 128;
                  } else {
                    tem.permission = 0;
                  }
                  return tem;
                });
              }
              return item;
            });
          }
          return cur;
        });
      }, // 选中扩展列表头
      extendRowCheckboxChange(currentValue, params) {
        if (params) {
          params.row[`${params.column.key}Value`] = currentValue;
          if (params.row.actionList && params.row.actionList.length > 0) {
            params.row.actionList.map((item) => {
              if (currentValue) {
                item.permission = 128;
              } else {
                item.permission = 0;
              }
              if (item.children && item.children.length > 0) {
                item.children.map((tem) => {
                  if (currentValue) {
                    tem.permission = 128;
                  } else {
                    tem.permission = 0;
                  }
                  return tem;
                });
              }
              return item;
            });
          }
          this.tableData[params.index] = params.row;
          // 判断该列是否全部选中
          this.tabthCheckboxSelected(params.column, 'extend');
        }

        // 判断下边表格选中还是不选中
        this.editExtendTableData(currentValue);

        // 将查看列选中
        this.selectedSeeColumn(params, currentValue);
      }, // 扩展一列的checkbox点击的时候触发
      editExtendTableData(currentValue) {
        if (this.extendTableData.length > 0) {
          this.extendTableData.map((item) => {
            if (currentValue) {
              item.permission = 128;
            } else {
              item.permission = 0;
            }
            if (item.children && item.children.length > 0) {
              item.children.map((tep) => {
                if (currentValue) {
                  tep.permission = 128;
                } else {
                  tep.permission = 0;
                }
                return tep;
              });
            }
            return item;
          });
        }
      }, // 根据传入的值判断下边的表格是否全部选中或者不选中
      extendFunctionCheckboxChange(val, params) {
        // 判断是否选中
        if (val) {
          params.row.permission = 128;
          this.editTableExtendData(128, params.row);
        } else {
          params.row.permission = 0;
          this.editTableExtendData(0, params.row);
        }
        this.extendTableData[params.index] = params.row;

        // 判断下边表格中是否全部选中，如果有没有选中的就存到数组里
        const arr = this.extendTableData.reduce((acc, cur) => {
          if (cur.permission === 0) {
            acc.push(cur.permission);
          }
          if (cur.children && cur.children.length > 0) {
            cur.children.forEach((item) => {
              if (item.permission === 0) {
                acc.push(item.permission);
              }
            });
          }
          return acc;
        }, []);
        // 如果下边表格里全部选中，将上边表格对应的扩展选中，如果没有全部选中就取消选中
        // const findIndex = this.tableData.findIndex(item => item.ad_table_id === params.row.ad_table_id);
        const findIndex = params.row.extendIndex;
        if (arr.length > 0) {
          if (findIndex > -1) {
            this.tableData[findIndex].extendValue = false;
            this.selectedSeeColumn({ index: findIndex }, false);
          }
        } else {
          if (findIndex > -1) {
            this.tableData[findIndex].extendValue = true;
            this.selectedSeeColumn({ index: findIndex }, true);
          }
        }

        // 判断扩展该列是否全选
        this.tabthCheckboxSelected(this.columns[8], 'extend');

        // 调保存修改数据的方法
        // this.getExtendTableSaveData(val, params.row);
      }, // 下边表格扩展功能的checkbox改变时触发
      functionCheckboxChange(val, params) {
         const { itemIndex } = params;
        // 判断是否选中
        if (val) {
          params.row.children[itemIndex].permission = 128;
          this.editTableDataForFunction(128, params.row, itemIndex);
        } else {
          params.row.children[itemIndex].permission = 0;
         this.editTableDataForFunction(0, params.row, itemIndex);
        }
        // 判断按钮组是否选中
        let params_row_child_check = params.row.children.some((item)=>{
            if(item.permission === 128){
                return true;
            }
        });
       // 判断按钮组如果有选中则默认选中扩展功能
        if(params_row_child_check){
             this.extendTableData[params.index].permission = 128
        }else{
             this.extendTableData[params.index].permission = 0
        }
        this.extendTableData[params.index] = params.row;
        // 判断下边表格中是否全部选中，如果有没有选中的就存到数组里
        const arr = this.extendTableData.reduce((acc, cur) => {
          if (cur.permission === 0) {
            acc.push(cur.permission);
          }
          if (cur.children && cur.children.length > 0) {
            cur.children.forEach((item) => {
              if (item.permission === 0) {
                acc.push(item.permission);
              }
            });
          }
          return acc;
        }, []);

        // 如果下边表格里全部选中，将上边表格对应的扩展选中，如果没有全部选中就取消选中
        // const findIndex = this.tableData.findIndex(item => item.ad_table_id === params.row.ad_table_id);
        const findIndex = params.row.extendIndex;
        if (arr.length > 0) {
          if (findIndex > -1) {
            this.tableData[findIndex].extendValue = false;
            this.selectedSeeColumn(findIndex, false);
          }
        } else {
          if (findIndex > -1) {
            this.tableData[findIndex].extendValue = true;
            this.selectedSeeColumn(findIndex, true);
          }
        }

        // 判断扩展该列是否全选
        this.tabthCheckboxSelected(this.columns[8], 'extend');
      }, // 下边表格功能列checkbox改变时触发
      savePermission(type) {
        this.getSaveData();
        if (this.tableSaveData.length === 0) {
          this.$Message.info({
            content: this.$t('messages.noChange')
          });
          setTimeout(() => this.saveLoading = false, 1000)
        } else {
          const obj = {
            GROUPID: this.groupId,
            CP_C_GROUPPERM: this.tableSaveData
          };
          if(!this.checkGroupID()) return false;
          functionPowerActions().savePermission({
            params: obj,
            success: (res) => {
              setTimeout(() => this.saveLoading = false, 1000)
              if (res.data.code === 0) {
                this.isSaveError = false;
                if (type === 'refresh') {
                //   this.refresh();
                // } else {
                  this.groupId = this.newGroupId;
                  this.adSubsystemId = this.newAdSubsystemId;
                  this.adTableCateId = this.newAdTableCateId;
                }
                this.getTableData();
                this.$Message.success({
                  content: res.data.message
                });
              } else {
                this.isSaveError = true;
                if (type === 'menuTree') {
                  this.$refs.menuTree.handleSelect(this.oldMenuTreeObj.nodeKey);
                }
                if (type === 'tree') {
                  this.$refs.tree.handleSelect(this.oldTreeObj.nodeKey);
                }
                setTimeout(() => {
                  this.isSaveError = false;
                }, 0);
              }
            }
          });
          // network.post('/p/cs/savePermission', obj)
          //   .then((res) => {
          //     if (res.data.code === 0) {
          //       if (type === 'refresh') {
          //         this.refresh();
          //       } else {
          //         this.groupId = this.newGroupId;
          //         this.adSubsystemId = this.newAdSubsystemId;
          //         this.adTableCateId = this.newAdTableCateId;
          //         this.getTableData();
          //       }
          //       this.$Message.success({
          //         content: res.data.message
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     throw err;
          //   });
        }
      }, // 保存数据
      getSaveData() {
        this.tableSaveData = this.tableData.reduce((acc, cur, idx) => {
          if (cur.ad_menu_id === this.backupsTableData[idx].ad_menu_id && this.getSavePermission(idx) !== this.toBin(this.backupsTableData[idx].permission)) {
            acc.push({
              AD_MENU_ID: cur.ad_menu_id,
              DATA_SOURCE: cur.data_source,
              ID: cur.id,
              PERMISSION: this.getSavePermission(idx)
            });
          }
          if (cur.actionList && cur.actionList.length > 0) {
            cur.actionList.forEach((item, index) => {
              if (item.permission !== this.backupsTableData[idx].actionList[index].permission) {
                acc.push({
                  AD_ACTION_ID: item.ad_action_id,
                  ID: item.id,
                  PERMISSION: item.permission === 128 ? 1 : 0
                });
              }
              if (item.children && item.children.length > 0) {
                item.children.forEach((tem, temIndex) => {
                  if (tem.permission !== this.backupsTableData[idx].actionList[index].children[temIndex].permission) {
                    acc.push({
                      AD_ACTION_ID: tem.ad_action_id,
                      ID: tem.id,
                      PERMISSION: tem.permission === 128 ? 1 : 0
                    });
                  }
                });
              }
            });
          }
          return acc;
        }, []);
      }, // 获得保存的数据
      getCopyPermissionData() {
        functionPowerActions().cgroupsquery({
          params: { NAME: '' },
          success: (res) => {
            if (res.data.code === 0) {
              this.backupsDropData = res.data.data;
              this.totalRowCount = res.data.data.length;
              this.getSingleDropSelectData(1, res.data.data);
              this.getMultipleDropSelectData(1, res.data.data);
            }
          }
        });
        // network.post('/p/cs/cgroupsquery', { NAME: '' })
        //   .then((res) => {
        //     if (res.data.code === 0) {
        //       this.backupsDropData = res.data.data;
        //       this.totalRowCount = res.data.data.length;
        //       this.getSingleDropSelectData(1, res.data.data);
        //       this.getMultipleDropSelectData(1, res.data.data);
        //     }
        //   })
        //   .catch((err) => {
        //     throw err;
        //   });
      }, // 获取复制权限外键的数据
      getSingleDropSelectData(pageValue, data) {
        const start = (pageValue - 1) * this.dropPageSize;
        const tabth = [
          {
            colname: 'ID',
            name: 'ID',
            isak: false
          },
          {
            colname: 'NAME',
            name: this.$t('tips.role'),
            isak: true
          }
        ];
        const row = data.slice(start, start + this.dropPageSize)
          .reduce((acc, cur) => {
            const obj = {
              ID: {
                val: cur.ID,
              },
              NAME: {
                val: cur.NAME
              }
            };
            acc.push(obj);
            return acc;
          }, []);
        this.singleDropDownSelectFilterData = {
          start,
          tabth,
          row
        };
      }, // 整合复制权限外键单选数据
      getMultipleDropSelectData(pageValue, data) {
        const start = (pageValue - 1) * this.dropPageSize;
        const tabth = [
          {
            colname: 'ID',
            name: 'ID',
            isak: false
          },
          {
            colname: 'NAME',
            name: this.$t('tips.role'),
            isak: true
          }
        ];
        const row = data.slice(start, start + this.dropPageSize)
          .reduce((acc, cur) => {
            const obj = {
              ID: {
                val: cur.ID,
              },
              NAME: {
                val: cur.NAME
              }
            };
            acc.push(obj);
            return acc;
          }, []);
        this.multipleDropDownSelectFilterData = {
          start,
          tabth,
          row
        };
      }, // 整合复制权限外键多选数据
      singleDropSelected(val) {
        this.singlePermissionId = val[0].ID;
      }, // 外键单选，选中触发
      singleDropPageChange(val) {
        this.getSingleDropSelectData(val, this.backupsDropData);
      }, // 外键单选分页改变触发
      singlePopperHide() {
        this.getSingleDropSelectData(1, this.backupsDropData);
      }, // 外键单选popper隐藏时触发
      singleDropClear() {
        this.singlePermissionId = null;
      }, // 单选清空时触发
      singleInputChange(val) {
        if (val) {
          this.singleAutoData = this.backupsDropData.reduce((acc, cur) => {
            if (cur.NAME && cur.NAME.indexOf(val) !== -1) {
              acc.push({ ID: cur.ID, NAME: cur.NAME });
            }
            return acc;
          }, []);
        } else {
          this.singleAutoData = [];
        }
      }, // 外键单选输入框值改变时触发
      multipleDropSelected(val) {
        this.multiplePermissionId = val.reduce((acc, cur) => {
          acc.push(cur.ID);
          return acc;
        }, []).join(',');
      }, // 外键多选，选中触发
      multipleDropPageChange(val) {
        this.getMultipleDropSelectData(val, this.backupsDropData);
      }, // 外键多选分页改变触发
      multiplePopperHide() {
        this.getMultipleDropSelectData(1, this.backupsDropData);
      }, // 外键多选popper隐藏时触发
      multipleDropClear() {
        this.multiplePermissionId = null;
      }, // 多选清空时触发
      multipleInputChange(val) {
        if (val) {
          this.multipleAutoData = this.backupsDropData.reduce((acc, cur) => {
            if (cur.NAME && cur.NAME.indexOf(val) !== -1) {
              acc.push({ ID: cur.ID, NAME: cur.NAME });
            }
            return acc;
          }, []);
        } else {
          this.multipleAutoData = [];
        }
      }, // 复制权限外键多选输入时触发
    }
  };
</script>
