var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.title,
        "title-align": _vm.titleAlign,
        scrollable: _vm.scrollable,
        closable: _vm.closable,
        draggable: _vm.draggable,
        mask: _vm.mask,
        "mask-closable": _vm.maskClosable,
        transfer: _vm.transfer,
        "footer-hide": _vm.footerHide,
        "ok-text": _vm.okText,
        "cancel-text": _vm.cancelText,
      },
      on: {
        "on-ok": function ($event) {
          return _vm.confirmDialog()
        },
        "on-cancel": function ($event) {
          return _vm.closeDialog()
        },
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [_c("p", [_vm._v(_vm._s(_vm.contentText))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }