var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "LoginCore",
        [
          _c("template", { slot: "logo" }, [
            _vm.logo
              ? _c("img", {
                  staticClass: "logo",
                  attrs: { src: _vm.logo, alt: "logo" },
                })
              : _c("div", { class: _vm.logoBg }),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }