// 配置菜单名称缩进

export default {
  // PS_C_PRICE_STRATEGY: {
  //   indent: 2
  // },
  // PS_C_PRICE_ADJUST: {
  //   indent: 4
  // }
};
