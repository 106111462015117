var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tag favorite",
      style: { width: _vm.autoWidth ? "100%" : "" },
    },
    [
      _c(
        "Dropdown",
        { attrs: { transfer: true }, on: { "on-click": _vm.routeTo } },
        [
          _vm._t("default", [
            _c("i", { staticClass: "iconfont iconbj_col left-icon" }),
          ]),
          _vm._v(" "),
          _vm.favorite.length > 0
            ? _c(
                "DropdownMenu",
                { attrs: { slot: "list", trigger: "click" }, slot: "list" },
                _vm._l(_vm.favorite, function (d, i) {
                  return _c(
                    "DropdownItem",
                    {
                      key: "favorite-" + i,
                      attrs: {
                        title: d.label,
                        name: d,
                        placement: "bottom-end",
                      },
                    },
                    [_vm._v("\n        " + _vm._s(d.label) + "\n      ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.favorite.length === 0
            ? _c(
                "DropdownMenu",
                { attrs: { slot: "list" }, slot: "list" },
                [
                  _c("DropdownItem", [
                    _vm._v(_vm._s(_vm.$t("messages.noCollection"))),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }