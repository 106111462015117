var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c(
      "div",
      { staticClass: "R3-button-group" },
      _vm._l(_vm.buttonsData, function (item) {
        return _c("Button", {
          key: item.webid,
          ref: item.ref,
          refInFor: true,
          attrs: { id: item.eName, type: "fcdefault" },
          domProps: { textContent: _vm._s(item.webdesc) },
          on: {
            click: function ($event) {
              return _vm.btnclick(item)
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }