var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "R3-api-permission" },
    [
      _c(
        "div",
        { staticClass: "operation-wrap" },
        [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.showAddForm
                  ? _vm.$t("messages.accountName")
                  : _vm.$t("messages.quickOperation")
              ) + "："
            ),
          ]),
          _vm._v(" "),
          _vm.showAddForm
            ? _c("AddAccount", {
                on: { cancel: _vm.hideAdd, save: _vm.addAccount },
              })
            : _c(
                "Button",
                {
                  staticClass: "add-account-btn",
                  attrs: { type: "fcdefault" },
                  on: { click: _vm.showAdd },
                },
                [_vm._v(_vm._s(_vm.$t("messages.addAccount")) + "+")]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.accountList.length === 0
        ? _c("div", { staticClass: "no-account" }, [
            _vm.imgSrc.treeImg
              ? _c("img", { attrs: { src: _vm.imgSrc.treeImg, alt: "" } })
              : _c("div", { staticClass: "no-tree-data" }, [
                  _c("div", { staticClass: "no-tree-data-bg" }),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "none-tip" }, [
              _vm._v(_vm._s(_vm.$t("messages.addAccountFirst"))),
            ]),
          ])
        : [
            _c("div", { staticClass: "view-title" }, [
              _vm._v(_vm._s(_vm.$t("messages.accountList"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content-area" }, [
              _c(
                "div",
                { staticClass: "account-list" },
                [
                  _c(
                    "Scroll",
                    {
                      ref: "scroll",
                      attrs: { "on-reach-bottom": _vm.handleReachBottom },
                    },
                    [
                      _vm._l(_vm.accountList, function (item, index) {
                        return _c("AccoutItem", {
                          key: item.credentialKey,
                          ref: "account",
                          refInFor: true,
                          attrs: {
                            itemInfo: item,
                            index: index,
                            currentPermissionsIndex:
                              _vm.currentPermissionsIndex,
                          },
                          on: {
                            manageAuthority: _vm.manageAuthority,
                            comfirmDelete: _vm.comfirmDelete,
                            comfirmRefresh: _vm.comfirmRefresh,
                          },
                        })
                      }),
                      _vm._v(" "),
                      _vm.isNoMore
                        ? _c("div", { staticClass: "bottom-tip" }, [
                            _vm._v(_vm._s(_vm.$t("tips.noMore"))),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "api-panel" },
                [
                  _c("ApiTree", {
                    ref: "apiTree",
                    attrs: {
                      permissionsIndex: _vm.currentPermissionsIndex,
                      currentAccount: _vm.currentAccount,
                      checkedTotal: _vm.checkedTotal,
                      total: _vm.total,
                      treeData: _vm.treeData,
                      isUpdated: _vm.isUpdated,
                      isLoading: _vm.isLoading,
                    },
                    on: {
                      search: _vm.searchAuthority,
                      updateCheckedCount: _vm.updateCheckedCount,
                      check: _vm.handlerNodeCheck,
                      save: _vm.saveAuthority,
                      refresh: _vm.refreshAuthority,
                      updateStatus: _vm.updateStatus,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }