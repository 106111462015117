var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "detail-collection" },
        [
          _c(
            _vm.slotTableTemplate,
            {
              tag: "component",
              attrs: {
                tabwebact: _vm.tabwebact,
                webConfSingle: _vm.webConfSingle,
                dataSource: _vm.dataSource,
                readonly: _vm.readonly,
                tableReadonly: _vm.tableReadonly,
                objreadonly: _vm.objreadonly,
                tableHeight: _vm.tableHeight,
                status: _vm.status,
                tableName: _vm.tableName,
                type: _vm.type,
                itemInfo: _vm.itemInfo,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-buttons",
                  attrs: { slot: "detail-page" },
                  slot: "detail-page",
                },
                [
                  _c("Page", {
                    ref: "page",
                    staticClass: "table-page",
                    attrs: {
                      total: _vm.dataSource.totalRowCount,
                      "page-size-opts": _vm.dataSource.selectrange,
                      current: _vm.currentPage,
                      size: "small",
                      "show-elevator": "",
                      "show-sizer": "",
                      "show-total": "",
                    },
                    on: {
                      "on-change": _vm.pageChangeEvent,
                      "on-page-size-change": _vm.pageSizeChangeEvent,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isHorizontal && !_vm.readonly
                ? _c(
                    "ul",
                    {
                      staticClass: "detail-buttons",
                      attrs: { slot: "detail-buttons" },
                      slot: "detail-buttons",
                    },
                    _vm._l(_vm.buttonData, function (item) {
                      return _c(
                        "a",
                        {
                          key: item.name,
                          on: {
                            click: function ($event) {
                              return _vm.buttonClick(item)
                            },
                          },
                        },
                        [
                          _vm._t(
                            "detail-buttons-a",
                            [_vm._v("【" + _vm._s(item.name) + "】")],
                            { item: item }
                          ),
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("Dialog", {
                ref: "dialogRef",
                attrs: {
                  title: _vm.dialogConfig.title,
                  mask: _vm.dialogConfig.mask,
                  "content-text": _vm.dialogConfig.contentText,
                  idArray: _vm.tableRowSelectedIds,
                  itemId: _vm.tableName,
                  "footer-hide": _vm.dialogConfig.footerHide,
                  confirm: _vm.dialogConfig.confirm,
                },
              }),
              _vm._v(" "),
              _vm.filterList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "detail-search",
                      attrs: { slot: "detail-search" },
                      slot: "detail-search",
                    },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "" + _vm.$t("tips.queryCondition"),
                          },
                          on: {
                            "on-change": _vm.selectedChange,
                            "on-clear": function ($event) {
                              _vm.searchCondition = ""
                            },
                          },
                          model: {
                            value: _vm.searchCondition,
                            callback: function ($$v) {
                              _vm.searchCondition = $$v
                            },
                            expression: "searchCondition",
                          },
                        },
                        _vm._l(_vm.filterList, function (item) {
                          return _c(
                            "Option",
                            {
                              key: item.colname,
                              attrs: {
                                label: item.name,
                                value: item.isfk
                                  ? item.inputname
                                  : item.colname,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "detail-search-input" },
                        [
                          _c(
                            "Input",
                            {
                              attrs: {
                                search: "",
                                placeholder:
                                  "" + _vm.$t("messages.searchPlaceholder"),
                              },
                              on: {
                                "on-change": _vm.onInputChange,
                                "on-search": _vm.searTabelList,
                              },
                              model: {
                                value: _vm.searchInfo,
                                callback: function ($$v) {
                                  _vm.searchInfo = $$v
                                },
                                expression: "searchInfo",
                              },
                            },
                            [
                              _c(
                                "Button",
                                {
                                  attrs: { slot: "prepend" },
                                  on: { click: _vm.searTabelList },
                                  slot: "prepend",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("buttons.find")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "table-outside",
                  attrs: {
                    slot: "detail-table",
                    columns: _vm.columns,
                    tabledata: _vm.tabledata,
                  },
                  slot: "detail-table",
                },
                [
                  _vm.isCommonTable || !_vm.useAgGrid
                    ? _c("Table", {
                        ref: "selection",
                        staticClass: "table-in",
                        attrs: {
                          height: _vm.tableHeight ? _vm.tableHeight : true,
                          border: "",
                          columns: _vm.columns,
                          loading: _vm.loading,
                          data: _vm.tabledata,
                          "total-data": _vm.totalDataNumber,
                        },
                        on: {
                          "on-selection-change": _vm.tableSelectedChange,
                          "on-sort-change": _vm.tableSortChange,
                          "on-row-dblclick": _vm.tableRowDbclick,
                        },
                      })
                    : _c("arkCommonTableByAgGrid", {
                        ref: "agGridTableContainer",
                        staticClass: "table-in",
                        attrs: {
                          height: _vm.tableHeight
                            ? _vm.tableHeight + "px"
                            : "calc(100% - 10px)",
                          border: "",
                          mode: "commonTable",
                          columns: _vm.columns,
                          data: _vm.tabledata,
                          "render-params": _vm.renderParams,
                          options: _vm.agOptions,
                        },
                        on: {
                          "ag-selection-change": _vm.tableSelectedChange,
                          "ag-row-dblclick": _vm.tableRowDbclick,
                          "grid-ready": _vm.gridReady,
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isHorizontal
                ? _c(
                    "div",
                    {
                      staticClass: "queryCondition",
                      attrs: { slot: "detail-queryCondition" },
                      slot: "detail-queryCondition",
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("tips.queryCondition")) +
                          ":" +
                          _vm._s(_vm.dataSource.queryDesc) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.importData.importDialog
        ? _c("ImportDialog", {
            attrs: {
              name: _vm.importData.importDialog,
              visible: _vm.importData.importDialog,
              "show-close": true,
              title: _vm.importData.importDialogTitle,
              tablename: _vm.tableName,
              "main-table": _vm.mainFormInfo.tablename,
              "main-id": _vm.pageItemId,
            },
            on: {
              confirmImport: _vm.importsuccess,
              closeDialog: _vm.closeImportDialog,
              imporSuccess: _vm.imporSuccess,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("Dialog", {
        ref: "dialogRef",
        attrs: {
          title: _vm.dialogConfig.title,
          mask: _vm.dialogConfig.mask,
          "popwin-message": _vm.popwinMessage,
          "content-text": _vm.dialogConfig.contentText,
          "footer-hide": _vm.dialogConfig.footerHide,
          confirm: _vm.dialogConfig.confirm,
          isrefrsh: _vm.isrefrsh,
          idArray: _vm.tableRowSelectedIds,
          itemId: _vm.tableName,
          "dialog-component-name": _vm.dialogComponentName,
          "obj-list": _vm.dialogComponentName ? _vm.objList : [],
        },
        on: {
          dialogComponentSaveSuccess: _vm.dialogComponentSaveSuccess,
          clearDialogComponentName: _vm.clearDialogComponentName,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }