var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c(
      "div",
      { staticClass: "panel-main" },
      [
        _c("div", { staticClass: "panel-item" }, [
          _c("p", { attrs: { title: _vm.getEname } }, [
            _c("i", { staticClass: "iconfont iconmd-contact explanatory" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("tips.welcome")) +
                ": " +
                _vm._s(_vm.getEname) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.closedChangePassword
          ? _c("div", { staticClass: "panel-item" }, [
              _c("p", { on: { click: _vm.changePwd } }, [
                _c("i", { staticClass: "iconfont iconmd-key explanatory" }),
                _vm._v(_vm._s(_vm.$t("tips.changePassword")) + "\n      "),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(_vm.customizeComponent, { tag: "component" }),
        _vm._v(" "),
        !_vm.layoutDirection
          ? _c("div", { staticClass: "panel-item" }, [
              _c(
                "p",
                [
                  _c("i", { staticClass: "iconfont iconmd-book explanatory" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("messages.expandFavorite")) +
                      "\n        "
                  ),
                  _c("i-switch", {
                    staticClass: "switch",
                    on: {
                      "on-change": function ($event) {
                        return _vm.operationFavorites(_vm.showFavorites)
                      },
                    },
                    model: {
                      value: _vm.showFavorites,
                      callback: function ($$v) {
                        _vm.showFavorites = $$v
                      },
                      expression: "showFavorites",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "panel-item" }, [
          _c(
            "p",
            [
              _c("i", { staticClass: "iconfont iconmd-apps explanatory" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("messages.collapseQuery")) +
                  "\n        "
              ),
              _c("i-switch", {
                staticClass: "switch",
                attrs: { disabled: _vm.queryConditionExpansion },
                on: {
                  "on-change": function ($event) {
                    return _vm.switchChange(_vm.switchValue)
                  },
                },
                model: {
                  value: _vm.switchValue,
                  callback: function ($$v) {
                    _vm.switchValue = $$v
                  },
                  expression: "switchValue",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.switchValue
          ? _c("div", { staticClass: "panel-item" }, [
              _c(
                "p",
                [
                  _c("i", { staticClass: "iconfont iconmd-list explanatory" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("messages.queryDefaultRows")) +
                      "\n        "
                  ),
                  _c("InputNumber", {
                    staticClass: "set-panel-number",
                    attrs: { max: 10, min: 1 },
                    model: {
                      value: _vm.num7,
                      callback: function ($$v) {
                        _vm.num7 = $$v
                      },
                      expression: "num7",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("settingColor"),
        _vm._v(" "),
        _c("div", { staticClass: "panel-item" }, [
          _c("p", { on: { click: _vm.clickSignout } }, [
            _c("i", { staticClass: "iconfont iconmd-exit explanatory" }),
            _vm._v("\n        " + _vm._s(_vm.$t("buttons.exit")) + "\n      "),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }