<template>
  <!-- v-if="newformList.addcolums" -->
  <div ref="modify">
    <ModalConfirm
      ref="Modal"
      :title="poptitle"
      :width="width"
      :loading="loading"
      :title-align="titleAlign"
      :footer-hide="true"
      @on-cancle="oncancle"
    >
      <div slot="Modalsolt" :class="classes">
        <Spin v-if="loading" fix>
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load" />
        </Spin>
        <component
          :is="'panelForm'"
          :defaultData="newformList"
          :CollapseName="CollapseName"
          ref="panelForm"
          tableName="BatchModal"
          class="v-formPanel"
          type=""
          @on-changeForm="formChange"
        />
        <div class="button R3-button-group modifyButton">
          <Button type="fcdefault" @click="oncancle">
            {{ $t('buttons.cancel') }}
          </Button>
          <!-- <template v-if="objbutton.length > 0">
            <Button
              v-for="(item, index) in objbutton"
              type="primary"
              style="margin:0 0 0 10px;"
              :key="index"
              @click="confirm"
            >
              {{ item.webdesc }}
            </Button>
          </template> -->
           <Button type="primary" style="margin:0 0 0 10px;" @click="confirm">
              {{ $t('buttons.confirm') }}
            </Button>
       
        </div>
      </div>
    </ModalConfirm>
  </div>
</template>
<script>
import { Version, classFix } from '../../constants/global';
import ModalConfirm from './Confirm.vue';
import CollapseName from '../FormComponents/childrenForm/CollapseComponent.vue';

let fkHttpRequest = undefined;
import(
  `../../__config__/actions/version_${Version()}/formHttpRequest/fkHttpRequest.js`
).then((data) => {
  fkHttpRequest = () => data;
});
// const fkHttpRequest = () => require(`../__config__/actions/version_${Version()}/formHttpRequest/fkHttpRequest.js`);

export default {
  name: 'ModifyDialog',
  components: {
    ModalConfirm,
  },
  data() {
    return {
      buttonData: {}, // 按钮信息
      formList: {},
      objbutton: [], // 动作定义按钮的信息
      newformList: {},
      formChangeData: {},
      fixedcolumns: {},
      defaultData: {},
      fromListButtonConfig:{},  
      CollapseName: CollapseName,
      Condition: 'list',
      changeType: 'Modify',
      objids: [],
      url:'', // 当前界面的url
      poptitle: this.$t('buttons.batchEdit'),
      loading: false,
      type: false, // 判断是勾选 还是批量
      router: {},
      ids: [],
    };
  },
  props: {
    title: {
      type: String,
      default() {
        return this.$t('tips.title');
      },
    },
    reffixedcolumns: {
      type: Object,
      default: () => {},
    },
    titleAlign: {
      type: String,
      default: () => 'center',
    }, // 设置标题是否居中 // center left
    width: {
      type: Number,
      default: () => 540,
    }, // 设置标题是否居中 // center left
  },
  created() {
    // this.tableName = 'BatchModal';
  },
  computed: {
    classes() {
      return [`${classFix}ModifyDialog virtualtableForm`];
    },
  },
  watch: {
    formList: {
      handler(val) {
        if (Object.hasOwnProperty.call(val, 'addcolums')) {
          let childs = val.addcolums.reduce((arr, item) => {
            const itemChilds = item.childs || item.child;
            if (Array.isArray(itemChilds)) {
              itemChilds.forEach((option) => {
                option.onjump = true;
              });
              arr.push(itemChilds);
            } else {
              itemChilds.isnotnull = false;
              if (itemChilds.display !== 'hr') {
                arr.push(itemChilds);
              }
            }

            return arr;
          }, []);

          childs = childs.flat();
          this.newformList = {
            addcolums: [
              {
                childs: childs,
              },
            ],
            objviewcol: 1,
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    getData(searchObject) {
      fkHttpRequest().fkModify({
        searchObject,
        success: (res) => {
          if (res.data.code === 0) {
            this.loading = false;

            this.formList = res.data.data;
          }
        },
      });
    },
    getObjectTab(searchObject) {
      // 请求tab 获取虚拟表的动作定义
      searchObject.objid = -1;
      searchObject.ismaintable = 'y';
      fkHttpRequest().fkObjectTab({
        searchObject,
        success: (res) => {
          if (res.data.code === 0) {
            let tabwebact = res.data.data.tabwebact || {};
            this.objbutton = tabwebact.objbutton;
          }
        },
      });
    },
    oncancle() {
      this.$emit('on-oncancle-success', this);
    },
    save() {
      let searchObject = {
         fixedData:this.formChangeData,
         table:this.tableName
      } 
      this.buttonData.requestUrlPath = this.url;
      fkHttpRequest().fksaveModify({
        buttonData: this.buttonData,
        searchObject,
        success: (res) => {
          this.loading = false;
          if (res.data.code === 0) {
            this.$Message.info(res.data.message);
            this.$emit('on-save-success', this.fromListButtonConfig);
          } else if (res.data.error) {
            this.$emit('on-oncancle-success', this);
          }
        },
      });
    },
    saveData() {

    },
    open(data,router) {
      //  打开弹窗
      this.router = router;
      this.poptitle = data.webdesc;
      this.url = data.url;
      this.$refs.Modal.open();
      this.loading = true;
      this.tableName = data.tableName;
      this.fromListButtonConfig = data;
      const searchObject = {
        table: data.tableName,
      };
      this.getData(searchObject);
      // this.getObjectTab(searchObject);
    },
    formChange(data, defaultData, changeData) {
      // form 修改的数据
      this.formChangeData = Object.assign({}, data);
      this.defaultData = Object.assign({}, changeData);
    },
    confirm() {
      // 保存提交
      if(!this.$refs.panelForm){
        return false;
      }
      const checkTip = this.$refs.panelForm.validate();
      if (checkTip.length>0) {
       this.$Message.info(checkTip[0].tip);
        return false;
      }else{
        this.save();
      }
    },
  },
  mounted() {},
};
</script>
