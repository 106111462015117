var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.imgAssets.welcome
    ? _c("div", { staticClass: "r3-welcome", style: _vm.bg })
    : _c("div", { class: _vm.classes })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }