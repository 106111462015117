var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "r3-tab-nav" },
    [
      _c(
        "Tabs",
        {
          ref: "R3_Tabs",
          attrs: { type: "card", value: _vm.currentIndex },
          on: { "on-click": _vm.tabClick },
        },
        _vm._l(_vm.data.multi_tab, function (item, index) {
          return _c("TabPane", { key: index, attrs: { label: item.tab_name } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }