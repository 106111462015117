var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v(
      "\n  未找到" +
        _vm._s(_vm.options.componentName) +
        "组件，请确认R3启动项的配置是否正确\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }