var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("Input", {
        staticStyle: { width: "200px" },
        attrs: { placeholder: "生成路由...", icon: "md-color-wand" },
        on: { "on-click": _vm.automaticPathGeneration },
        model: {
          value: _vm.automaticPathGenerationValue,
          callback: function ($$v) {
            _vm.automaticPathGenerationValue = $$v
          },
          expression: "automaticPathGenerationValue",
        },
      }),
      _vm._v(" "),
      _c("Dialog", {
        ref: "dialogRef",
        attrs: {
          title: _vm.dialogConfig.title,
          mask: _vm.dialogConfig.mask,
          "content-text": _vm.dialogConfig.contentText,
          "footer-hide": _vm.dialogConfig.footerHide,
          confirm: _vm.dialogConfig.confirm,
          closable: true,
          "dialog-component-name": _vm.dialogComponentName,
        },
        on: {
          clearDialogComponentName: _vm.clearDialogComponentName,
          setValue: _vm.setValue,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }