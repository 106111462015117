var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "Alert",
        {
          staticClass: "R3-Alert",
          attrs: { type: "warning", "show-icon": "", closable: "" },
        },
        [
          _vm._t("default", [
            _vm._v("为了更好的操作体验，推荐使用谷歌浏览器访问。"),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }